import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { editRoundSeriesSchema } from "./schemas";
import { useForm, Controller } from "react-hook-form";
import { Stack, Box, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { postUpdateRoundSeries } from "api/rounds";

function EditRoundSeriesForm({
  fetchRounds,
  handleClose,
  setIsSubmitting,
  defaultValues,
}) {
  const {
    handleSubmit,
    formState: { errors, isSubmitting, isValid, isDirty },
    control,
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(editRoundSeriesSchema),
    mode: "all",
  });

  const onSubmit = async (data) => {
    try {
      await postUpdateRoundSeries(data);
      fetchRounds();
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setIsSubmitting(isSubmitting);
  }, [isSubmitting]);

  return (
    <Box sx={{ mt: 2 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                fullWidth
                error={Boolean(errors.roundsRemaining?.message)}
                helperText={errors.roundsRemaining?.message}
                label="Rounds remaining in series"
              />
            )}
            name="roundsRemaining"
            control={control}
          />
          <LoadingButton
            loading={isSubmitting}
            variant="contained"
            type="submit"
            disabled={!isDirty || !isValid}
          >
            Submit
          </LoadingButton>
        </Stack>
      </form>
    </Box>
  );
}

export default EditRoundSeriesForm;
