import api from "./index.js";

export function getRounds() {
  return api.get("/api/ethRounds");
}


export function getAdminRounds() {
  return api.get("/api/ethRounds/adminRounds");
}

export function postCloseRound(params) {
  return api.post("/api/ethRounds/closeRound", { ...params });
}

export function postCreateRound(params) {
  return api.post("/api/ethRounds/createRound", { ...params });
}


export function postUpdateRound(params) {
  return api.post("/api/ethRounds/updateRound", { ...params });
}
export function postUpdateRoundSeries(params) {
  return api.post("/api/ethRounds/updateRoundSeries", { ...params });
}

export function getSingleRound(round) {
  return api.get(`/api/ethRounds/getRound/${round}`);
}



export function postLockRound(params) {
  return api.post("/api/ethRounds/lockRoundPrice", { ...params });
}

export function getUserRounds(address, page) {
  return api.get(`/api/ethRounds/getRounds/${address}/${page}`);
}
