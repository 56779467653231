import { useState, useEffect, useCallback } from "react";
import {
    Switch,
    Stack,
    Typography,
    FormControlLabel,
    TableCell,
    TableRow,
    Button,
    Box,
    Paper,
    TableContainer,
    TableHead,
    TableBody,
    Table,
} from "@mui/material";
import clsx from "clsx";
import { styled } from "@mui/material/styles";
import CashierCard from "./CashierCard";
import axios from "axios";
import {
    useNetwork,
    useAccount,
    useContractRead,
    useSwitchNetwork,
    usePrepareContractWrite,
    useContractWrite,
} from "wagmi";
import { isEthChain } from "utils/helpers";
import styles from "assets/jss/tableStyles";

import { makeStyles } from "@mui/styles";
import CallWithSignature from "./CallWithSignature";
import { ethers, BigNumber } from "ethers";
import hiloTokenAbi from "../../contracts/hiloTokenContractAbi.json";
import arbCashierAbi from "../../contracts/cashierArb.json";

const useStyles = makeStyles(styles);

function Cashier() {
    const classes = useStyles();

    const MaterialUISwitch = styled(Switch)(({ theme }) => ({
        width: 62,
        height: 34,
        padding: 7,
        "& .MuiSwitch-switchBase": {
            margin: 1,
            padding: 0,
            transform: "translateX(6px)",
            "&.Mui-checked": {
                color: "#fff",
                transform: "translateX(22px)",
                "& .MuiSwitch-thumb:before": {
                    backgroundImage: `url('/arbitrum.png')`,
                },
                "& + .MuiSwitch-track": {
                    opacity: 1,
                    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
                },
            },
        },
        "& .MuiSwitch-thumb": {
            backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
            width: 32,
            height: 32,
            "&:before": {
                content: "''",
                position: "absolute",
                width: "100%",
                height: "100%",
                left: 0,
                top: 0,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundImage: `url('/eth.png')`,
            },
        },
        "& .MuiSwitch-track": {
            opacity: 1,
            backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
            borderRadius: 20 / 2,
        },
    }));

    const [showDeposit, setShowDeposit] = useState(true);

    const [userBalance, setUserBalance] = useState("0");
    const [withDrawableBalance, setWithdrawableBalance] = useState("0");
    const [signedData, setSignedData] = useState(null);

    const { chain } = useNetwork();
    const { address: currentUserAddress, isConnected } = useAccount();
    const [switchClick, setSwitchClick] = useState(false);

    const { switchNetwork } = useSwitchNetwork();

    const handleChange = () => {
        setShowDeposit((showDeposit) => !showDeposit);
    };

    const checkBalance = useContractRead({
        address:
            chain?.id === Number(process.env.REACT_APP_ETH_CHAIN_ID)
                ? process.env.REACT_APP_HILO_TOKEN
                : process.env.REACT_APP_ARB_CASHIER,
        abi:
            chain?.id === Number(process.env.REACT_APP_ETH_CHAIN_ID) ? hiloTokenAbi : arbCashierAbi,
        functionName: "balanceOf",
        args: [currentUserAddress],
        enabled: false,
    });

    const fetchUserBalance = useCallback(async () => {
        try {
            console.log("Fetch user balance was run");
            const result = await checkBalance.refetch();
            const decAmount = Number(ethers.utils.formatEther(result.data.toString()));
            const intAmount = Math.floor(Number(decAmount));
            const bignumberAmount = ethers.utils.parseEther(intAmount.toString());
            console.log("decimal amount", decAmount);
            console.log("in amount", intAmount);
            console.log("big number amount", bignumberAmount);
            setUserBalance(bignumberAmount.toString());
        } catch (e) {
            setUserBalance("0");
        }
    }, [checkBalance]);

    useEffect(() => {
        if (chain === undefined) {
            console.log("not connected");
            return;
        }
        if (chain.id === Number(process.env.REACT_APP_ARB_CHAIN_ID)) {
            axios(
                process.env.REACT_APP_BACKEND_API_URL +
                    "/api/activities/getDeposits/" +
                    currentUserAddress
            )
                .then((response) => {
                    setSignedData(response.data.results);
                })
                .catch((error) => {
                    console.error("Error fetching data: ", error);
                })
                .finally(() => {});
        } else if (chain.id === Number(process.env.REACT_APP_ETH_CHAIN_ID)) {
            axios(
                process.env.REACT_APP_BACKEND_API_URL +
                    "/api/activities/getWithdraw/" +
                    currentUserAddress
            )
                .then((response) => {
                    setSignedData(response.data.results);
                })
                .catch((error) => {
                    console.error("Error fetching data: ", error);
                })
                .finally(() => {});
        }
    }, [chain, currentUserAddress]);

    console.log("signed data", signedData);

    useEffect(() => {
        fetchUserBalance();
        setSwitchClick(!isEthChain(chain?.id));
    }, [chain?.id, currentUserAddress, fetchUserBalance]);

    if (!isConnected)
        return (
            <Stack
                direction='column'
                justifyContent='center'
                alignItems='center'
                sx={{ width: "100%" }}
                spacing={2}>
                <Typography
                    variant='h5'
                    sx={{ color: "white" }}>
                    Please connect your wallet to visit cashier
                </Typography>
            </Stack>
        );

    return (
        <>
            <Stack
                display='flex'
                direction={{ xl: "row", md: "column" }}
                justifyContent='center'
                alignItems='start'
                gap='2.5rem'>
                <CashierCard
                    showDeposit={showDeposit}
                    userBalance={userBalance}
                    fetchUserBalance={fetchUserBalance}
                />
                <TableContainer
                    className={classes.block}
                    component={Paper}>
                    <Table
                        className={classes.body}
                        sx={{ minWidth: 428 }}
                        aria-label='simple table'>
                        <TableHead className={clsx(classes.trow, classes.header)}>
                            <TableRow>
                                <TableCell
                                    align='center'
                                    className={classes.tCell}>
                                    ID
                                </TableCell>
                                <TableCell
                                    align='center'
                                    className={classes.tCell}>
                                    Amount
                                </TableCell>
                                <TableCell
                                    align='center'
                                    className={classes.tCell}>
                                    call
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {signedData &&
                            signedData.map((data) => (
                                <TableBody className={classes.body}>
                                    <TableRow className={classes.tRows}>
                                        <TableCell
                                            align='center'
                                            className={classes.tCell}>
                                            {data.id}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            className={classes.tCell}>
                                            {ethers.utils.formatEther(data.amount)}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            className={classes.tCell}>
                                            <CallWithSignature
                                                signature={data.signature}
                                                id={data.userId}
                                                amount={data.amount}
                                                type={data.type}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ))}
                    </Table>
                </TableContainer>
            </Stack>
        </>
    );
}
export default Cashier;
