import { useEffect } from "react";
import { Stack, Box, TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { postUpdateRound } from "api/rounds";
import { LoadingButton } from "@mui/lab";
import CategoryInput from "components/CategoryInput/CategoryInput";

import { editRoundSchema } from "./schemas";

function EditRoundForm({
  fetchRounds,
  handleClose,
  setIsSubmitting,
  defaultValues,
}) {
  const replaceNull = (roundData) => {
    const filterResult = Object.entries(roundData)
      .filter(([key, value]) => {
        return value === null;
      })
      .map(([key]) => [key, ""]);

    return { ...roundData, ...Object.fromEntries(filterResult) };
  };

  const formatCategories = (defautlValues) => {
    if (!defaultValues?.category) return {};
    const data = defaultValues.category.map((category) => category.category);
    return { category: data };
  };

  const {
    handleSubmit,
    watch,
    formState: {
      errors,
      isSubmitting,
      isValid,
      isDirty,
      dirtyFields,
      defaultValues: formDefaults,
    },
    control,
    reset,
  } = useForm({
    defaultValues: {
      ...replaceNull(defaultValues),
      ...formatCategories(defaultValues),
    },
    resolver: yupResolver(editRoundSchema),
    mode: "all",
  });

  const onSubmit = async (data) => {
    try {
      const onlyDirtyFields = Object.fromEntries(
        Object.keys(dirtyFields).map((key) => [key, data[key]])
      );
      await postUpdateRound({ ...onlyDirtyFields, epochId: data.epochId });
      fetchRounds();
      handleClose();
      reset();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setIsSubmitting(isSubmitting);
  }, [isSubmitting]);

  return (
    <Box sx={{ mt: 2 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Controller
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  error={Boolean(errors.tokenName?.message)}
                  helperText={errors.tokenName?.message}
                  fullWidth
                  label="Token name"
                />
              );
            }}
            name="tokenName"
            label="Token name"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.tokenSymbol?.message)}
                helperText={errors.tokenSymbol?.message}
                fullWidth
                label="Token symbol"
              />
            )}
            name="tokenSymbol"
            label="Token symbol"
            control={control}
          />
          {watch("tokenImageUrl") && (
            <Box
              component="img"
              style={{ width: 25, height: 25 }}
              src={`${watch("tokenImageUrl")}`}
              alt="Token"
            />
          )}
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.tokenImageUrl?.message)}
                helperText={errors.tokenImageUrl?.message}
                fullWidth
                label="Token image url"
              />
            )}
            name="tokenImageUrl"
            label="Token image url"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                multiline
                rows={4}
                error={Boolean(errors.tokenDescription?.message)}
                helperText={errors.tokenDescription?.message}
                fullWidth
                label="Token description"
              />
            )}
            name="tokenDescription"
            label="Token description"
            control={control}
          />
          <CategoryInput
            control={control}
            errors={errors}
            fieldName="category"
            label="Categories"
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.tokenAddress?.message)}
                helperText={errors.tokenAddress?.message}
                fullWidth
                label="Token address"
              />
            )}
            name="tokenAddress"
            label="Token address"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.pairAddress?.message)}
                helperText={errors.pairAddress?.message}
                fullWidth
                label="Pair address"
              />
            )}
            name="pairAddress"
            label="Pair address"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                fullWidth
                error={Boolean(errors.startPrice?.message)}
                helperText={errors.startPrice?.message}
                label="Start price"
              />
            )}
            name="startPrice"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                fullWidth
                error={Boolean(errors.lockPrice?.message)}
                helperText={errors.lockPrice?.message}
                label="Lock price"
              />
            )}
            name="lockPrice"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.upOption?.message)}
                helperText={errors.upOption?.message}
                fullWidth
                label="Up option"
              />
            )}
            name="upOption"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.downOption?.message)}
                helperText={errors.downOption?.message}
                fullWidth
                label="Down option"
              />
            )}
            name="downOption"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.coingeckoUrl?.message)}
                helperText={errors.coingeckoUrl?.message}
                fullWidth
                label="Coingecko url"
              />
            )}
            name="coingeckoUrl"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.telegramUrl?.message)}
                helperText={errors.telegramUrl?.message}
                fullWidth
                label="Telegram url"
              />
            )}
            name="telegramUrl"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.twitterUrl?.message)}
                helperText={errors.twitterUrl?.message}
                fullWidth
                label="Twitter url"
              />
            )}
            name="twitterUrl"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.mediumUrl?.message)}
                helperText={errors.mediumUrl?.message}
                fullWidth
                label="Medium url"
              />
            )}
            name="mediumUrl"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.coinMarketCapUrl?.message)}
                helperText={errors.coinMarketCapUrl?.message}
                fullWidth
                label="Coin market cap url"
              />
            )}
            name="coinMarketCapUrl"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.bubbleMapsUrl?.message)}
                helperText={errors.bubbleMapsUrl?.message}
                fullWidth
                label="Bubble maps url"
              />
            )}
            name="bubbleMapsUrl"
            control={control}
          />
          <LoadingButton
            loading={isSubmitting}
            variant="contained"
            type="submit"
            disabled={!isDirty || !isValid}
          >
            Submit
          </LoadingButton>
        </Stack>
      </form>
    </Box>
  );
}

export default EditRoundForm;
