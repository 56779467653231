import { useState } from "react";
import { Controller } from "react-hook-form";
import { TextField, Chip, Box, LinearProgress } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useEffect } from "react";
import { getCategoriess } from "api/categories";

const filter = createFilterOptions();

function CategoryInput({ control, errors, fieldName, label }) {
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  const fetchCategories = async () => {
    try {
      setIsLoading(true);
      const {
        data: { categories },
      } = await getCategoriess();
      setCategories([...categories]);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  if (isLoading) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <Controller
      render={({ field: { onChange, ...props } }) => (
        <Autocomplete
          multiple
          onChange={(e, data) => {
            onChange(data);
          }}
          {...props}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option);
            if (inputValue !== "" && !isExisting) {
              filtered.push(`${inputValue}`);
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id="free-solo-with-text-demo"
          options={categories}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            }
          }}
          // renderOption={(props, option) => <li {...props}>{option}</li>}
          sx={{ width: 300 }}
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={Boolean(errors[fieldName]?.message)}
              helperText={errors[fieldName]?.message}
            />
          )}
        />
      )}
      name={fieldName}
      control={control}
      fullWidth
    />
  );
}

export default CategoryInput;
