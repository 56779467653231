const ganache1 = {
    id: 1337,
    name: "Ganache1",
    network: "ethGanache",
    nativeCurrency: {
        decimals: 18,
        name: "EtherGanache",
        symbol: "ETHG",
    },
    rpcUrls: {
        default: {
            http: ["http://127.0.0.1:8545"],
        },
        public: {
            http: ["http://127.0.0.1:8545"],
        },
    },
};

const ganache2 = {
    id: 1338,
    name: "Ganache2",
    network: "arbGanache",
    nativeCurrency: {
        decimals: 18,
        name: "ArbitrumGanache",
        symbol: "ARBG",
    },
    rpcUrls: {
        default: {
            http: ["http://127.0.0.1:8546"],
        },
        public: {
            http: ["http://127.0.0.1:8546"],
        },
    },
};

export { ganache1, ganache2 };
