import React from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import styles from "assets/jss/footerStyles";

const useStyles = makeStyles(styles);

const Footer = () => {
  const classes = useStyles();

  return (
    <Box className={classes.footer}>
      <Box
        display="flex"
        justifyContent="center"
       
      >
        <p style={{ color: "#ffffff", fontFamily: "Arial, sans-serif", fontSize: "16px", fontWeight: 400, margin: "10px 0" }}>
          By using this dApp, you confirm that you have read and understood the{" "}
          <a
            href="https://hilo-1.gitbook.io/hilo-white-paper/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#ffffff", textDecoration: "underline" }}
          >
            Whitepaper 
          </a> {" "}
          and you agree to its terms.
        </p>
      </Box>
    </Box>
  );
};

export default Footer;