export const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || "http://localhost:3001";

export const ADMIN_ADDRESS = [
    process.env.REACT_APP_ADMIN_ADDRESS,
    "0xf9bB81F7d2237Dd3a436023B47627454076c7E3e",
    "0xF164dDd23FeeA3576B0c80f0aD66737fFCe01D29",
    "0x82e2AcfC56212BA5d6247712fcD3e60DA386c55E",
    "0xDD3C83209aD855651F7707912B77a84A17796bf5",
];

export const PROVIDER_URL = process.env.REACT_APP_PROVIDER_URL || "";

export const ENV = process.env.REACT_APP_ENV ?? "development";
