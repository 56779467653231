import * as yup from "yup";
import Web3 from "web3";
import dayjs from "dayjs";

const web3 = new Web3();

export const startNewRoundSchema = yup.object({
    tokenName: yup.string().required(),
    tokenSymbol: yup.string().required(),
    tokenImageUrl: yup.string().url().required(),
    tokenDescription: yup.string().required(),
    tokenAddress: yup
        .string()
        .test("isValidAddress", `Not a valid contract address`, (data) =>
            web3.utils.isAddress(data)
        )
        .required(),
    pairAddress: yup
        .string()
        .test("isValidPairAddress", `Not a valid pair address`, (data) =>
            web3.utils.isAddress(data)
        )
        .notOneOf([yup.ref("tokenAddress")], "Addresses should not be the same")
        .required(),
    lockTimestamp: yup
        .date()
        .min(dayjs(), "Can't be less than current time")
        .typeError("Not a valid date")
        .required("Not a valid date"),
    closeTimestamp: yup
        .date()
        .test(
            "close is > lock",
            "close time should be later than lock time",
            function (closeTimestamp) {
                const lockTimestamp = this.resolve(yup.ref("lockTimestamp"));
                return dayjs(lockTimestamp).unix() < dayjs(closeTimestamp).unix();
            }
        )
        .typeError("Not a valid date")
        .required(),
    startPrice: yup.string().required(),
    lockPrice: yup.string(),
    upOption: yup.string().required(),
    downOption: yup.string().required(),
    coingeckoUrl: yup.string().url(),
    telegramUrl: yup.string().url(),
    twitterUrl: yup.string().url(),
    mediumUrl: yup.string().url(),
    coinMarketCapUrl: yup.string().url(),
    bubbleMapsUrl: yup.string().url(),
    roundsRemainingAtCreate: yup
        .number()
        .min(0, "Must be greater than or equal to 0")
        .test("is-decimal", "invalid decimal", (value) => {
            return (value + "").match(/^-?\d+$/);
        })
        .typeError("Must be a valid number")
        .required("A number is required"),
    category: yup.array().of(yup.string()),
});
export const editRoundSchema = yup.object({
    tokenName: yup.string().required(),
    tokenSymbol: yup.string().required(),
    tokenImageUrl: yup.string().url().required(),
    tokenDescription: yup.string().required(),
    tokenAddress: yup
        .string()
        .test("isValidAddress", `Not a valid contract address`, (data) =>
            web3.utils.isAddress(data)
        )
        .required(),
    pairAddress: yup
        .string()
        .test("isValidPairAddress", `Not a valid pair address`, (data) =>
            web3.utils.isAddress(data)
        )
        .notOneOf([yup.ref("tokenAddress")], "Addresses should not be the same")
        .required(),
    startPrice: yup.string().required(),
    lockPrice: yup.string(),
    upOption: yup.string().required(),
    downOption: yup.string().required(),
    coingeckoUrl: yup.string().url(),
    telegramUrl: yup.string().url(),
    twitterUrl: yup.string().url(),
    mediumUrl: yup.string().url(),
    coinMarketCapUrl: yup.string().url(),
    bubbleMapsUrl: yup.string().url(),
    category: yup.array().of(yup.string()),
});

export const editRoundSeriesSchema = yup.object({
    roundsRemaining: yup
        .number()
        .min(0, "Must be greater than or equal to 0")
        .test("is-decimal", "invalid decimal", (value) => {
            return (value + "").match(/^-?\d+$/);
        })
        .typeError("Amount must be a number")
        .required("A number is required"),
});

export const startNewQuestionSchema = yup.object({
    questionName: yup.string().required(),
    questionSymbol: yup.string().required(),
    questionImageUrl: yup.string().required(),
    questionDescription: yup.string().required(),
    lockTimestamp: yup
        .date()
        .min(dayjs(), "Can't be less than current time")
        .typeError("Not a valid date")
        .required("Not a valid date"),
    closeTimestamp: yup
        .date()
        .test(
            "close is > lock",
            "close time should be later than lock time",
            function (closeTimestamp) {
                const lockTimestamp = this.resolve(yup.ref("lockTimestamp"));
                return dayjs(lockTimestamp).unix() < dayjs(closeTimestamp).unix();
            }
        )
        .typeError("Not a valid date")
        .required(),
    choiceOne: yup.string().required(),
    choiceTwo: yup.string().required(),
    questionCategory: yup.array().of(yup.string()),
});

export const editQuestionSchema = yup.object({
    questionName: yup.string().required(),
    questionSymbol: yup.string().required(),
    questionImageUrl: yup.string().required(),
    questionDescription: yup.string().required(),
    choiceOne: yup.string().required(),
    choiceTwo: yup.string().required(),
});
