import {useState} from "react";
import {Dialog, DialogContent, DialogTitle, Box, Tab} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import styles from "assets/jss/adminCreateRound";
import {makeStyles} from "@mui/styles";
import EditRoundForm from "./EditRoundForm";
import EditRoundSeriesForm from "./EditRoundSeriesForm";

const useStyles = makeStyles( styles );

function EditRound ( {
    fetchRounds,
    showEditRoundForm,
    setShowEditRoundForm,
    defaultValues,
    setRoundToEdit,
} ) {
    const classes = useStyles();

    console.log( "edit round from EditRound", defaultValues );

    const [isSubmitting, setIsSubmitting] = useState( false );
    const [tab, setTab] = useState( "1" );

    const handleTabChange = ( event, newValue ) => {
        setTab( newValue );
    };

    const handleClose = () => {
        if ( isSubmitting ) return;
        setShowEditRoundForm( false );
        setRoundToEdit( {} );
    };

    return (
        <Box className={classes.block}>
            <Dialog open={showEditRoundForm} onClose={handleClose}>
                <TabContext value={tab}>
                    <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                        <TabList
                            onChange={handleTabChange}
                            aria-label="lab API tabs example"
                        >
                            <Tab label="Edit round" value="1" />
                            <Tab label="Edit series" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <DialogContent>
                            <EditRoundForm
                                fetchRounds={fetchRounds}
                                handleClose={handleClose}
                                setIsSubmitting={setIsSubmitting}
                                defaultValues={defaultValues}
                            />
                        </DialogContent>
                    </TabPanel>

                    <TabPanel value="2">
                        <DialogContent>
                            {defaultValues.roundSeries ? (
                                <EditRoundSeriesForm
                                    fetchRounds={fetchRounds}
                                    handleClose={handleClose}
                                    setIsSubmitting={setIsSubmitting}
                                    defaultValues={defaultValues.roundSeries}
                                />
                            ) : (
                                <> No round series available </>
                            )}
                        </DialogContent>
                    </TabPanel>
                </TabContext>
            </Dialog>
        </Box>
    );
}

export default EditRound;
