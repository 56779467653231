import { Typography, Box, Grid, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Loader from "../../assets/img/loader.svg";
import Axios from "axios";
import { useAccount } from "wagmi";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);

  const { address } = useAccount();

  const navigate = useNavigate();

  useEffect(() => {
    if (address) {
      async function fetchData() {
        Axios.get(
          process.env.REACT_APP_DATA_URL +
            `api/dashboard/getOnePlayer?address=${address.toLowerCase()}`
        ).then(async (response) => {
          if (response?.data) {
            console.log(response?.data);
            setData(response?.data);
            setLoading(false);
          }
        });
      }
      fetchData();
    }
  }, [address]);

  return (
    <>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          minHeight="100vh"
          width="100%"
        >
          <img src={Loader} alt="Loader" />
        </Box>
      ) : error ? (
        <Box
          display="flex"
          justifyContent="center"
          minHeight="100vh"
          width="100%"
        >
          <Typography
            color={{ xs: "rgba(255, 255, 255, 0.5)", sm: "white" }}
            fontSize={"24px"}
            fontWeight={"500"}
            marginBottom={{ xs: "24px", sm: "0px" }}
            marginTop="200px"
          >
            Unable to fetch data.
          </Typography>
        </Box>
      ) : !data ? (
        <Box
          display="flex"
          justifyContent="center"
          minHeight="100vh"
          width="100%"
        >
          <Typography
            color={{ xs: "rgba(255, 255, 255, 0.5)", sm: "white" }}
            fontSize={"24px"}
            fontWeight={"500"}
            marginBottom={{ xs: "24px", sm: "0px" }}
            marginTop="200px"
          >
            No Data Found.
          </Typography>
        </Box>
      ) : (
        <Box
          position="relative"
          marginTop={{ xs: "50px", sm: "100px" }}
          marginBottom="50px"
          maxWidth={{ xs: "90%", lg: "1320px" }}
          marginLeft="auto"
          marginRight="auto"
        >
          <Typography color="white" fontSize={"24px"} fontWeight={"500"}>
            Dashboard
          </Typography>
          <Grid
            container
            display="flex"
            justifyContent="space-between"
            gap={{ xs: 0, sm: 4 }}
          >
            <Grid item width={{ xs: "100%", md: "48%" }}>
              <Box
                padding={{ xs: "20px", sm: "43px" }}
                borderRadius={{ xs: "51px", md: "40px" }}
                backgroundColor="#0C1C2E"
                marginTop={{ xs: "30px" }}
                border="0.5px solid #343435"
              >
                <Grid
                  container
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item xs={7} md={6}>
                    <Box>
                      <Typography
                        color="#818892"
                        fontSize={{ xs: "13px", sm: "18px" }}
                        fontWeight="500"
                      >
                        Net Payout ($HILO)
                      </Typography>
                      <Typography
                        color="white"
                        fontSize={{ xs: "17px", sm: "35px" }}
                        fontWeight="500"
                      >
                        {data.netPayout}
                        <Typography
                          component="span"
                          fontSize={{ xs: "10px", sm: "22px" }}
                          fontWeight="500"
                          marginLeft="10px"
                          color="#4166F4"
                        >
                          ~${parseFloat(data.netPayoutUsd).toFixed(4)}
                        </Typography>
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent={{ lg: "space-between" }}
                      marginTop={{ xs: "20px", sm: "40px" }}
                    >
                      <Box>
                        <Typography
                          color="#818892"
                          fontSize={{ xs: "13px", sm: "18px" }}
                          fontWeight="500"
                        >
                          Winning Rate
                        </Typography>
                        <Typography
                          color="white"
                          fontSize={{ xs: "17px", sm: "26px" }}
                          fontWeight="500"
                        >
                          {!data.totalRound
                            ? 0
                            : parseFloat(
                                (data.winningRound / data.totalRound) * 100
                              ).toFixed(2)}
                          %
                        </Typography>
                      </Box>
                      <Box marginLeft={{ xs: "12px", md: "0px" }}>
                        <Typography
                          color="#818892"
                          fontSize={{ xs: "13px", sm: "18px" }}
                          fontWeight="500"
                        >
                          Winning Round
                        </Typography>
                        <Typography
                          color="white"
                          fontSize={{ xs: "17px", sm: "26px" }}
                          fontWeight="500"
                        >
                          {data.winningRound}/{data.totalRound}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <CircularProgressbar
                      value={(data.winningRound / data.totalRound) * 100}
                      strokeWidth={14}
                      styles={buildStyles({
                        pathColor: `#4166F4`,
                        strokeLinecap: "butt",
                        pathTransitionDuration: 0.5,
                      })}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item width={{ xs: "100%", md: "48%" }}>
              <Box
                padding={{ xs: "20px", sm: "26px" }}
                borderRadius={{ xs: "51px", md: "40px" }}
                marginTop={{ xs: "30px" }}
                border="0.5px solid white"
              >
                <Box display="flex" justifyContent="space-between">
                  <Box>
                    <Typography
                      color="#818892"
                      fontSize={{ xs: "13px", sm: "18px" }}
                      fontWeight="500"
                    >
                      Total Winning Rounds
                    </Typography>
                    <Typography
                      color="white"
                      fontSize={{ xs: "17px", sm: "26px" }}
                      fontWeight="500"
                    >
                      {data.winningRound} Rounds
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      color="#818892"
                      fontSize={{ xs: "13px", sm: "18px" }}
                      fontWeight="500"
                      textAlign="right"
                    >
                      ~${parseFloat(data.winningAmountUsd).toFixed(4)}
                    </Typography>
                    <Typography
                      textAlign="right"
                      color="#4166F4"
                      fontSize={{ xs: "17px", sm: "26px" }}
                      fontWeight="500"
                    >
                      +{parseFloat(data.winningAmount).toFixed(4)} $HILO
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  marginTop="15px"
                >
                  <Box>
                    <Typography
                      color="#818892"
                      fontSize={{ xs: "13px", sm: "18px" }}
                      fontWeight="500"
                    >
                      Total Losing Rounds
                    </Typography>
                    <Typography
                      color="white"
                      fontSize={{ xs: "17px", sm: "26px" }}
                      fontWeight="500"
                    >
                      {data.totalRound - data.winningRound} Rounds
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      color="#818892"
                      fontSize={{ xs: "13px", sm: "18px" }}
                      fontWeight="500"
                      textAlign="right"
                    >
                      ~$
                      {parseFloat(
                        data.netPayoutUsd - data.winningAmountUsd
                      ).toFixed(4)}
                    </Typography>
                    <Typography
                      textAlign="right"
                      color="white"
                      fontSize={{ xs: "17px", sm: "26px" }}
                      fontWeight="500"
                    >
                      -
                      {parseFloat(data.netPayout - data.winningAmount).toFixed(
                        4
                      )}{" "}
                      $HILO
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  marginTop="15px"
                >
                  <Box>
                    <Typography
                      color="#818892"
                      fontSize={{ xs: "13px", sm: "18px" }}
                      fontWeight="500"
                    >
                      Total Positions
                    </Typography>
                    <Typography
                      color="white"
                      fontSize={{ xs: "17px", sm: "26px" }}
                      fontWeight="500"
                    >
                      {data.totalRound} Rounds
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      color="#818892"
                      fontSize={{ xs: "13px", sm: "18px" }}
                      fontWeight="500"
                      textAlign="right"
                    >
                      ~{parseFloat(data.netPayoutUsd).toFixed(4)}
                    </Typography>
                    <Typography
                      textAlign="right"
                      color="white"
                      fontSize={{ xs: "17px", sm: "26px" }}
                      fontWeight="500"
                    >
                      {data.netPayout} $HILO
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Box
              width={{ xs: "100%", md: "50%" }}
              marginLeft={{ xs: "auto" }}
              marginRight={{ xs: "auto" }}
              padding={{ xs: "20px", sm: "26px" }}
              borderRadius={{ xs: "51px", md: "40px" }}
              marginTop={{ xs: "30px", sm: "50px" }}
              border="0.5px solid white"
            >
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <Typography
                    color="#818892"
                    fontSize={{ xs: "13px", sm: "18px" }}
                    fontWeight="500"
                  >
                    Best Round
                  </Typography>
                  <Typography
                    color="white"
                    fontSize={{ xs: "17px", sm: "26px" }}
                    fontWeight="500"
                  >
                    #{data.bestRoundId}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    color="#818892"
                    fontSize={{ xs: "13px", sm: "18px" }}
                    fontWeight="500"
                    textAlign="right"
                  >
                    ~${parseFloat(data.bestPayoutUsd).toFixed(4)}
                  </Typography>
                  <Typography
                    textAlign="right"
                    color="#4166F4"
                    fontSize={{ xs: "17px", sm: "26px" }}
                    fontWeight="500"
                  >
                    +{parseFloat(data.bestPayout).toFixed(4)} $HILO
                  </Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                marginTop="15px"
              >
                <Box>
                  <Typography
                    color="#818892"
                    fontSize={{ xs: "13px", sm: "18px" }}
                    fontWeight="500"
                  >
                    Avg. Payout
                  </Typography>
                  <Typography
                    color="white"
                    fontSize={{ xs: "17px", sm: "26px" }}
                    fontWeight="500"
                  >
                    {parseFloat(data.winningAmount / data.winningRound).toFixed(
                      4
                    )}{" "}
                    $HILO
                  </Typography>
                  <Typography
                    color="#818892"
                    fontSize={{ xs: "13px", sm: "18px" }}
                    fontWeight="500"
                  >
                    ~$
                    {parseFloat(
                      data.winningAmountUsd / data.winningRound
                    ).toFixed(4)}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    color="#818892"
                    fontSize={{ xs: "13px", sm: "18px" }}
                    fontWeight="500"
                    textAlign="right"
                  >
                    Avg. Position
                  </Typography>
                  <Typography
                    textAlign="right"
                    color="white"
                    fontSize={{ xs: "17px", sm: "26px" }}
                    fontWeight="500"
                  >
                    {parseFloat(data.avgPayout).toFixed(4)} $HILO
                  </Typography>
                  <Typography
                    color="#818892"
                    fontSize={{ xs: "13px", sm: "18px" }}
                    fontWeight="500"
                    textAlign="right"
                  >
                    ~${parseFloat(data.avgPayoutUsd).toFixed(4)}
                  </Typography>
                </Box>
              </Box>
              <Button
                style={{
                  backgroundColor: "#4166F4",
                  width: "100%",
                  color: "white",
                  borderRadius: "100px",
                  fontSize: "18px",
                  fontWeight: "500",
                  marginTop: "20px",
                }}
                onClick={() => {
                  navigate("/positions");
                }}
              >
                Positions History
              </Button>
            </Box>
          </Grid>
        </Box>
      )}
    </>
  );
};
export default Dashboard;
