import {useState, useCallback, useEffect} from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import styles from "assets/jss/positionsPageStyles";
import tableStyles from "assets/jss/tableStyles";
import Web3 from "web3";
import {Box, Button, Stack, TableCell, TableRow, Typography, Badge} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useContractRead, useNetwork, useAccount} from "wagmi";
import {setDecimalsNumericString} from "utils/helpers";
import {getMultiplierV2} from "utils/helpers";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {Link, useNavigate} from "react-router-dom";
import Countdown from "react-countdown";
import hiloTokenAbi from "../../contracts/hiloTokenContractAbi.json";
import LockIcon from "@mui/icons-material/Lock";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import {getDex} from "api/dex";
import {AreaChart, XAxis, YAxis, CartesianGrid, Tooltip, Area} from "recharts";
dayjs.extend( relativeTime );

const useStyles = makeStyles( styles );
const useTableStyles = makeStyles( tableStyles );

function LiveRoundRow ( {roundData, approvedAmount, fetchApprovedAmount, arbPrice} ) {
    const navigate = useNavigate();
    const tableClasses = useTableStyles();
    const web3 = new Web3();

    const bullMultiplier = getMultiplierV2( roundData?.totalAmount, roundData?.bullAmount );
    const bearMultiplier = getMultiplierV2( roundData?.totalAmount, roundData?.bearAmount );
    const formattedBullMultiplier = bullMultiplier
        .toUnsafeFloat()
        .toFixed( bullMultiplier.isZero() ? 0 : 2 );
    const formattedBearMultiplier = bearMultiplier
        .toUnsafeFloat()
        .toFixed( bearMultiplier.isZero() ? 0 : 2 );




    const arbTotalAmount = setDecimalsNumericString(
        web3.utils.fromWei( roundData?.totalAmount.toString() || "0" )
    );



    const arbInUSD = arbTotalAmount ? ( ( Number( arbTotalAmount ) * arbPrice ) ).toFixed( 2 ) : 0;


    console.log( "roundData?.totalAmount", Number( arbTotalAmount ) );
    console.log( "arbPrice", arbPrice );



    const [data, setData] = useState();
    console.log( "dex data", data );




    useEffect( () => {
        const getDexData = () => {
            getDex( roundData.pairAddress ).then( ( res ) => {
                const data = [];
                const minValue = Math.min( ...res.data.data.getBars.o );
                const maxValue = Math.max( ...res.data.data.getBars.o );

                // Map the values to the desired range
                const scaledValues = res.data.data.getBars.o.map( value => {
                    // Formula for linear scaling: scaledValue = (value - minValue) / (maxValue - minValue) * (maxRange - minRange) + minRange
                    return ( value - minValue ) / ( maxValue - minValue ) * ( 1000 - 0 ) + 0;
                } );

                for ( let i = 0; i < res.data.data.getBars.o.length; i++ ) {
                    const obj = {
                        name: `value${ i + 1 }`,
                        value: scaledValues[i]
                    };

                    data.push( obj );
                }
                setData( data );

            } );
        };
        getDexData();
    }, [roundData.pairAddress] );

    console.log( data );

    return (
        <>
            <TableRow className={tableClasses.tRows}>
                <TableCell
                    align='center'
                    className={tableClasses.tCell}>
                    <Link
                        className={tableClasses.tLink}
                        to={`/hilocard/${ roundData.epochId }`}
                        underline='none'>
                        <Stack
                            direction='row'
                            justifyContent='flex-start'
                            alignItems='center'
                            spacing={2}>
                            <Box
                                component='img'
                                style={{width: 40, height: 40}}
                                src={`${ roundData.tokenImageUrl }`}
                                alt='Token'
                                borderRadius="50px"
                            />
                            <Typography>{roundData.tokenName}</Typography>
                        </Stack>
                    </Link>
                </TableCell>
                <TableCell
                    align='center'
                    className={tableClasses.tCell}>
                    <Link
                        className={tableClasses.tLink}
                        to={`/hilocard/${ roundData.epochId }`}
                        underline='none'>
                        {roundData.tokenSymbol}
                    </Link>
                </TableCell>
                <TableCell
                    align='center'
                    className={tableClasses.tCell}>
                    <Link
                        className={tableClasses.tLink}
                        to={`/hilocard/${ roundData.epochId }`}
                        underline='none'>
                        <Box className={tableClasses.timeStamp}>
                            <Box
                                backgroundColor='#1A192E'
                                padding='.25rem'
                                borderRadius='24.5px'
                                display='flex'
                                flexDirection='row'
                                alignItems='center'
                                gap='.25rem'>
                                <Box
                                    backgroundColor='#4166F4'
                                    display='flex'
                                    justifyContent='center'
                                    alignContent='center'
                                    borderRadius={50}
                                    padding='.25rem'>
                                    <LockIcon />
                                </Box>
                                <Box minWidth="125px">
                                    <Countdown
                                        key={dayjs.unix( roundData?.lockTimestamp ).toDate().toString()}
                                        date={dayjs.unix( roundData?.lockTimestamp ).toDate()}
                                    />
                                </Box>

                            </Box>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='4'
                                height='4'
                                viewBox='0 0 4 4'
                                fill='none'>
                                <circle
                                    cx='1.9113'
                                    cy='2.16905'
                                    r='1.51964'
                                    fill='white'
                                    fill-opacity='0.5'
                                />
                            </svg>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='5'
                                height='4'
                                viewBox='0 0 5 4'
                                fill='none'>
                                <circle
                                    cx='2.50947'
                                    cy='2.16905'
                                    r='1.51964'
                                    fill='white'
                                />
                            </svg>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='4'
                                height='4'
                                viewBox='0 0 4 4'
                                fill='none'>
                                <circle
                                    cx='1.9113'
                                    cy='2.16905'
                                    r='1.51964'
                                    fill='white'
                                    fill-opacity='0.5'
                                />
                            </svg>
                            <Box
                                backgroundColor='#1A192E'
                                padding='.25rem'
                                borderRadius='24.5px'
                                display='flex'
                                flexDirection='row'
                                alignItems='center'
                                gap='.25rem'
                            >
                                <Box minWidth="125px">
                                    <Countdown
                                        key={dayjs.unix( roundData?.closeTimestamp ).toDate().toString()}
                                        date={dayjs.unix( roundData?.closeTimestamp ).toDate()}
                                    />
                                </Box>


                                <Box
                                    backgroundColor='#4166F4'
                                    display='flex'
                                    justifyContent='center'
                                    alignContent='center'
                                    borderRadius={50}
                                    padding='.25rem'>
                                    <KeyboardDoubleArrowDownIcon />
                                </Box>
                            </Box>
                        </Box>
                    </Link>
                </TableCell>
                <TableCell
                    align='center'
                    className={tableClasses.tCell}>
                    <Link
                        className={tableClasses.tLink}
                        to={`/hilocard/${ roundData.epochId }`}
                        underline='none'>
                        {setDecimalsNumericString(
                            web3.utils.fromWei( roundData?.totalAmount.toString() || "0" )
                        )} {`($${ arbInUSD })`}
                    </Link>
                </TableCell>
                <TableCell
                    align='center'
                    className={tableClasses.tCell}>
                    <Link
                        className={tableClasses.tLink}
                        to={`/hilocard/${ roundData.epochId }`}
                        underline='none'>
                        <Stack
                            direction='row'
                            justifyContent='center'
                            alignItems='center'
                            gap="1rem"
                        >
                            <Stack
                                direction='row'
                                justifyContent='center'
                                alignItems='center'
                                backgroundColor="#343252"
                                borderRadius='24.5px'
                                gap='.25rem'
                                paddingRight=".25rem"
                            >
                                <Box
                                    backgroundColor='#1A192E'
                                    display='flex'
                                    justifyContent='center'
                                    alignContent='center'
                                    borderRadius={50}
                                    padding='.25rem'
                                    border={2}
                                    sx={{
                                        borderColor: "#6C4DFF"
                                    }}
                                >
                                    <ArrowUpwardIcon />
                                </Box>

                                <Typography
                                    color='white'
                                    noWrap>
                                    x {formattedBullMultiplier}
                                </Typography>
                            </Stack>
                            <Stack
                                direction='row'
                                justifyContent='center'
                                alignItems='center'
                                backgroundColor="#1A192E"
                                borderRadius='24.5px'
                                gap='.25rem'
                                paddingLeft=".25rem"
                            >

                                <Typography
                                    color='white'
                                    noWrap>
                                    x {formattedBearMultiplier}
                                </Typography>
                                <Box
                                    backgroundColor='#1A192E'
                                    display='flex'
                                    justifyContent='center'
                                    alignContent='center'
                                    borderRadius={50}
                                    padding='.25rem'
                                    border={2}
                                    sx={{
                                        borderColor: "#FF72E1"
                                    }}
                                >
                                    <ArrowDownwardIcon />
                                </Box>
                            </Stack>
                        </Stack>
                    </Link>
                </TableCell>
                <TableCell
                    align='center'
                    className={tableClasses.tCell}>
                    {/* chart here */}
                    <AreaChart
                        width={200}
                        height={40}
                        data={data}
                    >
                        <Area type="monotone" dataKey="value" stroke="#8884d8" fill="#8884d8" />
                    </AreaChart>
                </TableCell>
                <TableCell
                    align='center'
                    className={tableClasses.tCell}>
                    <Button
                        sx={{
                            minWidth: "143px",
                            "&.Mui-disabled": {
                                color: "rgba(255, 255, 255, 0.5)",
                                background: "rgba(65, 102, 244, 0.5)",
                            },
                        }}
                        className={
                            roundData.roundBettingStatus === "OPEN"
                                ? tableClasses.claimBtnWhite
                                : tableClasses.claimBtn
                        }
                        // to={`/hilocard/${roundData.epochId}`}
                        onClick={() => {
                            if ( roundData.roundBettingStatus === "OPEN" ) {
                                navigate( `/hilocard/${ roundData.epochId }` );
                            }
                        }}
                        disabled={roundData.roundBettingStatus === "DONE"}>
                        {roundData.roundBettingStatus}
                    </Button>
                </TableCell>
            </TableRow>
        </>
    );
}
export default LiveRoundRow;
