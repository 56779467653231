import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import tableStyles from "assets/jss/tableStyles";
import Web3 from "web3";
import {Box, Button, Stack, TableCell, TableRow, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {setDecimalsNumericString} from "utils/helpers";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {Link, useNavigate} from "react-router-dom";
import Countdown from "react-countdown";
import LockIcon from "@mui/icons-material/Lock";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
dayjs.extend( relativeTime );

const useTableStyles = makeStyles( tableStyles );

function LiveQuestionRoundRow ( {roundData, approvedAmount, fetchApprovedAmount} ) {
    const navigate = useNavigate();
    const tableClasses = useTableStyles();
    const web3 = new Web3();

    return (
        <>
            <TableRow className={tableClasses.tRows}>
                <TableCell
                    align='center'
                    className={tableClasses.tCell}>
                    <Link
                        className={tableClasses.tLink}
                        to={`/hiloquestioncard/${ roundData.epochId }`}
                        underline='none'>
                        <Stack
                            direction='row'
                            justifyContent='flex-start'
                            alignItems='center'
                            spacing={2}>
                            <Box
                                component='img'
                                style={{width: 40, height: 40}}
                                src={`${ roundData.questionImageUrl }`}
                                alt='Token'
                                borderRadius="50px"
                            />
                            <Typography>{roundData.questionName}</Typography>
                        </Stack>
                    </Link>
                </TableCell>
                <TableCell
                    align='center'
                    className={tableClasses.tCell}>
                    <Link
                        className={tableClasses.tLink}
                        to={`/hiloquestioncard/${ roundData.epochId }`}
                        underline='none'>
                        {roundData.questionSymbol}
                    </Link>
                </TableCell>
                <TableCell
                    align='center'
                    className={tableClasses.tCell}>
                    <Link
                        className={tableClasses.tLink}
                        to={`/hiloquestioncard/${ roundData.epochId }`}
                        underline='none'>
                        <Box className={tableClasses.timeStamp}>
                            <Box
                                backgroundColor='#1A192E'
                                padding='.25rem'
                                borderRadius='24.5px'
                                display='flex'
                                flexDirection='row'
                                alignItems='center'
                                gap='.25rem'>
                                <Box
                                    backgroundColor='#4166F4'
                                    display='flex'
                                    justifyContent='center'
                                    alignContent='center'
                                    borderRadius={50}
                                    padding='.25rem'>
                                    <LockIcon />
                                </Box>
                                <Box minWidth="125px">
                                    <Countdown
                                        key={dayjs.unix( roundData?.lockTimestamp ).toDate().toString()}
                                        date={dayjs.unix( roundData?.lockTimestamp ).toDate()}
                                    />
                                </Box>
                            </Box>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='4'
                                height='4'
                                viewBox='0 0 4 4'
                                fill='none'>
                                <circle
                                    cx='1.9113'
                                    cy='2.16905'
                                    r='1.51964'
                                    fill='white'
                                    fill-opacity='0.5'
                                />
                            </svg>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='5'
                                height='4'
                                viewBox='0 0 5 4'
                                fill='none'>
                                <circle
                                    cx='2.50947'
                                    cy='2.16905'
                                    r='1.51964'
                                    fill='white'
                                />
                            </svg>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='4'
                                height='4'
                                viewBox='0 0 4 4'
                                fill='none'>
                                <circle
                                    cx='1.9113'
                                    cy='2.16905'
                                    r='1.51964'
                                    fill='white'
                                    fill-opacity='0.5'
                                />
                            </svg>
                            <Box
                                backgroundColor='#1A192E'
                                padding='.25rem'
                                borderRadius='24.5px'
                                display='flex'
                                flexDirection='row'
                                alignItems='center'
                                gap='.25rem'>
                                <Box minWidth="125px">

                                    <Countdown
                                        key={dayjs.unix( roundData?.closeTimestamp ).toDate().toString()}
                                        date={dayjs.unix( roundData?.closeTimestamp ).toDate()}
                                    />
                                </Box>

                                <Box
                                    backgroundColor='#4166F4'
                                    display='flex'
                                    justifyContent='center'
                                    alignContent='center'
                                    borderRadius={50}
                                    padding='.25rem'>
                                    <KeyboardDoubleArrowDownIcon />
                                </Box>
                            </Box>
                        </Box>
                    </Link>
                </TableCell>
                <TableCell
                    align='center'
                    className={tableClasses.tCell}>
                    <Link
                        className={tableClasses.tLink}
                        to={`/hiloquestioncard/${ roundData.epochId }`}
                        underline='none'>
                        {setDecimalsNumericString(
                            web3.utils.fromWei( roundData?.totalAmount.toString() || "0" )
                        )}
                    </Link>
                </TableCell>
                <TableCell
                    align='center'
                    className={tableClasses.tCell}>
                    <Link
                        className={tableClasses.tLink}
                        to={`/hiloquestioncard/${ roundData.epochId }`}
                        underline='none'>
                        <Stack
                            direction='row'
                            justifyContent='center'
                            alignItems='center'>

                            <Typography
                                color='white'
                                noWrap>
                                {roundData.choiceOne} | {roundData.choiceTwo}
                            </Typography>

                        </Stack>
                    </Link>
                </TableCell>
                <TableCell
                    align='center'
                    className={tableClasses.tCell}>
                    <Button
                        sx={{
                            minWidth: "143px",
                            "&.Mui-disabled": {
                                color: "rgba(255, 255, 255, 0.5)",
                                background: "rgba(65, 102, 244, 0.5)",
                            },
                        }}
                        className={
                            roundData.roundBettingStatus === "OPEN"
                                ? tableClasses.claimBtnWhite
                                : tableClasses.claimBtn
                        }
                        // to={`/hilocard/${roundData.epochId}`}
                        onClick={() => {
                            if ( roundData.roundBettingStatus === "OPEN" ) {
                                navigate( `/hiloquestioncard/${ roundData.epochId }` );
                            }
                        }}
                        disabled={roundData.roundBettingStatus === "DONE"}>
                        {roundData.roundBettingStatus}
                    </Button>
                </TableCell>
            </TableRow>
        </>
    );
}
export default LiveQuestionRoundRow;
