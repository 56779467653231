import {Stack, TableCell, TableRow, Box, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import ClaimWinningsButton from "./ClaimWinningsButton";
import styles from "assets/jss/tableStyles";
import {makeStyles} from "@mui/styles";
import {
    usePrepareContractWrite,
    useContractWrite,
    useContractRead,
    useNetwork,
    useAccount,
} from "wagmi";
import Web3 from "web3";
import {useMemo} from "react";
import {setDecimalsNumericString} from "utils/helpers";
import Countdown from "react-countdown";
import dayjs from "dayjs";
import pridictionMarketAbi from "../../contracts/predictionMarketContract.json";
import pridictionnewabi from "../../contracts/pridictionnewabi.json";

import {ethers} from "ethers";

const useStyles = makeStyles( styles );

function RoundRow ( {userRoundData, epoch, refetchUserData, dbRoundData} ) {
    const classes = useStyles();
    const web3 = new Web3();
    const {address} = useAccount();
    const {chain} = useNetwork();

    const [position, amount, claimed] = userRoundData;
    console.log( "round data from position row", userRoundData );
    console.log( "db round data", dbRoundData );

    const priceFormat = ( number ) => {
        const fromWei = web3.utils.fromWei( number );
        return fromWei.replace( /(\d)(?=(\d\d\d)+(?!\d))/g, "$1," );
    };

    const contract = epoch >= Number( process.env.REACT_APP_EPOCH ) ? {
        address: process.env.REACT_APP_PRIDICTION_CONTRACT,
        abi: pridictionnewabi,
    } : {
        address: process.env.REACT_APP_OLD_PRIDICTION_CONTRACT,
        abi: pridictionMarketAbi,
    };

    const {data: roundDataFromSmartContract, isLoading: isRoundDataLoading} = useContractRead( {
        ...contract,
        functionName: "rounds",
        args: [epoch],
    } );

    const {data: isClaimable, isLoading: isClaimableLoading} = useContractRead( {
        ...contract,
        functionName: "claimable",
        args: [epoch, address],
    } );

    console.log( "is claimable", isClaimable );

    const {config: claimWinningsConfig} = usePrepareContractWrite( {
        ...contract,
        functionName: "claim",
        args: [[epoch]],
        enabled: isClaimable,
        onError: ( e ) => {
            console.log( "error claiming", e );
        }
    } );

    console.log( "epoch double array", [[epoch]] );
    const {writeAsync: claim} = useContractWrite( claimWinningsConfig );

    const claimWinnings = async () => {
        if ( !claim ) {
            throw Error( "Error with claim, please try again" );
        }
        const tx = await claim();
        await tx.wait();
        refetchUserData();
    };

    const status = useMemo( () => {
        //When data is still loading
        if (
            isRoundDataLoading ||
            !roundDataFromSmartContract ||
            !userRoundData ||
            isClaimableLoading
        )
            return "Loading...";

        //Waiting for round to complete
        if ( !roundDataFromSmartContract.roundClosed )
            return (
                <Box className={classes.timeStamp}>
                    {/* <Stack direction="row" justifyContent="center" alignItems="center"> */}
                    <Box>
                        <Typography className={classes.timeStampTitle}>Pool locks in</Typography>
                        <Countdown date={dayjs.unix( dbRoundData?.lockTimestamp ).toDate()} />
                    </Box>
                    &nbsp; <Box className={classes.divider} /> &nbsp;
                    <Box>
                        <Typography className={classes.timeStampTitle}>Pool settles in</Typography>
                        <Countdown date={dayjs.unix( dbRoundData?.closeTimestamp ).toDate()} />
                    </Box>
                    {/* </Stack> */}
                </Box>
            );


        if ( roundDataFromSmartContract.outcome === 2 ) return "Draw";

        if ( position === String( roundDataFromSmartContract.outcome ) ) return "Win";

        if ( claimed ) return "Claimed";

        // User has lost
        return "Lose";
    }, [
        isRoundDataLoading,
        roundDataFromSmartContract,
        userRoundData,
        isClaimableLoading,
        classes.timeStamp,
        classes.timeStampTitle,
        classes.divider,
        dbRoundData?.lockTimestamp,
        dbRoundData?.closeTimestamp,
        claimed,
        position,
    ] );

    return (
        <TableRow className={classes.tRows}>
            <TableCell
                align='center'
                className={classes.tCell}>
                <Link
                    className={classes.tLink}
                    to={`/hilocard/${ dbRoundData.epochId }`}
                    underline='none'>
                    <Stack
                        direction='row'
                        justifyContent='flex-start'
                        alignItems='center'
                        spacing={2}
                        sx={{ml: 5}}>
                        <Box
                            component='img'
                            style={{width: 40, height: 40}}
                            src={`${ dbRoundData?.tokenImageUrl }`}
                            alt='Token'
                        />
                        <Typography>{dbRoundData.tokenName}</Typography>
                    </Stack>
                </Link>
            </TableCell>
            <TableCell
                align='center'
                className={classes.tCell}>
                <Link
                    className={classes.tLink}
                    to={`/hilocard/${ dbRoundData.epochId }`}
                    underline='none'>
                    {dbRoundData.tokenSymbol}
                </Link>
            </TableCell>
            <TableCell
                align='center'
                className={classes.tCell}>
                <Link
                    className={classes.tLink}
                    to={`/hilocard/${ dbRoundData.epochId }`}
                    underline='none'>
                    {position === "1" ? "Lower" : "Higher"}
                </Link>
            </TableCell>
            <TableCell
                align='center'
                className={classes.tCell}>
                <Link
                    className={classes.tLink}
                    to={`/hilocard/${ dbRoundData.epochId }`}
                    underline='none'>
                    {ethers.utils.formatEther( amount )} $HILO
                </Link>
            </TableCell>
            <TableCell
                align='center'
                className={classes.tCell}>
                <Link
                    className={classes.tLink}
                    to={`/hilocard/${ dbRoundData.epochId }`}
                    underline='none'>
                    {status}
                </Link>
            </TableCell>
            <TableCell
                align='center'
                className={classes.tCell}>
                <ClaimWinningsButton
                    className={classes.claimBtn}
                    isClaimable={isClaimable}
                    claimWinnings={claimWinnings}
                />
            </TableCell>
        </TableRow>
    );
}
export default RoundRow;
