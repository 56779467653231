import {useState, useEffect, useMemo} from "react";
import clsx from "clsx";
import {makeStyles} from "@mui/styles";
import styles from "assets/jss/tableStyles";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Stack,
    Typography,
    Select,
    MenuItem,
    Button,
    Pagination, // Import Pagination component
} from "@mui/material";
import RoundRow from "./RoundRow";
import {useAccount, useNetwork, useContractReads} from "wagmi";
import {getUserRounds} from "api/rounds";
import {getUserQuestionRounds} from "api/questions";
import {find} from "lodash";
import {useInterval} from "hooks/useInterval";
import Web3 from "web3";
import pridictionMarketAbi from "../../contracts/predictionMarketContract.json";
import pridictionnewabi from "../../contracts/pridictionnewabi.json";

import {BigNumber, ethers} from "ethers";
import {useNavigate} from "react-router-dom";

const web3 = new Web3();

const useStyles = makeStyles( styles );

const Positions = () => {
    const classes = useStyles();
    const {address, isConnecting} = useAccount();
    const {chain} = useNetwork();

    const [roundDataFromDb, setRoundDataFromDb] = useState( [] );
    const [smartContractRoundData, setSmartContractRoundData] = useState( [] );
    const [contracts, setContracts] = useState( [] );
    const [sortKey, setSortKey] = useState( "amountBet" );
    const [isUserRoundDataLoading, setIsUserRoundDataLoading] = useState( false );
    const [currentPage, setCurrentPage] = useState( 1 ); // Current page



    useEffect( () => {
        if ( chain.id !== Number( process.env.REACT_APP_ARB_CHAIN_ID ) ) {
            window.alert(
                "Please connect your wallet - to place your predictions, make sure you are are connected on ARB chain and you have enough for Gas fees."
            );
        }
    }, [chain] );

    function refreshPage () {
        window.location.reload();
    }

    const fetchUserRounds = async ( address, currentPage ) => {
        setIsUserRoundDataLoading( true );

        const {data: roundDataFromDb} = await getUserQuestionRounds( address, currentPage );
        console.log( "user round from position page", roundDataFromDb );
        if ( roundDataFromDb?.userDbRoundData?.length ) {
            const result = [];
            for ( const round of roundDataFromDb.userDbRoundData ) {
                const contract = round.epochId >= Number( process.env.REACT_APP_EPOCH ) ? {
                    address: process.env.REACT_APP_PRIDICTION_CONTRACT,
                    abi: pridictionnewabi,
                } : {
                    address: process.env.REACT_APP_OLD_PRIDICTION_CONTRACT,
                    abi: pridictionMarketAbi,
                };
                result.push( {
                    ...contract,
                    functionName: "rounds",
                    args: [round.epochId],
                    chainId: chain?.id ?? 42161,
                } );
            }
            setContracts( [...result] );
            setRoundDataFromDb( roundDataFromDb );
        } else {
            setSmartContractRoundData( [] );
            setRoundDataFromDb( [] );
        }

        setIsUserRoundDataLoading( false );
    };

    useEffect( () => {
        setSmartContractRoundData( [] );
        setRoundDataFromDb( [] );
    }, [address] );

    useEffect( () => {
        if ( address && !roundDataFromDb.length && !smartContractRoundData.length ) {
            fetchUserRounds( address, currentPage );
        }
    }, [address, roundDataFromDb.length, smartContractRoundData.length, currentPage] );

    useInterval( () => {
        if ( !address ) return;
        fetchUserRounds( address, currentPage );
    }, 60000 );

    const contract = {
        address: process.env.REACT_APP_PRIDICTION_CONTRACT,
        abi: pridictionMarketAbi,
    };

    useContractReads( {
        contracts,
        enabled: !!contracts.length,
        onSuccess: ( data ) => {
            setSmartContractRoundData( data );
            setContracts( [] );
        },
    } );


    const sortedRounds = useMemo( () => {
        const rounds = [];

        if ( !address || isConnecting ) {
            return [];
        }
        if (
            smartContractRoundData.length &&
            roundDataFromDb?.userDbRoundData?.length
        ) {
            smartContractRoundData.forEach( ( round ) => {
                if ( !round?.epoch?.toNumber() ) return;

                const dbRound = find( roundDataFromDb.userDbRoundData, {
                    epochId: round.epoch.toNumber(),
                } );

                const roundIndex = roundDataFromDb.userRoundNumbers.indexOf(
                    round.epoch.toNumber()
                );
                console.log( "round index", roundIndex );
                const userRoundData = roundDataFromDb.userRoundInfo[roundIndex];

                rounds.push( {
                    ...dbRound,
                    ...round,
                    amountBet: ethers.utils.formatEther( userRoundData[1] ),
                    userRoundData,
                } );
            } );

            return rounds.sort( ( a, b ) => {
                if ( a[sortKey] < ( b[sortKey] ) ) {
                    return 1;
                } else if ( a[sortKey] > ( b[sortKey] ) ) {
                    return -1;
                } else {
                    return 0;
                }
            } );
        } else {
            return [];
        }
    }, [address, isConnecting, smartContractRoundData, sortKey] );

    const handleChangeSortKey = ( e ) => {
        setSortKey( e.target.value );
    };

    const navigate = useNavigate();
    const goToPositions = () => {
        navigate( '/positions' );
    };

    return (
        <Box className={classes.mainContainer}>
            <Box>
                <Stack
                    direction='row'
                    justifyContent='flex-end'
                    alignItems='center'
                    spacing={2}
                    sx={{
                        maxWidth: "1320px",
                        margin: "auto",
                    }}>
                    <Button onClick={goToPositions}>token positions</Button>
                    <Typography className={classes.sortBy}>Sort by:</Typography>
                    <Select
                        disableUnderline={true}
                        variant='standard'
                        className={classes.sorter}
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        value={sortKey}
                        label='Age'
                        onChange={handleChangeSortKey}>
                        <MenuItem value={"amountBet"}>Amount bet</MenuItem>
                        <MenuItem value={"closeTimestamp"}>Round close</MenuItem>
                        <MenuItem value={"lockTimestamp"}>Round lock</MenuItem>
                        <MenuItem value={"userRoundData"}>Claimed</MenuItem>
                    </Select>
                </Stack>
            </Box>
            <Box>
                <TableContainer
                    className={classes.block}
                    component={Paper}>
                    <Table
                        className={classes.body}
                        sx={{minWidth: 650}}
                        aria-label='simple table'>
                        <TableHead className={clsx( classes.trow, classes.header )}>
                            <TableRow>
                                <TableCell
                                    align='center'
                                    className={classes.tCell}>
                                    NAME
                                </TableCell>
                                <TableCell
                                    align='center'
                                    className={classes.tCell}>
                                    TICKER
                                </TableCell>
                                <TableCell
                                    align='center'
                                    className={classes.tCell}>
                                    PREDICTIONS
                                </TableCell>
                                <TableCell
                                    align='center'
                                    className={classes.tCell}>
                                    AMOUNT
                                </TableCell>
                                <TableCell
                                    align='center'
                                    className={classes.tCell}>
                                    STATUS
                                </TableCell>
                                <TableCell
                                    align='center'
                                    className={classes.tCell}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className={classes.body}>
                            {!!sortedRounds?.length &&
                                sortedRounds.map( ( round, key ) => {
                                    return (
                                        <RoundRow
                                            key={key}
                                            epoch={round.epochId}
                                            userRoundData={round.userRoundData}
                                            dbRoundData={round}
                                            refetchUserData={refreshPage}
                                        />
                                    );
                                } )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box
                    className={classes.pagination}
                    component='div'
                    marginTop='42px'
                    display='flex'
                    justifyContent='flex-end'>
                    <Pagination
                        count={Math.ceil( roundDataFromDb.userRoundsLength / 10 )}
                        page={currentPage}
                        onChange={( event, page ) => {
                            setCurrentPage( page );
                            fetchUserRounds( address, page );

                        }}
                        className={classes.pagination} // Add your custom styling here
                    />
                </Box>

                <Box>
                    {!isUserRoundDataLoading && !roundDataFromDb?.userDbRoundData?.length && (
                        <Stack
                            direction='row'
                            justifyContent='center'
                            alignItems='center'>
                            <Typography className={classes.emptyTable}>
                                You do not have any positions yet
                            </Typography>
                        </Stack>
                    )}
                    {isUserRoundDataLoading && !roundDataFromDb?.userDbRoundData?.length && (
                        <Stack
                            direction='row'
                            justifyContent='center'
                            alignItems='center'>
                            <Typography className={classes.emptyTable}>
                                Refreshing position data...
                            </Typography>
                        </Stack>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default Positions;
