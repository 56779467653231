import { useState } from "react";
import { Button, Stack, Dialog, DialogContent, DialogTitle, Box, Tab } from "@mui/material";
import CreateRoundForm from "./CreateRoundForm";
import CreateQuestionForm from "./CreateQuestionForm";

function CreateRound({ fetchRounds }) {
    const [openCreateRoundForm, setOpenCreateRoundForm] = useState(false);
    const [openCreateQuestionForm, setOpenCreateQuestionForm] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmittingQuestion, setIsSubmittingQuestion] = useState(false);

    const handleClickOpen = () => {
        setOpenCreateRoundForm(true);
    };

    const handleClose = () => {
        if (isSubmitting) return;
        setOpenCreateRoundForm(false);
    };
    const handleClickOpenQuestion = () => {
        setOpenCreateQuestionForm(true);
    };

    const handleCloseQuestion = () => {
        if (isSubmitting) return;
        setOpenCreateQuestionForm(false);
    };
    return (
        <Box>
            <Stack
                direction='row'
                justifyContent='flex-start'
                alignItems='center'
                spacing={2}>
                <Button
                    sx={{ color: "white", borderColor: "white" }}
                    variant='outlined'
                    onClick={handleClickOpen}>
                    Create round series
                </Button>
                <Button
                    sx={{ color: "white", borderColor: "white" }}
                    variant='outlined'
                    onClick={handleClickOpenQuestion}>
                    Create question
                </Button>
            </Stack>
            {/* create round dialog */}
            <Dialog
                open={openCreateRoundForm}
                onClose={handleClose}>
                <DialogTitle>Create a new round</DialogTitle>
                <DialogContent>
                    <CreateRoundForm
                        fetchRounds={fetchRounds}
                        handleClose={handleClose}
                        setIsSubmitting={setIsSubmitting}
                    />
                </DialogContent>
            </Dialog>
            {/* create question dialog */}
            <Dialog
                open={openCreateQuestionForm}
                onClose={handleCloseQuestion}>
                <DialogTitle>Create a new round</DialogTitle>
                <DialogContent>
                    <CreateQuestionForm
                        fetchRounds={fetchRounds}
                        handleClose={handleCloseQuestion}
                        setIsSubmitting={setIsSubmitting}
                    />
                </DialogContent>
            </Dialog>
        </Box>
    );
}

export default CreateRound;
