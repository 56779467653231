import axios from "axios";
import { BACKEND_API_URL } from "../configs";

const api = axios.create({
  baseURL: BACKEND_API_URL,
  responseType: "json",
});

api.interceptors.request.use(
  async function (config) {
    const message = window.localStorage.getItem("message"),
      messageToken = window.localStorage.getItem("message-token");

    if (message && messageToken) {
      config.headers.authorization = `Bearer ${btoa(message)}:${messageToken}`;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default api;
