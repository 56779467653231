import {
    useNetwork,
    useAccount,
    useContractRead,
    useSwitchNetwork,
    usePrepareContractWrite,
    useContractWrite,
} from "wagmi";
import { ethers } from "ethers";
import { Button } from "@mui/material";
import arbcashierabi from "../../contracts/cashierArb.json";
import ethCahierabi from "../../contracts/cashierEth.json";

const CallWithSignature = ({ signature, id, amount, type }) => {
    const { chain } = useNetwork();

    const { config: mintConfig } = usePrepareContractWrite({
        address: process.env.REACT_APP_ARB_CASHIER,
        abi: arbcashierabi,
        functionName: "mint",
        args: [signature, id, amount],
        enabled: chain.id === Number(process.env.REACT_APP_ARB_CHAIN_ID),
        onError: (e)=>{
            console.log('error minting', e)
        }
    });
    const { write: callMint } = useContractWrite(mintConfig);

    const { config: unlockConfig } = usePrepareContractWrite({
        address: process.env.REACT_APP_ETH_CASHIER,
        abi: ethCahierabi,
        functionName: "unlockTokens",
        args: [signature, id, amount],
        enabled: chain.id === Number(process.env.REACT_APP_ETH_CHAIN_ID),
        onError: (e) => {
            console.log("error unlock", e);
        },
    });
    const { write: callUnlock } = useContractWrite(unlockConfig);

    const mint = () => {
        callMint?.();
    };

    const unlock = () => {
        callUnlock?.();
    };
    return (
        <Button
            variant='contained'
            onClick={chain?.id === Number(process.env.REACT_APP_ARB_CHAIN_ID) ? mint : unlock}>
            {chain?.id === Number(process.env.REACT_APP_ARB_CHAIN_ID) ? "mint" : "unlock"}
        </Button>
    );
};

export default CallWithSignature;
