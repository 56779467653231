import api from "./index.js";
export function getQuestions() {
    return api.get("api/questions");
}

export function getUserQuestionRounds(address, page) {
    return api.get(`/api/questions/getQuestionRounds/${address}/${page}`);
}

export function getAdminQuestionRounds() {
    return api.get("api/questions/adminQuestionRounds");
}

export function postCreateQuestion(params) {
    return api.post("/api/questions/createQuetion", { ...params });
}

export function getSingleQuestion(round) {
    return api.get(`/api/questions/getQuestion/${round}`);
}

export function postCloseQuestionRound(params) {
    return api.post("/api/questions/closeQuestionRound", { ...params });
}

export function postUpdateQuestionRound(params) {
    return api.post("/api/questions/updateQuestionRound", { ...params });
}
