import { useCallback, useState, useEffect } from "react";
import {
    usePrepareContractWrite,
    useContractWrite,
    useWaitForTransaction,
    useContractRead,
    useNetwork,
    useAccount,
} from "wagmi";
import Web3 from "web3";
import { isEthChain } from "utils/helpers";
import ethCashierAbi from "contracts/cashierEth.json";
import arbCashierAbi from "contracts/cashierArb.json";
import hiloTokenAbi from "contracts/hiloTokenContractAbi.json";

const web3 = new Web3();

export const useCashier = (amount = web3.utils.toWei("0")) => {
    const { chain } = useNetwork();
    const { address: currentUserAddress, isConnected } = useAccount();
    const isOnEthChain = chain?.id && isEthChain(chain?.id);

    //! Deposits to cashier
    const { config: depositHiloConfig } = usePrepareContractWrite({
        address: process.env.REACT_APP_ETH_CASHIER,
        abi: ethCashierAbi,
        functionName: "deposit",
        args: [amount],
        enabled: isOnEthChain,
    });

    console.log("chain id", chain?.id);

    const { data: depositTx, writeAsync: deposit } = useContractWrite(depositHiloConfig);

    const depositIntoCashier = async () => {
        if (!deposit) {
            throw Error("Error with deposit, please try again");
        }
        console.log("I am making a deposit");
        const result = await deposit();
        const response = await result.wait();
        return response?.transactionHash;
    };

    const { isLoading: isDepositLoading } = useWaitForTransaction({
        hash: depositTx?.hash,
    });

    //! Withdrawals from cashier
    const { config: withdrawHiloConfig } = usePrepareContractWrite({
        address: process.env.REACT_APP_ARB_CASHIER,
        abi: arbCashierAbi,
        functionName: "burn",
        args: [currentUserAddress, amount],
        enabled: !isOnEthChain,
    });

    const { data: withdrawalTx, writeAsync: withdrawal } = useContractWrite(withdrawHiloConfig);

    console.log("I am the withdrawal", withdrawal);

    const { isLoading: isWithdrawalLoading } = useWaitForTransaction({
        hash: withdrawalTx?.hash,
    });

    const withdrawalFromCashier = async () => {
        if (!withdrawal) {
            throw Error("Error with withdrawal, please try again");
        }
        console.log("I am making a withdrawal");
        const result = await withdrawal();
        const tx = await result.wait();
        return tx.transactionHash;
    };

    //! check how much user has approved cashier for
    const [approvedAmount, setApprovedAmount] = useState(null);

    const checkAllowance = useContractRead({
        address: process.env.REACT_APP_HILO_TOKEN,
        abi: hiloTokenAbi,
        functionName: "allowance",
        args: [currentUserAddress, process.env.REACT_APP_ETH_CASHIER],
        enabled: false,
        onError: () => setApprovedAmount("0"),
        onSuccess: (data) => setApprovedAmount(data?.toString()),
    });

    const fetchApprovedAmount = useCallback(async () => {
        const newApprovedAmount = await checkAllowance.refetch();
        setApprovedAmount(newApprovedAmount?.data?.toString());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chain?.id, currentUserAddress]);

    useEffect(() => {
        if (chain?.id && currentUserAddress && isConnected) {
            fetchApprovedAmount();
        }
    }, [chain?.id, currentUserAddress, fetchApprovedAmount, isConnected]);

    //! approve cashier for deposits
    const { config: approveErc20Config } = usePrepareContractWrite({
        address: process.env.REACT_APP_HILO_TOKEN,
        abi: hiloTokenAbi,
        functionName: "approve",
        args: [
            process.env.REACT_APP_ETH_CASHIER,
            "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff",
        ],
    });

    const { data: erc20ApprovaleTx, writeAsync: approveContractForBets } =
        useContractWrite(approveErc20Config);

    const approveErc20 = async () => {
        const result = await approveContractForBets();
        await result.wait();
        await fetchApprovedAmount();
        window.location.reload();
    };

    const { isLoading: isCashierApprovalLoading } = useWaitForTransaction({
        hash: erc20ApprovaleTx?.hash,
    });

    //! check user's balance on the cashier
    const [hiloBalanceOnCashier, setHiloBalanceOnCashier] = useState(0);

    const fetchHiloBalanceOnCashier = useContractRead({
        address:
            chain?.id === Number(process.env.REACT_APP_ETH_CHAIN_ID)
                ? process.env.REACT_APP_HILO_TOKEN
                : process.env.REACT_APP_ARB_CASHIER,
        abi:
            chain?.id === Number(process.env.REACT_APP_ETH_CHAIN_ID) ? hiloTokenAbi : arbCashierAbi,
        functionName: "balanceOf",
        args: [currentUserAddress],
        enabled: false,
        onSuccess: (data) => {
            setHiloBalanceOnCashier(data.toString());
        },
    });

    return {
        depositIntoCashier,
        isDepositLoading,
        withdrawalFromCashier,
        isWithdrawalLoading,
        approveErc20,
        isCashierApprovalLoading,
        fetchApprovedAmount,
        approvedAmount,
        fetchHiloBalanceOnCashier,
        hiloBalanceOnCashier,
    };
};
