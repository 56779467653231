import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";

import styles from "assets/jss/landingPageStyles";
import "react-multi-carousel/lib/styles.css";
import PublicRounds from "components/rounds/PublicRounds";

const useStyles = makeStyles(styles);

const Landing = () => {
  const classes = useStyles();

  return (
    <Box className={classes.mainContainer}>
      <PublicRounds />
    </Box>
  );
};

export default Landing;
