import {useEffect, useState} from "react";
import {Typography, Box, Grid} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CountUp from "react-countup";
import Loader from "../../assets/img/loader.svg";
import axios from "axios";
import {formatCurrency} from "utils/helpers";

const Stats = () => {
    const [loading, setLoading] = useState( true );
    const [error, setError] = useState( false );
    const [statsData, setStatsData] = useState( {} );
    console.log( "statsData", statsData );

    useEffect( () => {
        // Get volume
        axios
            .get( process.env.REACT_APP_DATA_URL + "api/stats/getVolumes" )
            .then( ( res ) => {
                // Get amounts
                axios
                    .get( process.env.REACT_APP_DATA_URL + "api/stats/getAmounts" )
                    .then( ( response ) => {
                        const manuallyProvidedNumbers = {
                            totalVolume: 30313602,
                            totalVolumeUsd: 4540745,
                            bullVolume: 14484660,
                            bullVolumeUsd: 2169691,
                            bearVolume: 15828942,
                            bearVolumeUsd: 2371054,
                        };
                        const updatedVolumes = {
                            totalVolume: res.data.totalVolume + manuallyProvidedNumbers.totalVolume,
                            totalVolumeUsd:
                                res.data.totalVolumeUsd + manuallyProvidedNumbers.totalVolumeUsd,
                            bullVolume: res.data.bullVolume + manuallyProvidedNumbers.bullVolume,
                            bullVolumeUsd:
                                res.data.bullVolumeUsd + manuallyProvidedNumbers.bullVolumeUsd,
                            bearVolume: res.data.bearVolume + manuallyProvidedNumbers.bearVolume,
                            bearVolumeUsd:
                                res.data.bearVolumeUsd + manuallyProvidedNumbers.bearVolumeUsd,
                        };
                        setStatsData( {
                            volumes: updatedVolumes,
                            amounts: response.data,
                        } );
                        setLoading( false );
                    } )
                    .catch( ( error ) => {
                        setError( true );
                        setLoading( false );
                    } );
            } )
            .catch( ( err ) => {
                setError( true );
                setLoading( false );
            } );
    }, [] );

    return (
        <>
            {loading ? (
                <Box
                    display='flex'
                    justifyContent='center'
                    minHeight='100vh'
                    width='100%'>
                    <img
                        src={Loader}
                        alt='Loader'
                    />
                </Box>
            ) : error ? (
                <Box
                    display='flex'
                    justifyContent='center'
                    minHeight='100vh'
                    width='100%'>
                    <Typography
                        color={{xs: "rgba(255, 255, 255, 0.5)", sm: "white"}}
                        fontSize={"24px"}
                        fontWeight={"500"}
                        marginBottom={{xs: "24px", sm: "0px"}}
                        marginTop='200px'>
                        Unable to fetch data.
                    </Typography>
                </Box>
            ) : (
                <Box
                    position='relative'
                    marginTop={{xs: "50px", md: "130px"}}
                    marginBottom='50px'
                    width={{xs: "90%", lg: "995px"}}
                    marginLeft='auto'
                    marginRight='auto'>
                    <Typography
                        color='white'
                        fontSize={"24px"}
                        fontWeight={"500"}>
                        Analytics
                    </Typography>
                    <Box
                        padding={{xs: "20px", md: "43px"}}
                        borderRadius='20px'
                        backgroundColor='#0C1C2E'
                        border='1px solid #848E9A'
                        marginTop={{xs: "30px"}}>
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                marginTop={{xs: "50px", md: "0px"}}>
                                <Typography
                                    color='#818892'
                                    fontSize='24.58px'
                                    fontWeight='500'>
                                    BETS VOLUME (HILO)
                                </Typography>
                                <Typography
                                    fontWeight='500'
                                    fontSize={{xs: "50px", md: "86px"}}
                                    color='white'
                                    lineHeight={{md: "103px"}}>
                                    <CountUp
                                        end={statsData.volumes.totalVolume}
                                        duration={2}
                                        formattingFn={( val ) => {
                                            return val.toLocaleString( "en-US", {
                                                maximumFractionDigits: 0,
                                            } );
                                        }}
                                    />
                                    <Typography
                                        color='white'
                                        fontSize='20px'
                                        fontWeight='500'>
                                        ( {formatCurrency( statsData.volumes.totalVolumeUsd )} )
                                    </Typography>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid
                                display='flex'
                                flexDirection={{xs: "column", sm: "row"}}
                                item
                                xs={12}
                                lg={6}
                                marginTop={{xs: "20px", md: "56px"}}>
                                <Typography
                                    color='#818892'
                                    fontSize={{xs: "16px", md: "24.58px"}}
                                    fontWeight='500'
                                    marginRight='2px'>
                                    BULLISH VOLUME
                                </Typography>
                                <Box>
                                    <Box
                                        display='flex'
                                        alignItems='center'>
                                        <ArrowUpwardIcon
                                            htmlColor='white'
                                            fontSize='large'
                                        />
                                        <Typography
                                            color='white'
                                            fontSize='30px'
                                            fontWeight='500'>
                                            {statsData.volumes.bullVolume.toLocaleString( "en-US", {
                                                maximumFractionDigits: 0,
                                            } )}
                                        </Typography>
                                    </Box>
                                    <Typography
                                        color='white'
                                        fontSize='20px'
                                        fontWeight='500'
                                        textAlign={{sm: "right"}}>
                                        ( {formatCurrency( statsData.volumes.bullVolumeUsd )} )
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid
                                display='flex'
                                flexDirection={{xs: "column", sm: "row"}}
                                justifyContent={{lg: "flex-end"}}
                                item
                                xs={12}
                                lg={6}
                                marginTop={{xs: "20px", md: "56px"}}>
                                <Typography
                                    color='#818892'
                                    fontSize={{xs: "16px", md: "24.58px"}}
                                    fontWeight='500'
                                    marginRight='2px'>
                                    BEARISH VOLUME
                                </Typography>
                                <Box>
                                    <Box
                                        display='flex'
                                        alignItems='center'>
                                        <ArrowDownwardIcon
                                            htmlColor='white'
                                            fontSize='large'
                                        />
                                        <Typography
                                            color='white'
                                            fontSize='30px'
                                            fontWeight='500'>
                                            {statsData.volumes.bearVolume.toLocaleString( "en-US", {
                                                maximumFractionDigits: 0,
                                            } )}
                                        </Typography>
                                    </Box>
                                    <Typography
                                        color='white'
                                        fontSize='20px'
                                        fontWeight='500'
                                        textAlign={{sm: "right"}}>
                                        ( {formatCurrency( statsData.volumes.bearVolumeUsd )} )
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Grid
                        container
                        marginTop='37px'
                        gap={{xs: 0, md: 2}}>
                        <Grid
                            item
                            xs={6}
                            sm={3}
                            lg={2}
                            marginBottom={{xs: "20px", md: "0px"}}>
                            <Typography
                                color='#818892'
                                fontSize={{xs: "16px", md: "20px"}}
                                fontWeight='500'>
                                Total HILO In Pools
                            </Typography>
                            <Typography
                                color='white'
                                fontSize='40px'
                                fontWeight='500'>
                                {statsData.amounts.stakedAmount
                                    ? statsData?.amounts?.stakedAmount.toLocaleString( "en-US", {
                                        maximumFractionDigits: 0,
                                    } )
                                    : "0"}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    );
};
export default Stats;
