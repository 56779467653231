import {useState} from "react";
import {
    Button,
    TableCell,
    TableRow,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Select,
    MenuItem,
    TextField,
} from "@mui/material";
import styles from "assets/jss/tableStyles";
import {makeStyles} from "@mui/styles";
import Web3 from "web3";
import {useNetwork, useContractRead} from "wagmi";
import predictionMarketAbi from "../../contracts/predictionMarketContract.json";
import {postCloseQuestionRound} from "../../api/questions";

const useStyles = makeStyles( styles );

function AdminQuestionRoundRow ( {
    roundData,
    fetchRounds,
    setSnackbarData,
    handleSnackbarOpen,
    setShowEditQuestionRoundForm,
    setQuestionRoundToEdit,
} ) {
    const classes = useStyles();

    const [openDialog, setOpenDialog] = useState( false );
    const [roundOutcome, setRoundOutcome] = useState( 0 );

    const {epochId, lockTimestamp, closeTimestamp, questionName, questionOutcome} = roundData;
    console.log( "rounds to edit from Question round row", roundData );

    const handleClickOpen = () => {
        setOpenDialog( true );
    };
    const handleClose = () => {
        setOpenDialog( false );
    };

    const {data: dataFromSmartContract} = useContractRead( {
        address: process.env.REACT_APP_PRIDICTION_CONTRACT,
        abi: predictionMarketAbi,
        functionName: "rounds",
        args: [epochId],
        enabled: Math.floor( Date.now() / 1000 ) > closeTimestamp && questionOutcome,
    } );

    const closeRound = async () => {
        try {
            await postCloseQuestionRound( {
                roundToEnd: epochId,
                outcome: roundOutcome,
            } );
            fetchRounds();
            setSnackbarData( {
                message: `Successfully closed round #${ epochId }`,
                severity: "success",
            } );
            handleSnackbarOpen();
            handleClose();
        } catch ( e ) {
            console.log( "error closing round", e );
            setSnackbarData( {
                message: `There was an error closing round #${ epochId } - ${ e }`,
                severity: "error",
            } );
            handleSnackbarOpen();
            console.log( e );
        }
    };
    const roundOutcomeChange = ( e ) => {
        setRoundOutcome( e.target.value );
    };

    const handleClickOpenEditQuestionRound = () => {
        setQuestionRoundToEdit( roundData );
        setShowEditQuestionRoundForm( true );
    };

    return (
        <>
            <Dialog
                open={openDialog}
                onClose={handleClose}>
                <DialogTitle>End round</DialogTitle>
                <DialogContent>
                    <DialogContentText>Select outcome for round:</DialogContentText>
                    <Select
                        value={roundOutcome}
                        onChange={roundOutcomeChange}>
                        <MenuItem value={0}>{roundData.choiceOne}</MenuItem>
                        <MenuItem value={1}>{roundData.choiceTwo}</MenuItem>
                        <MenuItem value={2}>Draw</MenuItem>
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={closeRound}>Submit</Button>
                </DialogActions>
            </Dialog>
            <TableRow className={classes.tRows}>
                <TableCell
                    align='center'
                    className={classes.tCell}>
                    {epochId}
                </TableCell>
                <TableCell
                    align='center'
                    className={classes.tCell}>
                    {questionName}
                </TableCell>
                <TableCell
                    align='center'
                    className={classes.tCell}>
                    {new Date( lockTimestamp * 1000 ).toUTCString()}
                </TableCell>
                <TableCell
                    align='center'
                    className={classes.tCell}>
                    {new Date( closeTimestamp * 1000 ).toUTCString()}
                </TableCell>
                <TableCell
                    align='center'
                    className={classes.tCell}>
                    <Button
                        className={classes.claimBtn}
                        onClick={handleClickOpen}
                    >
                        Close round
                    </Button>

                    {/* <ClaimWinningsButton
      isClaimable={
        isClaimable ||
        isClaimableLoading ||
        isCheckRefundLoading ||
        isRefundable
      }
      claimWinnings={claimWinnings}
      epoch={epoch}
    /> */}
                    <Button
                        className={classes.claimBtn}
                        sx={{color: "white", borderColor: "white"}}
                        onClick={handleClickOpenEditQuestionRound}>
                        Edit Question
                    </Button>
                </TableCell>
            </TableRow>
        </>
    );
}

export default AdminQuestionRoundRow;
