import { Button } from "@mui/material";
import styles from "assets/jss/positionsPageStyles";
import { makeStyles } from "@mui/styles";
import {
  usePrepareContractWrite,
  useContractWrite,
  useContractRead,
} from "wagmi";

const useStyles = makeStyles(styles);

function ClaimWinningsButton({ isClaimable, claimWinnings }) {
  const classes = useStyles();

  return (
    <Button
      onClick={claimWinnings}
      disabled={!isClaimable}
      className={classes.claimBtn}
    >
      Claim
    </Button>
  );
}

export default ClaimWinningsButton;
