import React, { useEffect, useRef, useState } from "react";
import { Search, Clear } from "@mui/icons-material";
import {
    Typography,
    Box,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Pagination,
} from "@mui/material";
import TropyOne from "../../assets/img/trophy-1.svg";
import TropyTwo from "../../assets/img/trophy-2.svg";
import TropyThree from "../../assets/img/trophy-3.svg";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import styles from "assets/jss/tableStyles";
import Axios from "axios";
import Loader from "../../assets/img/loader.svg";
import { Web3ProviderURL, AddrLPToken, ABILPToken } from "../../config/config.js";
import {
    convertWinningRate,
    convertNumberToLocaleString,
    convertAddress,
    convertUSDTPrice,
    restructorArray,
} from "../../utils/helpers";

import Web3 from "web3";
const provider = Web3ProviderURL;
const web3Provider = new Web3.providers.HttpProvider(provider);
const web3 = new Web3(web3Provider);
const LPContract = new web3.eth.Contract(ABILPToken, AddrLPToken);

const LeaderBoard = () => {
    const useStyles = makeStyles(styles);
    const classes = useStyles();
    const [playersInfo, setPlayersInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [topPlayersData, setTopPlayersData] = useState([]);
    const [tableCopy, setTableCopy] = useState([]);
    const [sortId, setSortId] = useState("netPayoutToken");
    const [paginatedData, setPaginatedData] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const searchRef = useRef(null);

    const ComponentTableRow = ({ data }) => {
        return (
            <TableRow className={classes.tRows}>
                <TableCell
                    align='left'
                    className={classes.tCell}>
                    {data?.rank || ""}
                </TableCell>
                <TableCell
                    align='left'
                    className={classes.tCell}>
                    <Typography
                        component='span'
                        fontSize={{ xs: "11px", sm: "15px" }}
                        fontWeight='500'
                        color='#4166F4'>
                        <a
                            rel='noreferrer'
                            target='_blank'
                            style={{ color: "#4166F4" }}
                            href={`https://etherscan.io/address/${data?.address}#tokentxns`}>
                            {" "}
                            {data?.convertedAddress || ""}
                        </a>
                    </Typography>
                </TableCell>
                <TableCell
                    align='center'
                    className={classes.tCell}>
                    {data?.netPayoutToken + " " + "(" + data?.netPayout + ")"}
                </TableCell>
                <TableCell
                    align='center'
                    className={classes.tCell}>
                    {data?.winningRate || ""}
                </TableCell>
                <TableCell
                    align='center'
                    className={classes.tCell}>
                    {data?.winningRounds || "0"}
                </TableCell>
                <TableCell
                    align='center'
                    className={classes.tCell}>
                    {data?.roundsInvolved || "0"}
                </TableCell>
            </TableRow>
        );
    };
    useEffect(() => {
        async function fetchData() {
            Axios.get(
                process.env.REACT_APP_DATA_URL +
                    `api/leaderboard/getPlayers?searchId=0&sortId=${sortId}&sortDir=-1&gte=5`
            )
                .then(async (response) => {
                    if (response.data.length) {
                        const res = await LPContract.methods.getReserves().call();
                        const HiloAmount = web3.utils.fromWei(res[1], "ether");
                        const USDTAmount = web3.utils.fromWei(res[0], "mwei");
                        const hiloPrice = USDTAmount / HiloAmount;
                        let _formattedData = [];
                        response.data.forEach((item, index) => {
                            _formattedData.push({
                                ...item,
                                rank: index + 1,
                                netPayout: Number(item.netPayout)
                                    ? convertUSDTPrice(item.netPayout, hiloPrice)
                                    : "~$0",
                                netPayoutToken: item.netPayoutToken
                                    ? convertNumberToLocaleString(item.netPayoutToken)
                                    : "0",
                                winningRate:
                                    item?.winningRounds && item?.roundsInvolved
                                        ? convertWinningRate(
                                              item?.winningRounds,
                                              item?.roundsInvolved
                                          )
                                        : "0%",
                                convertedAddress: convertAddress(item.address),
                            });
                        });
                        setCurrentIndex(0);
                        setTopPlayersData(_formattedData.slice(0, 3));
                        let _tableData = _formattedData.slice(3);
                        setPlayersInfo(_formattedData);
                        setTableCopy(_tableData);
                        let _paginatedData = restructorArray(_tableData, 10);
                        setPaginatedData(_paginatedData);

                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setError(true);
                    setLoading(false);
                });
        }
        fetchData();
    }, [sortId]);

    const searchHandler = (value) => {
        let _tableCopy = [...tableCopy];
        if (!_tableCopy.length) {
            return;
        }
        if (!value) {
            setPaginatedData(restructorArray(_tableCopy, 10));
        } else {
            let _filterData = _tableCopy.filter((item) =>
                item.address.toLowerCase().includes(value.toLowerCase())
            );
            setPaginatedData(restructorArray(_filterData, 10));
        }
    };
    console.log(paginatedData);
    console.log(currentIndex);
    return (
        <>
            {loading ? (
                <Box
                    display='flex'
                    justifyContent='center'
                    minHeight='100vh'
                    width='100%'>
                    <img
                        src={Loader}
                        alt='Loader'
                    />
                </Box>
            ) : error ? (
                <Box
                    display='flex'
                    justifyContent='center'
                    minHeight='100vh'
                    width='100%'>
                    <Typography
                        color={{ xs: "rgba(255, 255, 255, 0.5)", sm: "white" }}
                        fontSize={"24px"}
                        fontWeight={"500"}
                        marginBottom={{ xs: "24px", sm: "0px" }}
                        marginTop='200px'>
                        Unable to fetch data.
                    </Typography>
                </Box>
            ) : !playersInfo.length ? (
                <Box
                    display='flex'
                    justifyContent='center'
                    minHeight='100vh'
                    width='100%'>
                    <Typography
                        color={{ xs: "rgba(255, 255, 255, 0.5)", sm: "white" }}
                        fontSize={"24px"}
                        fontWeight={"500"}
                        marginBottom={{ xs: "24px", sm: "0px" }}
                        marginTop='200px'>
                        No Data Found.
                    </Typography>
                </Box>
            ) : (
                <Box
                    position='relative'
                    marginTop={{ xs: "50px", sm: "100px" }}
                    marginBottom='50px'
                    maxWidth={{ xs: "90%", lg: "1320px" }}
                    marginLeft='auto'
                    marginRight='auto'>
                    <Box
                        display='flex'
                        justifyContent='space-between'
                        flexDirection={{ xs: "column", sm: "row" }}>
                        <Typography
                            color={{ xs: "rgba(255, 255, 255, 0.5)", sm: "white" }}
                            fontSize={"24px"}
                            fontWeight={"500"}
                            marginBottom={{ xs: "24px", sm: "0px" }}>
                            LeaderBoards
                        </Typography>
                        <Box
                            border='1px solid rgba(255, 255, 255, 0.3)'
                            borderRadius='100px'
                            color='white'
                            width={{ xs: "100%", sm: "230px" }}
                            position='relative'
                            display='flex'
                            justifyContent='flex-end'
                            alignItems='center'
                            height='39px'>
                            {searchRef.current && searchRef.current.value ? (
                                <Clear
                                    onClick={() => {
                                        searchRef.current.value = "";
                                        searchHandler("");
                                    }}
                                    style={{
                                        marginRight: "10px",
                                        zIndex: "100",
                                    }}
                                />
                            ) : (
                                <Search
                                    style={{
                                        marginRight: "10px",
                                    }}
                                />
                            )}

                            <input
                                ref={searchRef}
                                onChange={(e) => {
                                    searchHandler(e.target.value);
                                }}
                                type='text'
                                placeholder='Search'
                                style={{
                                    backgroundColor: "transparent",
                                    outline: "none",
                                    position: "absolute",
                                    inset: 0,
                                    borderRadius: "100px",
                                    color: "white",
                                    fontSize: "16px",
                                    padding: "13px 40px 13px 17px",
                                    border: "none",
                                    cursor: "pointer",
                                }}
                            />
                        </Box>
                    </Box>
                    <Grid
                        container={true}
                        display='flex'
                        justifyContent='space-between'
                        gap={{ xs: 0, sm: 4, xl: 0 }}>
                        <Grid
                            item
                            width={{ xs: "100%", md: "31%" }}>
                            {topPlayersData[0] ? (
                                <Box
                                    padding={{ xs: "32px", sm: "43px" }}
                                    borderRadius='40px'
                                    backgroundColor='#0C1C2E'
                                    marginTop={{ xs: "30px" }}
                                    border='0.5px solid #343435'>
                                    <Grid
                                        borderBottom='1px solid rgba(255, 255, 255, 0.25)'
                                        container={true}
                                        display='flex'
                                        justifyContent='space-between'
                                        paddingBottom='30px'>
                                        <Grid
                                            item
                                            xs={7}
                                            md={9}>
                                            <Box>
                                                <Typography
                                                    color='#818892'
                                                    fontSize={{ xs: "17px", sm: "24px" }}
                                                    fontWeight='500'>
                                                    Net Payout ($HILO)
                                                </Typography>
                                                <Typography
                                                    color='white'
                                                    fontSize={{ xs: "24px", sm: "32px" }}
                                                    fontWeight='500'>
                                                    {topPlayersData[0]?.netPayoutToken || ""}
                                                </Typography>
                                                <Typography
                                                    component='span'
                                                    fontSize={{ xs: "11px", sm: "15px" }}
                                                    fontWeight='500'
                                                    color='#4166F4'>
                                                    {topPlayersData[0]?.netPayout || ""}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid
                                            display={{ xs: "none", md: "block" }}
                                            item
                                            xs={4}
                                            md={3}>
                                            <img
                                                src={TropyOne}
                                                alt='Trophy'
                                            />
                                        </Grid>
                                        <Grid
                                            display={{ md: "none" }}
                                            item
                                            xs={4}
                                            md={3}>
                                            <img
                                                src={TropyOne}
                                                style={{ width: "80%" }}
                                                alt='Trophy'
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box
                                        container={true}
                                        display='flex'
                                        justifyContent='space-between'
                                        paddingTop='22px'>
                                        <Typography
                                            color='#818892'
                                            fontSize={{ xs: "11px", sm: "15px" }}
                                            fontWeight='500'>
                                            Address
                                        </Typography>
                                        <Typography
                                            component='span'
                                            fontSize={{ xs: "11px", sm: "15px" }}
                                            fontWeight='500'
                                            color='#4166F4'>
                                            <a
                                                rel='noreferrer'
                                                target='_blank'
                                                style={{ color: "#4166F4" }}
                                                href={`https://etherscan.io/address/${topPlayersData[0]?.address}#tokentxns`}>
                                                {" "}
                                                {topPlayersData[0]?.convertedAddress || ""}
                                            </a>
                                        </Typography>
                                    </Box>
                                    <Box
                                        container={true}
                                        display='flex'
                                        justifyContent='space-between'
                                        paddingTop='8px'>
                                        <Typography
                                            color='#818892'
                                            fontSize={{ xs: "11px", sm: "15px" }}
                                            fontWeight='500'>
                                            Winning Rate
                                        </Typography>
                                        <Typography
                                            component='span'
                                            fontSize={{ xs: "11px", sm: "15px" }}
                                            fontWeight='500'
                                            color='white'>
                                            {topPlayersData[0]?.winningRate || ""}
                                        </Typography>
                                    </Box>
                                    <Box
                                        container={true}
                                        display='flex'
                                        justifyContent='space-between'
                                        paddingTop='8px'>
                                        <Typography
                                            color='#818892'
                                            fontSize={{ xs: "11px", sm: "15px" }}
                                            fontWeight='500'>
                                            Winning Rounds
                                        </Typography>
                                        <Typography
                                            component='span'
                                            fontSize={{ xs: "11px", sm: "15px" }}
                                            fontWeight='500'
                                            color='white'>
                                            {topPlayersData[0].winningRounds +
                                                "/" +
                                                topPlayersData[0].roundsInvolved}
                                        </Typography>
                                    </Box>
                                </Box>
                            ) : (
                                <div />
                            )}
                        </Grid>
                        <Grid
                            item
                            width={{ xs: "100%", md: "31%" }}>
                            {topPlayersData[1] ? (
                                <Box
                                    padding={{ xs: "32px", sm: "43px" }}
                                    borderRadius='40px'
                                    backgroundColor='#0C1C2E'
                                    marginTop={{ xs: "30px" }}
                                    border='0.5px solid #343435'>
                                    <Grid
                                        borderBottom='1px solid rgba(255, 255, 255, 0.25)'
                                        container={true}
                                        display='flex'
                                        justifyContent='space-between'
                                        paddingBottom='30px'>
                                        <Grid
                                            item
                                            xs={7}
                                            md={9}>
                                            <Box>
                                                <Typography
                                                    color='#818892'
                                                    fontSize={{ xs: "17px", sm: "24px" }}
                                                    fontWeight='500'>
                                                    Net Payout ($HILO)
                                                </Typography>
                                                <Typography
                                                    color='white'
                                                    fontSize={{ xs: "24px", sm: "32px" }}
                                                    fontWeight='500'>
                                                    {topPlayersData[1]?.netPayoutToken || ""}
                                                </Typography>
                                                <Typography
                                                    component='span'
                                                    fontSize={{ xs: "11px", sm: "15px" }}
                                                    fontWeight='500'
                                                    color='#4166F4'>
                                                    {topPlayersData[1]?.netPayout || ""}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid
                                            display={{ xs: "none", md: "block" }}
                                            item
                                            xs={4}
                                            md={3}>
                                            <img
                                                src={TropyTwo}
                                                alt='Trophy'
                                            />
                                        </Grid>
                                        <Grid
                                            display={{ md: "none" }}
                                            item
                                            xs={4}
                                            md={3}>
                                            <img
                                                src={TropyTwo}
                                                style={{ width: "80%" }}
                                                alt='Trophy'
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box
                                        container={true}
                                        display='flex'
                                        justifyContent='space-between'
                                        paddingTop='22px'>
                                        <Typography
                                            color='#818892'
                                            fontSize={{ xs: "11px", sm: "15px" }}
                                            fontWeight='500'>
                                            Address
                                        </Typography>
                                        <Typography
                                            component='span'
                                            fontSize={{ xs: "11px", sm: "15px" }}
                                            fontWeight='500'
                                            color='#4166F4'>
                                            <a
                                                rel='noreferrer'
                                                target='_blank'
                                                style={{ color: "#4166F4" }}
                                                href={`https://etherscan.io/address/${topPlayersData[1]?.address}#tokentxns`}>
                                                {" "}
                                                {topPlayersData[1]?.convertedAddress || ""}
                                            </a>
                                        </Typography>
                                    </Box>
                                    <Box
                                        container={true}
                                        display='flex'
                                        justifyContent='space-between'
                                        paddingTop='8px'>
                                        <Typography
                                            color='#818892'
                                            fontSize={{ xs: "11px", sm: "15px" }}
                                            fontWeight='500'>
                                            Winning Rate
                                        </Typography>
                                        <Typography
                                            component='span'
                                            fontSize={{ xs: "11px", sm: "15px" }}
                                            fontWeight='500'
                                            color='white'>
                                            {topPlayersData[1]?.winningRate || ""}
                                        </Typography>
                                    </Box>
                                    <Box
                                        container={true}
                                        display='flex'
                                        justifyContent='space-between'
                                        paddingTop='8px'>
                                        <Typography
                                            color='#818892'
                                            fontSize={{ xs: "11px", sm: "15px" }}
                                            fontWeight='500'>
                                            Winning Rounds
                                        </Typography>
                                        <Typography
                                            component='span'
                                            fontSize={{ xs: "11px", sm: "15px" }}
                                            fontWeight='500'
                                            color='white'>
                                            {topPlayersData[1].winningRounds +
                                                "/" +
                                                topPlayersData[1].roundsInvolved}
                                        </Typography>
                                    </Box>
                                </Box>
                            ) : (
                                <div />
                            )}
                        </Grid>
                        <Grid
                            item
                            width={{ xs: "100%", md: "31%" }}>
                            {topPlayersData[2] ? (
                                <Box
                                    padding={{ xs: "32px", sm: "43px" }}
                                    borderRadius='40px'
                                    backgroundColor='#0C1C2E'
                                    marginTop={{ xs: "30px" }}
                                    border='0.5px solid #343435'>
                                    <Grid
                                        borderBottom='1px solid rgba(255, 255, 255, 0.25)'
                                        container={true}
                                        display='flex'
                                        justifyContent='space-between'
                                        paddingBottom='30px'>
                                        <Grid
                                            item
                                            xs={7}
                                            md={9}>
                                            <Box>
                                                <Typography
                                                    color='#818892'
                                                    fontSize={{ xs: "17px", sm: "24px" }}
                                                    fontWeight='500'>
                                                    Net Payout ($HILO)
                                                </Typography>
                                                <Typography
                                                    color='white'
                                                    fontSize={{ xs: "24px", sm: "32px" }}
                                                    fontWeight='500'>
                                                    {topPlayersData[2]?.netPayoutToken || ""}
                                                </Typography>
                                                <Typography
                                                    component='span'
                                                    fontSize={{ xs: "11px", sm: "15px" }}
                                                    fontWeight='500'
                                                    color='#4166F4'>
                                                    {topPlayersData[2]?.netPayout || ""}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid
                                            display={{ xs: "none", md: "block" }}
                                            item
                                            xs={4}
                                            md={3}>
                                            <img
                                                src={TropyThree}
                                                alt='Trophy'
                                            />
                                        </Grid>
                                        <Grid
                                            display={{ md: "none" }}
                                            item
                                            xs={4}
                                            md={3}>
                                            <img
                                                src={TropyThree}
                                                style={{ width: "80%" }}
                                                alt='Trophy'
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box
                                        container={true}
                                        display='flex'
                                        justifyContent='space-between'
                                        paddingTop='22px'>
                                        <Typography
                                            color='#818892'
                                            fontSize={{ xs: "11px", sm: "15px" }}
                                            fontWeight='500'>
                                            Address
                                        </Typography>
                                        <Typography
                                            component='span'
                                            fontSize={{ xs: "11px", sm: "15px" }}
                                            fontWeight='500'
                                            color='#4166F4'>
                                            <a
                                                rel='noreferrer'
                                                target='_blank'
                                                style={{ color: "#4166F4" }}
                                                href={`https://etherscan.io/address/${topPlayersData[2]?.address}#tokentxns`}>
                                                {" "}
                                                {topPlayersData[2]?.convertedAddress || ""}
                                            </a>
                                        </Typography>
                                    </Box>
                                    <Box
                                        container={true}
                                        display='flex'
                                        justifyContent='space-between'
                                        paddingTop='8px'>
                                        <Typography
                                            color='#818892'
                                            fontSize={{ xs: "11px", sm: "15px" }}
                                            fontWeight='500'>
                                            Winning Rate
                                        </Typography>
                                        <Typography
                                            component='span'
                                            fontSize={{ xs: "11px", sm: "15px" }}
                                            fontWeight='500'
                                            color='white'>
                                            {topPlayersData[2]?.winningRate || ""}
                                        </Typography>
                                    </Box>
                                    <Box
                                        container={true}
                                        display='flex'
                                        justifyContent='space-between'
                                        paddingTop='8px'>
                                        <Typography
                                            color='#818892'
                                            fontSize={{ xs: "11px", sm: "15px" }}
                                            fontWeight='500'>
                                            Winning Rounds
                                        </Typography>
                                        <Typography
                                            component='span'
                                            fontSize={{ xs: "11px", sm: "15px" }}
                                            fontWeight='500'
                                            color='white'>
                                            {topPlayersData[2].winningRounds +
                                                "/" +
                                                topPlayersData[2].roundsInvolved}
                                        </Typography>
                                    </Box>
                                </Box>
                            ) : (
                                <div />
                            )}
                        </Grid>
                    </Grid>
                    <Box marginTop='42px'>
                        <TableContainer
                            className={classes.block}
                            component={Paper}>
                            <Table
                                className={classes.body}
                                sx={{ minWidth: 650 }}
                                aria-label='simple table'>
                                <TableHead className={clsx(classes.trow, classes.header)}>
                                    <TableRow>
                                        <TableCell
                                            align='left'
                                            className={classes.tCell}>
                                            Rank
                                        </TableCell>
                                        <TableCell
                                            align='left'
                                            cursor='pointer'
                                            className={classes.tCell}>
                                            WALLET ADDRESS
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            className={classes.tCell}>
                                            <Button
                                                className={classes.tButton}
                                                onClick={() => {
                                                    setSortId("netPayoutToken");
                                                }}>
                                                NET PAYOUT ($HILO)
                                            </Button>
                                        </TableCell>

                                        <TableCell
                                            align='center'
                                            className={classes.tCell}>
                                            <Button
                                                className={classes.tButton}
                                                onClick={() => {
                                                    setSortId("winningRate");
                                                }}>
                                                WINNING RATE
                                            </Button>
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            className={classes.tCell}>
                                            <Button
                                                className={classes.tButton}
                                                onClick={() => {
                                                    setSortId("winningRounds");
                                                }}>
                                                WINNING ROUND
                                            </Button>
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                            className={classes.tCell}>
                                            <Button
                                                className={classes.tButton}
                                                onClick={() => {
                                                    setSortId("roundsInvolved");
                                                }}>
                                                ROUNDS INVOLVED
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className={classes.body}>
                                    {paginatedData.length !== 0
                                        ? paginatedData[currentIndex].map((item, index) => {
                                              return (
                                                  <ComponentTableRow
                                                      data={item}
                                                      index={index + 1}
                                                      key={index}
                                                  />
                                              );
                                          })
                                        : ""}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box
                            className={classes.pagination}
                            component='div'
                            marginTop='42px'
                            display='flex'
                            justifyContent='flex-end'>
                            {" "}
                            {paginatedData?.length > 1 ? (
                                <Pagination
                                    page={currentIndex + 1}
                                    onChange={(e, page) => {
                                        setCurrentIndex(page - 1);
                                    }}
                                    count={paginatedData.length}
                                />
                            ) : (
                                <div />
                            )}
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
};
export default LeaderBoard;
