import React, {useState, useEffect, useMemo} from "react";
import clsx from "clsx";
import {makeStyles} from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import styles from "assets/jss/hiloCardStyles";
import {
    Button,
    IconButton,
    Typography,
    TextField,
    Box,
    Stack,
    LinearProgress,
    Alert,
    Link,
    Grid,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import {
    useContractWrite,
    useContractRead,
    useAccount,
    useNetwork,
    usePrepareContractWrite,
    useWaitForTransaction,
} from "wagmi";
import {BigNumber} from "ethers";
import Web3 from "web3";
import localizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
import {setDecimalsNumericString} from "utils/helpers";
import {getMultiplierV2} from "utils/helpers";
import {LoadingButton} from "@mui/lab";
import Countdown from "react-countdown";

import dextoolsicon from "assets/img/dextoolsicon.svg";
import etherscanicon from "assets/img/etherscanicon.svg";
import coingeckoicon from "assets/img/coingeckoicon.svg";
import mediumicon from "assets/img/mediumicon.svg";
import telegramicon from "assets/img/telegramicon.svg";
import ticon from "assets/img/ticon.png";
import coinmarketcapicon from "assets/img/coinmarketcapicon.svg";
import bubblemapsicon from "assets/img/bubblemapsicon.svg";
import predictionMarketAbi from "../../contracts/predictionMarketContract.json";
import hiloTokenAbi from "../../contracts/hiloTokenContractAbi.json";
import arbCashierAbi from "../../contracts/cashierArb.json";

dayjs.extend( localizedFormat );

const useStyles = makeStyles( styles );
const web3 = new Web3();

const HiloQuestionCard = ( {
    dbRoundData,
    dataFromSmartContract,
    roundBettingStatus,
    epoch,
    userBalance,
    approvedAmount,
    fetchApprovedAmount,
    refetchRoundData,
} ) => {
    const classes = useStyles();

    const [isRoundInfoVisibile, setIsRoundInfoVisibile] = useState( false );
    const [userRoundData, setUserRoundData] = useState( {} );
    const [betAmount, setBetAmount] = useState( web3.utils.toWei( "500" ) );
    const [approveErrorMessage, setApproveErrorMessage] = useState( false );

    useEffect( () => {
        if ( BigNumber.from( betAmount || "0" ).gt( approvedAmount || "0" ) ) {
            setApproveErrorMessage( true );
        } else {
            setApproveErrorMessage( false );
        }
    }, [betAmount, approvedAmount] );

    //current user account data
    const {address: currentUserAddress} = useAccount();
    const {chain} = useNetwork();

    const {data: minBetAmount} = useContractRead( {
        address: process.env.REACT_APP_PRIDICTION_CONTRACT,
        abi: predictionMarketAbi,
        functionName: "minBetAmount",
        enabled: roundBettingStatus === "OPEN",
        select: ( data ) => data.toString(),
        onSuccess: ( data ) => setBetAmount( data ),
    } );

    const minBetCheck = useMemo( () => {
        if ( !minBetAmount || !betAmount ) return false;

        return (
            BigNumber.from( minBetAmount ).gt( betAmount ) &&
            !BigNumber.from( minBetAmount ).isZero() &&
            !BigNumber.from( betAmount ).isZero()
        );
    }, [minBetAmount, betAmount] );

    const {refetch: refetchAddressRoundData} = useContractRead( {
        address: process.env.REACT_APP_PRIDICTION_CONTRACT,
        abi: predictionMarketAbi,
        functionName: "ledger",
        args: [epoch, currentUserAddress],
        onSuccess: ( data ) => setUserRoundData( data ),
    } );

    useEffect( () => {
        refetchAddressRoundData();
    }, [currentUserAddress, refetchAddressRoundData] );

    const userBet = useMemo( () => {
        if ( !userRoundData.amount ) return false;

        if ( userRoundData.amount.isZero() ) {
            return false;
        } else if ( userRoundData.position ) {
            return "DOWN";
        } else {
            return "UP";
        }
    }, [userRoundData] );

    const {config: bullBetConfig} = usePrepareContractWrite( {
        address: process.env.REACT_APP_PRIDICTION_CONTRACT,
        abi: predictionMarketAbi,
        functionName: "betBull",
        args: [epoch, BigNumber.from( betAmount || "0" )],
    } );
    // config for sending a bull bet to betting round with epoch #
    const {writeAsync: sendBullBet} = useContractWrite( bullBetConfig );

    const startBullBet = async () => {
        try {
            if ( !betAmount || betAmount <= 0 ) {
                throw new Error( "you must choose an amount to bet" );
            }

            let tx = await sendBullBet();
            await tx.wait();
            await refreshCardData();
            setBetAmount( "0" );
        } catch ( e ) {
            console.log( e );
        }
    };

    const {config: bearBetConfig} = usePrepareContractWrite( {
        address: process.env.REACT_APP_PRIDICTION_CONTRACT,
        abi: predictionMarketAbi,
        functionName: "betBear",
        args: [epoch, BigNumber.from( betAmount || "0" )],
    } );

    // config for sending a bear bet to betting round with epoch #
    const {writeAsync: sendBetBear} = useContractWrite( bearBetConfig );

    const startBearBet = async () => {
        try {
            if ( !betAmount || betAmount <= 0 ) {
                throw new Error( "you must choose an amount to bet" );
            }
            const tx = await sendBetBear();
            await tx.wait();
            await refreshCardData();
            setBetAmount( "0" );
        } catch ( e ) {
            console.log( e );
        }
    };

    const {config: approveErc20Config} = usePrepareContractWrite( {
        address: process.env.REACT_APP_ARB_CASHIER,
        abi: arbCashierAbi,
        functionName: "approve",
        args: [
            process.env.REACT_APP_PRIDICTION_CONTRACT,
            "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff",
        ],
    } );

    const {data: erc20ApprovaleTx, writeAsync: approveContractForBets} =
        useContractWrite( approveErc20Config );

    const approveErc20 = async () => {
        const result = await approveContractForBets();
        await result.wait();
        await fetchApprovedAmount();
    };

    const {isLoading} = useWaitForTransaction( {
        hash: erc20ApprovaleTx?.hash,
    } );

    const refreshCardData = async () => {
        await refetchRoundData();
        await refetchAddressRoundData();
    };

    const enterBet = ( e ) => {
        if ( /\./.test( e.target.value ) ) return;
        if ( /[0-9]+\./.test( e.target.value ) ) return;
        if ( e.target.value === "" ) {
            setBetAmount( web3.utils.toWei( "0" ) );
            return;
        }
        setBetAmount( web3.utils.toWei( e.target.value ) );
    };

    const setMaxBet = () => {
        setBetAmount( userBalance );
    };

    const handleShowRoundInfo = () => {
        setIsRoundInfoVisibile( ( isRoundInfoVisibile ) => !isRoundInfoVisibile );
    };

    const roundOutcome = useMemo( () => {
        if ( !dataFromSmartContract.roundClosed ) return "Awaiting final result...";

        if ( dataFromSmartContract.outcome === 0 ) {
            return dbRoundData.choiceOne;
        } else if ( dataFromSmartContract.outcome === 1 ) {
            return dbRoundData.choiceTwo;
        } else {
            return "DRAW";
        }
    }, [
        dataFromSmartContract.outcome,
        dataFromSmartContract.roundClosed,
        dbRoundData.choiceOne,
        dbRoundData.choiceTwo,
    ] );

    const bullMultiplier = getMultiplierV2(
        dataFromSmartContract?.totalAmount,
        dataFromSmartContract?.bullAmount
    );
    const bearMultiplier = getMultiplierV2(
        dataFromSmartContract?.totalAmount,
        dataFromSmartContract?.bearAmount
    );
    const formattedBullMultiplier = bullMultiplier
        .toUnsafeFloat()
        .toFixed( bullMultiplier.isZero() ? 0 : 2 );
    const formattedBearMultiplier = bearMultiplier
        .toUnsafeFloat()
        .toFixed( bearMultiplier.isZero() ? 0 : 2 );

    const bullMultiplierWarning = getMultiplierV2(
        dataFromSmartContract?.totalAmount.add( betAmount ),
        dataFromSmartContract?.bullAmount.add( betAmount )
    );
    const bearMultiplierWarning = getMultiplierV2(
        dataFromSmartContract?.totalAmount.add( betAmount ),
        dataFromSmartContract?.bearAmount.add( betAmount )
    );
    const formattedBullMultiplierWarning = bullMultiplierWarning
        .toUnsafeFloat()
        .toFixed( bullMultiplier.isZero() ? 0 : 2 );
    const formattedBearMultiplierWarning = bearMultiplierWarning
        .toUnsafeFloat()
        .toFixed( bearMultiplier.isZero() ? 0 : 2 );

    const showWarning = useMemo( () => {
        if ( !betAmount || !dataFromSmartContract?.totalAmount ) return false;
        const totalAmount = Number(
            web3.utils.fromWei( dataFromSmartContract?.totalAmount.toString() )
        );
        const bet = Number( web3.utils.fromWei( betAmount ) );

        return bet / totalAmount > 0.2;
    }, [betAmount, dataFromSmartContract?.totalAmount] );

    return (
        <Stack
            direction={{xs: "column", sm: "column", md: "row"}}
            justifyContent='center'
            alignItems='center'
            spacing={2}>
            <Box className={classes.blockContainer}>
                <Box className={classes.header}>
                    <Box className={classes.status}>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='21'
                            height='21'
                            fill='white'
                            className='bi bi-play-circle'
                            viewBox='0 0 16 16'>
                            <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' />
                            <path d='M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z' />
                        </svg>
                        <Typography variant='body1'>{roundBettingStatus}</Typography>
                    </Box>
                    <Box className={classes.time}>
                        <Box>
                            <Typography className={classes.timeStampTitle}>
                                Pool locks in
                            </Typography>
                            <Countdown
                                className={classes.countdown}
                                key={dayjs
                                    .unix( dataFromSmartContract?.lockTimestamp )
                                    .toDate()
                                    .toString()}
                                date={dayjs.unix( dataFromSmartContract?.lockTimestamp ).toDate()}
                            />
                        </Box>
                        &nbsp; <Box className={classes.divider} /> &nbsp;
                        <Box>
                            <Typography className={classes.timeStampTitle}>
                                Pool settles in
                            </Typography>
                            <Countdown
                                className={classes.countdown}
                                key={dayjs
                                    .unix( dataFromSmartContract?.closeTimestamp )
                                    .toDate()
                                    .toString()}
                                date={dayjs.unix( dataFromSmartContract?.closeTimestamp ).toDate()}
                            />
                        </Box>
                    </Box>

                    {/* <Box>
                        <IconButton
                            className={classes.infoIconButton}
                            onClick={handleShowRoundInfo}>
                            <InfoIcon />
                        </IconButton>
                    </Box> */}
                </Box>
                <Box className={classes.progressBar}>
                    <LinearProgress
                        variant='determinate'
                        value={parseInt( dataFromSmartContract.progressbar )}
                    />
                </Box>
                {roundBettingStatus !== "LATER" && (
                    <Box className={classes.mainBox}>
                        <Box className={classes.cardHeader}>
                            <Stack
                                direction='row'
                                justifyContent='start'
                                alignItems='center'
                                padding={2}
                                gap={2}>
                                <img style={{borderRadius: "8px"}} height={75} src={dbRoundData.questionImageUrl} />
                                <Typography variant='body1'>{dbRoundData.questionName}</Typography>
                            </Stack>
                        </Box>
                        <Stack
                            direction='column'
                            justifyContent='space-evenly'
                            className={classes.priceBox}
                            style={{backgroundColor: "#031225"}}>
                            {isRoundInfoVisibile && (
                                <Box className={classes.roundInfo}>
                                    <Typography>{`Option 1: ${ dbRoundData.choiceOne }`}</Typography>
                                    <Typography>{`Option 2: ${ dbRoundData.choiceTwo }`}</Typography>
                                    <Typography>
                                        Anything in between will be considered a draw. All tokens
                                        will be burned.
                                    </Typography>
                                    <Typography>{`Betting ends: ${ dayjs(
                                        dayjs.unix( dataFromSmartContract.lockTimestamp )
                                    ).format() }`}</Typography>
                                    <Typography>{`Bets settle: ${ dayjs(
                                        dayjs.unix( dataFromSmartContract.closeTimestamp )
                                    ).format() }`}</Typography>
                                    <br />
                                    <Typography>Round status:</Typography>
                                    <Typography>
                                        OPEN: Payouts can change and you can place 1 bet from the
                                        same wallet.
                                    </Typography>
                                    <Typography>
                                        LOCKED: Payouts are locked and you cannot place new bets.
                                    </Typography>
                                </Box>
                            )}
                            {/* {roundBettingStatus === "LOCKED" && !isRoundInfoVisibile && (
                <Box className={classes.topBox}>
                  <Box>
                    <Typography variant="body1">Last Price</Typography>
                    {dataFromSmartContract.closeTimestamp -
                      Math.round(new Date().getTime() / 1000) >
                    0 ? (
                      <Typography variant="h1">${oraclePrice}</Typography>
                    ) : (
                      <Typography variant="h1">
                        $
                        {dataFromSmartContract.closePrice
                          .div("100000000")
                          .toNumber()
                          .toFixed(2)}
                      </Typography>
                    )}
                  </Box>
                </Box>
              )} */}
                            {!isRoundInfoVisibile && (
                                <Box>
                                    {roundBettingStatus === "OPEN" &&
                                        approveErrorMessage &&
                                        currentUserAddress &&
                                        !userBet && (
                                            <Alert severity='warning'>
                                                Please reapprove more tokens
                                            </Alert>
                                        )}
                                    {roundBettingStatus === "OPEN" &&
                                        minBetCheck &&
                                        currentUserAddress && (
                                            <Alert severity='warning'>
                                                {`Minimum bet amount is ${ web3.utils.fromWei(
                                                    minBetAmount
                                                ) } Dapp Hilo`}
                                            </Alert>
                                        )}
                                    {roundBettingStatus === "OPEN" && !currentUserAddress && (
                                        <Alert severity='warning'>
                                            {`Connect your wallet to begin betting`}
                                        </Alert>
                                    )}
                                    <Box className={classes.topBox}>
                                        <Stack direction='column'>
                                            {roundBettingStatus !== "DONE" && (
                                                <>
                                                    {!userBet && roundBettingStatus !== "LOCKED" ? (
                                                        <Stack
                                                            direction='row'
                                                            justifyContent='space-between'
                                                            alignItems='center'>
                                                            <Typography variant='body1'>
                                                                Commit:
                                                            </Typography>
                                                            <TextField
                                                                value={
                                                                    web3.utils
                                                                        .fromWei( betAmount )
                                                                        .split( "." )[0]
                                                                }
                                                                onChange={enterBet}
                                                                variant='filled'
                                                                type='number'
                                                                sx={{
                                                                    input: {
                                                                        color: "white",
                                                                        fontSize: "23px",
                                                                    },
                                                                }}
                                                                className={classes.betInput}
                                                                placeholder='0'
                                                                InputProps={{
                                                                    min: 1,
                                                                }}
                                                            />
                                                        </Stack>
                                                    ) : (
                                                        <>
                                                            {roundBettingStatus !== "LOCKED" && (
                                                                <Stack
                                                                    direction='row'
                                                                    justifyContent='center'
                                                                    alignItems='center'>
                                                                    <Typography
                                                                        className={
                                                                            classes.betPlaced
                                                                        }
                                                                        variant='body1'>
                                                                        Bet placed...
                                                                    </Typography>
                                                                </Stack>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            {roundBettingStatus === "LOCKED" && (
                                                <Stack
                                                    direction='row'
                                                    justifyContent='center'
                                                    alignItems='center'>
                                                    <Typography
                                                        className={classes.betPlaced}
                                                        variant='body1'>
                                                        POOL LOCKED
                                                    </Typography>
                                                </Stack>
                                            )}
                                            {roundBettingStatus === "DONE" && (
                                                <Stack
                                                    direction='row'
                                                    justifyContent='center'
                                                    alignItems='center'>
                                                    <Typography
                                                        className={classes.betPlaced}
                                                        variant='body1'>
                                                        {`Result:  ${ roundOutcome }`}
                                                    </Typography>
                                                </Stack>
                                            )}
                                            {roundBettingStatus === "OPEN" &&
                                                userBalance &&
                                                !userBet && (
                                                    <Box>
                                                        <Stack
                                                            direction='row'
                                                            justifyContent='flex-end'
                                                            alignItems='center'>
                                                            <Typography
                                                                className={classes.maxBetBalance}
                                                                variant='body1'>{`Balance: ${ web3.utils
                                                                    .fromWei( userBalance )
                                                                    .split( "." )[0]
                                                                    } $HILO`}</Typography>
                                                            <Button onClick={setMaxBet}>Max</Button>
                                                        </Stack>
                                                    </Box>
                                                )}
                                        </Stack>
                                    </Box>
                                </Box>
                            )}
                            {!isRoundInfoVisibile && (
                                <Box>
                                    <Box className={classes.prizeBox}>
                                        <Typography variant='body1'>Prize Pool</Typography>
                                        <Typography variant='h3'>
                                            {setDecimalsNumericString(
                                                web3.utils.fromWei(
                                                    dataFromSmartContract.totalAmount.toString()
                                                )
                                            )}
                                            <span style={{marginLeft: "5px"}}>$HILO</span>
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                        </Stack>
                        {approveErrorMessage &&
                            !isRoundInfoVisibile &&
                            roundBettingStatus === "OPEN" &&
                            !userBet && (
                                <Box
                                    className={classes.payoutBox}
                                    justifyContent='center'>
                                    <LoadingButton
                                        className={classes.approvalButton}
                                        onClick={approveErc20}
                                        disabled={!currentUserAddress}
                                        loading={isLoading}>
                                        <Typography variant='body1'>APPROVE</Typography>
                                    </LoadingButton>
                                </Box>
                            )}
                        {roundBettingStatus === "DONE" && (
                            <Box className={classes.payoutBox}>
                                <Button
                                    className={clsx(
                                        classes.payoutHigherButton,
                                        classes.buttonBetSide
                                    )}
                                    disabled={
                                        ( dataFromSmartContract.roundClosed &&
                                            dataFromSmartContract.outcome === 1 ) ||
                                        dataFromSmartContract.outcome === 2 ||
                                        ( !dataFromSmartContract.roundClosed && userBet === "DOWN" )
                                    }>
                                    <Typography variant='body1'>
                                        {" "}
                                        {dbRoundData.choiceOne}
                                    </Typography>
                                    <Typography variant='h3'>
                                        {`Payout x ${ formattedBullMultiplier }`}
                                    </Typography>
                                </Button>

                                <Button
                                    className={clsx(
                                        classes.payoutLowerButton,
                                        classes.buttonBetSide
                                    )}
                                    disabled={
                                        ( dataFromSmartContract.roundClosed &&
                                            dataFromSmartContract.outcome === 0 ) ||
                                        dataFromSmartContract.outcome === 2 ||
                                        ( !dataFromSmartContract.roundClosed && userBet === "UP" )
                                    }>
                                    <Typography variant='body1'>
                                        {" "}
                                        {dbRoundData.choiceTwo}
                                    </Typography>
                                    <Typography variant='h3'>
                                        {`Payout x ${ formattedBearMultiplier }`}
                                    </Typography>
                                </Button>
                            </Box>
                        )}
                        {roundBettingStatus === "LOCKED" && (
                            <Box className={classes.payoutBox}>
                                <Button
                                    className={clsx(
                                        classes.payoutHigherButton,
                                        userBet === "UP" ? classes.buttonBetSide : null
                                    )}
                                    disabled={userBet === "DOWN"}>
                                    <Typography variant='body1'>{dbRoundData.choiceOne}</Typography>
                                    <Typography variant='h3'>
                                        {`Payout x ${ formattedBullMultiplier }`}
                                    </Typography>
                                </Button>
                                <Button
                                    className={clsx(
                                        classes.payoutLowerButton,
                                        userBet === "DOWN" ? classes.buttonBetSide : null
                                    )}
                                    disabled={userBet === "UP"}>
                                    <Typography variant='body1'>{dbRoundData.choiceTwo}</Typography>
                                    <Typography variant='h3'>
                                        {`Payout x ${ formattedBearMultiplier }`}
                                    </Typography>
                                </Button>
                            </Box>
                        )}

                        {roundBettingStatus === "OPEN" && ( !approveErrorMessage || userBet ) && (
                            <Tooltip
                                open={showWarning}
                                classes={{
                                    tooltip: classes.tooltip,
                                    arrow: classes.tooltipArrow,
                                }}
                                title={`Warning: You are betting more than 20% of the current total pool amount,
                  a "BULLISH" bet will reduce your payout to ${ formattedBullMultiplierWarning }x, a "BEARISH" bet will reduce your payout to ${ formattedBearMultiplierWarning }x
                  `}
                                arrow
                                placement='bottom'>
                                <Box className={classes.payoutBox}>
                                    <Button
                                        className={clsx(
                                            classes.payoutHigherButton,
                                            userBet === "UP" ? classes.buttonBetSide : null
                                        )}
                                        disabled={
                                            minBetCheck || !currentUserAddress || userBet === "DOWN"
                                        }
                                        onClick={startBullBet}>
                                        <Typography variant='body1'>
                                            {dbRoundData.choiceOne}
                                        </Typography>
                                        <Typography variant='h3'>
                                            {`Payout x ${ formattedBullMultiplier }`}
                                        </Typography>
                                    </Button>
                                    <Button
                                        className={clsx(
                                            classes.payoutLowerButton,
                                            userBet === "DOWN" ? classes.buttonBetSide : null
                                        )}
                                        disabled={
                                            minBetCheck || !currentUserAddress || userBet === "UP"
                                        }
                                        onClick={startBearBet}>
                                        <Typography variant='body1'>
                                            {dbRoundData.choiceTwo}
                                        </Typography>
                                        <Typography variant='h3'>
                                            {`Payout x ${ formattedBearMultiplier }`}
                                        </Typography>
                                    </Button>
                                </Box>
                            </Tooltip>
                        )}
                    </Box>
                )}
            </Box>
            {dbRoundData?.questionDescription && (
                <Stack
                    direction='column'
                    justifyContent='center'
                    alignItems='flex-start'
                    spacing={4}
                    className={classes.descriptionLinksContainer}>
                    <Box>
                        <Typography className={classes.tokenDescriptionHeader}>
                            Question Description
                        </Typography>
                        <Typography className={classes.tokenDescription}>
                            {dbRoundData.questionDescription}
                        </Typography>
                    </Box>
                </Stack>
            )}
        </Stack>
    );
};

export default HiloQuestionCard;
