import {useState} from "react";
import {Dialog, DialogContent, DialogTitle, Box, Tab} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import styles from "assets/jss/adminCreateRound";
import {makeStyles} from "@mui/styles";
import EditQuestionRoundForm from "./EditQuestionRoundForm";

const useStyles = makeStyles( styles );

function EditQuestionRound ( {
    fetchQuestionRounds,
    showEditQuestionRoundForm,
    setShowEditQuestionRoundForm,
    defaultQuestionValues,
    setQuestionRoundToEdit,
} ) {
    const classes = useStyles();

    console.log( "edit round from EditRound", defaultQuestionValues );



    const [isSubmitting, setIsSubmitting] = useState( false );



    const handleClose = () => {
        if ( isSubmitting ) return;
        setShowEditQuestionRoundForm( false );
        setQuestionRoundToEdit( {} );
    };

    return (
        <Box className={classes.block}>
            <Dialog open={showEditQuestionRoundForm} onClose={handleClose}>

                <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                </Box>
                <DialogContent>
                    <EditQuestionRoundForm
                        fetchRounds={fetchQuestionRounds}
                        handleClose={handleClose}
                        setIsSubmitting={setIsSubmitting}
                        defaultValues={defaultQuestionValues}
                    />
                </DialogContent>
            </Dialog>
        </Box>
    );
}

export default EditQuestionRound;
