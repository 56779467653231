import Countdown from "react-countdown";
import axios from "axios";
import Web3 from "web3";
import dayjs from "dayjs";
import { useEffect } from "react";
import { Stack, Box, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { postCreateRound } from "api/rounds";
import { LoadingButton } from "@mui/lab";
import { debounce } from "lodash";
import { startNewRoundSchema } from "./schemas";
import CategoryInput from "components/CategoryInput/CategoryInput";

const web3 = new Web3();

const defaultFormValues = {
  tokenName: "",
  tokenSymbol: "",
  tokenImageUrl: "",
  tokenDescription: "",
  tokenAddress: "",
  pairAddress: "",
  lockTimestamp: dayjs(),
  closeTimestamp: dayjs(),
  startPrice: "",
  upOption: "HIGHER",
  downOption: "LOWER",
  coingeckoUrl: "",
  telegramUrl: "",
  twitterUrl: "",
  mediumUrl: "",
  coinMarketCapUrl: "",
  bubbleMapsUrl: "",
  roundsRemainingAtCreate: 0,
  category: [],
};

//todo bottom two functions to be updated when adding new chain
/**
 * Takes a string that is a large number, with many decimals, cuts it to two decimal places
 * @param {string} pairAddress address of pair we want the values of
 * @param {string} chain dexcreener chain id for pair we are looking up
 */
const getPairAddressDetails = async (
  pairAddress,
  setValue,
  chain = "ethereum"
) => {
  try {
    const url = `https://api.dexscreener.com/latest/dex/pairs/${chain}/${pairAddress}`;
    const { data } = await axios.get(url);
    if (data.pair) {
      const { baseToken, priceUsd } = data.pair;

      setValue("tokenName", baseToken.name, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      setValue("tokenSymbol", baseToken.symbol, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      setValue("tokenAddress", baseToken.address, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      setValue("startPrice", priceUsd, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  } catch (e) {
    console.log(e);
  }
};

const debouncedGetPairAddressDetails = debounce(getPairAddressDetails, 750);

function CreateRoundForm({ fetchRounds, handleClose, setIsSubmitting }) {
  const {
    handleSubmit,
    watch,
    formState: { errors, isSubmitting, isValid, isDirty },
    control,
    reset,
    setValue,
  } = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(startNewRoundSchema),
    mode: "all",
  });

  const onSubmit = async (data) => {
    try {
      await postCreateRound(data);
      fetchRounds();
      handleClose();
      reset();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setIsSubmitting(isSubmitting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  const pairAddress = watch("pairAddress");

  useEffect(() => {
    if (web3.utils.isAddress(pairAddress)) {
      debouncedGetPairAddressDetails(pairAddress, setValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pairAddress]);

  return (
    <Box sx={{ mt: 2 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.pairAddress?.message)}
                helperText={errors.pairAddress?.message}
                fullWidth
                label="Pair address"
              />
            )}
            name="pairAddress"
            label="Pair address"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                fullWidth
                error={Boolean(errors.roundsRemainingAtCreate?.message)}
                helperText={errors.roundsRemainingAtCreate?.message}
                label="Additional rounds"
              />
            )}
            name="roundsRemainingAtCreate"
            control={control}
          />
          <Controller
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  error={Boolean(errors.tokenName?.message)}
                  helperText={errors.tokenName?.message}
                  fullWidth
                  label="Token name"
                />
              );
            }}
            name="tokenName"
            label="Token name"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.tokenSymbol?.message)}
                helperText={errors.tokenSymbol?.message}
                fullWidth
                label="Token symbol"
              />
            )}
            name="tokenSymbol"
            label="Token symbol"
            control={control}
          />
          {watch("tokenImageUrl") && (
            <Box
              component="img"
              style={{ width: 25, height: 25 }}
              src={`${watch("tokenImageUrl")}`}
              alt="Token"
            />
          )}
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.tokenImageUrl?.message)}
                helperText={errors.tokenImageUrl?.message}
                fullWidth
                label="Token image url"
              />
            )}
            name="tokenImageUrl"
            label="Token image url"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                multiline
                rows={4}
                error={Boolean(errors.tokenDescription?.message)}
                helperText={errors.tokenDescription?.message}
                fullWidth
                label="Token description"
              />
            )}
            name="tokenDescription"
            label="Token description"
            control={control}
          />
          <CategoryInput
            control={control}
            errors={errors}
            fieldName="category"
            label="Categories"
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.tokenAddress?.message)}
                helperText={errors.tokenAddress?.message}
                fullWidth
                label="Token address"
              />
            )}
            name="tokenAddress"
            label="Token address"
            control={control}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {dayjs(watch("lockTimestamp")).isValid() && (
              <Countdown date={dayjs(watch("lockTimestamp")).toDate()} />
            )}
            <Controller
              name="lockTimestamp"
              control={control}
              render={({ field: { ref, ...rest } }) => (
                <DateTimePicker
                  disablePast
                  renderInput={({ error: inputError, ...params }) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={Boolean(errors.lockTimestamp?.message)}
                      helperText={errors.lockTimestamp?.message}
                    />
                  )}
                  label="Lock betting"
                  {...rest}
                />
              )}
            />
            {dayjs(watch("closeTimestamp")).isValid() && (
              <Countdown date={dayjs(watch("closeTimestamp")).toDate()} />
            )}
            <Controller
              name="closeTimestamp"
              control={control}
              render={({ field: { ref, ...rest } }) => (
                <DateTimePicker
                  disablePast
                  renderInput={({ error: inputError, ...params }) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={Boolean(errors.closeTimestamp?.message)}
                      helperText={errors.closeTimestamp?.message}
                    />
                  )}
                  label="Close round"
                  {...rest}
                />
              )}
            />
          </LocalizationProvider>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                fullWidth
                error={Boolean(errors.startPrice?.message)}
                helperText={errors.startPrice?.message}
                label="Start price"
              />
            )}
            name="startPrice"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.upOption?.message)}
                helperText={errors.upOption?.message}
                fullWidth
                label="Up option"
              />
            )}
            name="upOption"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.downOption?.message)}
                helperText={errors.downOption?.message}
                fullWidth
                label="Down option"
              />
            )}
            name="downOption"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.coingeckoUrl?.message)}
                helperText={errors.coingeckoUrl?.message}
                fullWidth
                label="Coingecko url"
              />
            )}
            name="coingeckoUrl"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.telegramUrl?.message)}
                helperText={errors.telegramUrl?.message}
                fullWidth
                label="Telegram url"
              />
            )}
            name="telegramUrl"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.twitterUrl?.message)}
                helperText={errors.twitterUrl?.message}
                fullWidth
                label="Twitter url"
              />
            )}
            name="twitterUrl"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.mediumUrl?.message)}
                helperText={errors.mediumUrl?.message}
                fullWidth
                label="Medium url"
              />
            )}
            name="mediumUrl"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.coinMarketCapUrl?.message)}
                helperText={errors.coinMarketCapUrl?.message}
                fullWidth
                label="Coin market cap url"
              />
            )}
            name="coinMarketCapUrl"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.bubbleMapsUrl?.message)}
                helperText={errors.bubbleMapsUrl?.message}
                fullWidth
                label="Bubble maps url"
              />
            )}
            name="bubbleMapsUrl"
            control={control}
          />
          <LoadingButton
            loading={isSubmitting}
            variant="contained"
            type="submit"
            disabled={!isDirty || !isValid}
          >
            Submit
          </LoadingButton>
        </Stack>
      </form>
    </Box>
  );
}

export default CreateRoundForm;
