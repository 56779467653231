const navbarStyles = (theme) => ({
    navbar: {
        display: "grid",
    },
    drawerStack: {
        [theme.breakpoints.up("md")]: {
            display: "none !important",
        },
    },
    mobileButton: {
        justifySelf: "end",
        margin: "8px",
        [theme.breakpoints.up("md")]: {
            display: "none !important",
            "& .MuiButtonBase-root": {
                display: "none !important",
            },
        },
    },
    navbarInner: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: "3rem",
        paddingRight: "3rem",
        paddingTop: "70px",
        "& h3": {
            fontSize: "38px",
            lineHeight: "1",
        },
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            gap: "0rem",
            paddingTop: "1rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            "& h3": {
                fontSize: "24px",
            },
        },
    },
    navLinks: {
        display: "flex",
        gap: "2rem",
        justifyContent: "center",
        alignItems: "center",
        background: "#1A192E",
        borderTopLeftRadius: "100px",
        borderBottomLeftRadius: "100px",
        borderTopRightRadius: "100px",
        borderBottomRightRadius: "100px",
        margin: "0 auto",
        padding: "0.50rem",
        "& .MuiLink-root": {
            color: "#fff",
            fontSize: "20px",
            lineHeight: "27px",
            marginLeft: "46px",
            fontWeight: "500",
            "&:hover": {
                position: "relative",
                "&::before": {
                    position: "absolute",
                    width: "40px",
                    content: `''`,
                    height: "2px",
                    backgroundColor: "#E6C39F",
                    right: "0",
                    bottom: "-5px",
                },
            },
        },
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    linkItem: {
        color: "#fff",
        fontSize: "20px",
        lineHeight: "27px",
        fontWeight: "500",
        textDecoration: "none",
        padding: "1rem",
        [theme.breakpoints.down("md")]: {
            color: "#fff",
            fontSize: "16px",
            padding: "1rem",
        },
    },
    linkItemActive: {
        color: "#fff",
        fontSize: "20px",
        lineHeight: "27px",
        fontWeight: "500",
        textDecoration: "none",
        background: "#4166F4",
        borderTopLeftRadius: "100px",
        borderBottomLeftRadius: "100px",
        borderTopRightRadius: "100px",
        borderBottomRightRadius: "100px",
        padding: "1rem",
        [theme.breakpoints.down("md")]: {
            color: "#fff",
            fontSize: "16px",
            padding: "1rem",
        },
    },
    activeLink: {
        "&.MuiLink-root": {
            position: "relative",
        },
    },
    sidebar: {
        width: "300px",
        boxSizing: "border-box",
        padding: "30px 16px",
        "& .MuiLink-root": {
            display: "block",
            marginBottom: "24px",
        },
    },
    connectWalletBtn: {
        "&.MuiButton-root": {
            borderRadius: "100px",
            width: "132px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "3.688rem",
            color: "white",
            backgroundColor: "#4166F4",
            fontSize: "20px",
            fontWeight: "500",
            lineHeight: "23.25px",
            marginLeft: "46px",
            [theme.breakpoints.down("md")]: {
                marginLeft: "20px",
                display: "none",
            },
        },
        "&:hover": {
            opacity: "0.8",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "3.688rem",
            color: "black",
            backgroundColor: "white !important",
            fontSize: "20px",
            lineHeight: "23.25px",
        },
    },
    connectWalletBtnXsScreen: {
        "&.MuiButton-root": {
            [theme.breakpoints.up("sm")]: {
                display: "none",
            },
            [theme.breakpoints.down("xs")]: {
                color: "#fff",
                fontSize: "16px",
                lineHeight: "27px",
                marginBottom: "24px",
                fontWeight: "500",
                width: "132px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "42.6px",
                backgroundColor: "white",
                padding: "0px !important",
            },
        },
    },
    mobileNav: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        "& a": {
            marginBottom: "24px",
            textDecoration: "none",
            color: "#1976d2",
        },
    },
});

export default navbarStyles;
