import { Box, Link, Stack, Typography } from "@mui/material";
import { useNetwork, useBalance } from "wagmi";
import { setDecimalsNumericString } from "utils/helpers";

const ADDRESSES = {
    [Number(process.env.REACT_APP_ETH_CHAIN_ID)]: {
        ADMIN: process.env.REACT_APP_ADMIN_ADDRESS,
    },
    [Number(process.env.REACT_APP_ARB_CHAIN_ID)]: {
        ADMIN: process.env.REACT_APP_ADMIN_ADDRESS,
    },
};

const URLS = {
    [Number(process.env.REACT_APP_ETH_CHAIN_ID)]: "https://etherscan.io/address/",
    [Number(process.env.REACT_APP_ARB_CHAIN_ID)]: "https://goerli.etherscan.io/address/",
};

function AdminBalances() {
    const { chain } = useNetwork();

    if (!chain?.id) return null;

    return (
        <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent='center'
            alignItems='center'
            spacing={2}>
            {Object.entries(ADDRESSES[chain.id]).map(([walletName, address]) => (
                <Balance
                    key={`${walletName}`}
                    walletName={walletName}
                    address={address}
                    url={URLS[chain.id]}
                />
            ))}
        </Stack>
    );
}

function Balance({ walletName, address, url }) {
    const { data } = useBalance({ address });

    if (!walletName || !address || !data?.formatted) return null;

    return (
        <Box>
            <Link
                rel='noreferrer'
                target='_blank'
                href={`${url}${address}`}
                underline='none'>
                <Typography color='white'>
                    {walletName}: {setDecimalsNumericString(data.formatted, 4)} ETH
                </Typography>
            </Link>
        </Box>
    );
}

export default AdminBalances;
