import React, { useEffect, useRef } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import Layout from "components/layout";
import Landing from "page/landing";
import Positions from "page/positions";
import QuestionPositions from "page/QuestionPositions"
import Stats from "page/stats";
import Dashboard from "page/dashboard";
import Leaderboard from "page/leaderboard";
import { createClient, configureChains, WagmiConfig } from "wagmi";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { InjectedConnector } from "wagmi/connectors/injected";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { mainnet, hardhat, goerli, arbitrum, arbitrumGoerli } from "@wagmi/core/chains";
import { ganache1, ganache2 } from "./customeChain";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { useAccount } from "wagmi";
import Admin from "page/admin/Admin";
import HiloCardPage from "page/hilocardpage/HiloCardPage";
import { ADMIN_ADDRESS, ENV } from "configs";
import Background from "components/layout/background";
import { Buffer } from "buffer";
import CashierPage from "./page/cashier/cashier";
import Questions from "./page/questions/questions";
import HiloQuestionCardPage from "./page/hiloQuestionCardPage/HiloQuestionCardPage";

window.Buffer = window.Buffer || Buffer;
console.log("eth chain id", process.env.REACT_APP_ETH_CHAIN_ID);
const { provider, webSocketProvider, chains } = configureChains(
    [
        ...(ENV === "development"
            ? [goerli, arbitrumGoerli]
            : ENV === "local"
            ? [ganache1, ganache2]
            : [mainnet, arbitrum]),
    ],
    // [infuraProvider({ apiKey: PROVIDER_URL }), publicProvider()] // ,
    [
        jsonRpcProvider({
            rpc: (chain) => {
                if (chain.id === Number(process.env.REACT_APP_ETH_CHAIN_ID)) {
                    return {
                        http: process.env.REACT_APP_ETH_PROVIDER_URL,
                        webSocket: process.env.REACT_APP_ETH_PROVIDER_WS,
                    };
                } else if (chain.id === Number(process.env.REACT_APP_ARB_CHAIN_ID)) {
                    return {
                        http: process.env.REACT_APP_ARB_PROVIDER_URL,
                        webSocket: process.env.REACT_APP_ARB_PROVIDER_WS,
                    };
                }
            },
        }),
    ]
);

const client = createClient({
    provider,
    webSocketProvider,
    autoConnect: true,
    connectors: [
        new MetaMaskConnector({ chains, options: { name: "MetaMask" } }),
        new InjectedConnector({
            chains,
            options: {
                name: "Trust Wallet",
            },
        }),
        new CoinbaseWalletConnector({ chains }),
    ],
});

const theme = createTheme({
    typography: {
        fontFamily: "Epilogue",
    },
    palette: {
        mode: "dark",
    },
});

function App() {
    const { address } = useAccount();

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <WagmiConfig client={client}>
                    <Background>
                        <Layout>
                            <Routes>
                                <Route
                                    path='/'
                                    element={<Landing />}
                                />
                                <Route
                                    path='/questions'
                                    element={<Questions />}
                                />
                                <Route
                                    path='/positions'
                                    element={<Positions />}
                                />
                                <Route
                                    path='/questionPositions'
                                    element={<QuestionPositions/>}
                                />
                                <Route
                                    path='/hilocard/:id'
                                    element={<HiloCardPage />}
                                />
                                <Route
                                    path='/hiloquestioncard/:id'
                                    element={<HiloQuestionCardPage />}
                                />
                                <Route
                                    path='/analytics'
                                    element={<Stats />}
                                />
                                <Route
                                    path='/dashboard'
                                    element={<Dashboard />}
                                />
                                <Route
                                    path='/leaderboard'
                                    element={<Leaderboard />}
                                />
                                <Route
                                    path='/cashier'
                                    element={<CashierPage />}
                                />
                                {ADMIN_ADDRESS.includes(address) && (
                                    <Route
                                        path='/admin'
                                        element={<Admin />}
                                    />
                                )}
                                <Route
                                    path='*'
                                    element={<Navigate to='/' />}
                                />
                            </Routes>
                        </Layout>
                    </Background>
                    <ToastContainer
                        position='top-right'
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover={false}
                        theme='dark'
                    />
                </WagmiConfig>
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default App;
