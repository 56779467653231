import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";

import styles from "assets/jss/landingPageStyles";
import "react-multi-carousel/lib/styles.css";
import PublicQuestionRounds from "components/questionRounds/PublicQuestionRounds";

const useStyles = makeStyles(styles);

const Questions = () => {
    const classes = useStyles();

    return (
        <Box className={classes.mainContainer}>
            <PublicQuestionRounds />
        </Box>
    );
};

export default Questions;