import {useState, useMemo, useEffect} from "react";
import {
    Box,
    Stack,
    Typography,
    Button,
    TextField,
    CircularProgress,
    FormControlLabel,
    Switch,
    InputAdornment,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import {LoadingButton} from "@mui/lab";
import Web3 from "web3";
import {useAccount, useNetwork, useSwitchNetwork} from "wagmi";

import {makeStyles} from "@mui/styles";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import {styled} from "@mui/material/styles";
import styles from "assets/jss/cashierStyles.js";
import {useCashier} from "../../hooks/useCashier";
import {isEthChain} from "utils/helpers";

import {toast} from "react-toastify";
import {getWithdrawCounts} from "utils/helpers";
import arbCashierAbi from "../../contracts/cashierArb.json";
import hiloAmount from "assets/img/Group 1194.png";

const useStyles = makeStyles( styles );
const web3 = new Web3();

//todo when we have the contract on arb need to check how much is able to be withdrawn/sent to the arb cashier
//todo set up approval of arb contract to transfer the lockedHilo
//todo set up the deposit into cashier on eth and withdraw from cashier on arb, they should only be run when on the respective chains

var withdrawalTx;
var depositTx;

const BootstrapTooltip = styled( ( {className, ...props} ) => (
    <Tooltip
        {...props}
        arrow
        classes={{popper: className}}
    />
) )( ( {theme} ) => ( {
    [`& .${ tooltipClasses.arrow }`]: {
        color: theme.palette.common.black,
    },
    [`& .${ tooltipClasses.tooltip }`]: {
        backgroundColor: theme.palette.common.black,
        fontSize: "1rem",
        padding: "10px 15px",
    },
} ) );

function CashierCard ( {showDeposit, userBalance, fetchUserBalance} ) {
    const classes = useStyles();

    const [amount, setAmount] = useState( web3.utils.toWei( "0" ) );
    const [loadingDeposit, setLoadingDeposit] = useState( false );
    const [loadingWithdraw, setLoadingWithdraw] = useState( false );

    const {address: currentUserAddress} = useAccount();
    const {switchNetworkAsync} = useSwitchNetwork();
    const {chain} = useNetwork();
    const isOnEthChain = isEthChain( chain?.id );

    const {
        depositIntoCashier,
        isDepositLoading,
        withdrawalFromCashier,
        isWithdrawalLoading,
        approveErc20,
        isCashierApprovalLoading,
        approvedAmount,
        fetchHiloBalanceOnCashier,
        hiloBalanceOnCashier,
    } = useCashier( amount );

    const setMaxBet = () => {
        setAmount( userBalance );
    };

    const enterBet = ( e ) => {
        if ( /\./.test( e.target.value ) ) return;
        if ( /[0-9]+\./.test( e.target.value ) ) return;
        if ( e.target.value === "" ) {
            setAmount( web3.utils.toWei( "0" ) );
            return;
        }
        setAmount( web3.utils.toWei( e.target.value ) );
    };

    const switchChain = async () => {
        try {
            await switchNetworkAsync( getChainToSwitchTo( chain?.id ) );
            // eslint-disable-next-line no-restricted-globals
            location.reload();
        } catch ( e ) {
            console.log( "There was an error switching chains" );
            console.log( e );
        }
    };
    const makeDeposit = async () => {
        try {
            depositTx = await depositIntoCashier();
            // Start listening to the Mint event
            await switchChain();
            await fetchUserBalance();
        } catch ( e ) {
            setLoadingDeposit( false );
            console.log( "There was an error" );
            console.log( e );
        }
    };
    const getChainToSwitchTo = ( chainId ) => {
        switch ( chainId ) {
            case Number( process.env.REACT_APP_ETH_CHAIN_ID ):
                return Number( process.env.REACT_APP_ARB_CHAIN_ID );
            case [Number( process.env.REACT_APP_ARB_CHAIN_ID )]:
                return Number( process.env.REACT_APP_ETH_CHAIN_ID );
            default:
                break;
        }
    };

    const makeWithdrawal = async () => {
        try {
            withdrawalTx = await withdrawalFromCashier();
            const arbWebSocketProvider = new Web3.providers.WebsocketProvider(
                process.env.REACT_APP_ETH_GOERLI_PROVIDER_WS
            );
            const arbWeb3 = new Web3( arbWebSocketProvider );
            const contract = new arbWeb3.eth.Contract(
                arbCashierAbi.abi,
                process.env.REACT_APP_ARB_CASHIER
            );
            const event = contract.events.Deposit( {}, async ( error, result ) => {
                if ( error ) {
                    console.error( "Error:", error );
                } else {
                    const user = result.returnValues.user;
                    const amount = result.returnValues.amount;
                    console.log( `Mint event: User-${ user } minted ${ amount } tokens.` );
                    toast( "Deposited Successfully" );
                    toast.success( "Deposited Successfully" );
                    await switchChain();
                }
            } );
            await fetchUserBalance();
        } catch ( e ) {
            setLoadingWithdraw( false );
            console.log( "There was an error" );
            console.log( e );
        }
    };

    const cashierButton = useMemo( () => {
        if ( approvedAmount === null ) return <CircularProgress />;

        if ( !isOnEthChain ) {
            return (
                <LoadingButton
                    className={classes.approvalButton}
                    onClick={() => {
                        setLoadingWithdraw( true );
                        makeWithdrawal();
                    }}
                    disabled={
                        !currentUserAddress ||
                        web3.utils
                            .toBN( amount )
                            .lt( web3.utils.toBN( web3.utils.toWei( "500" ) ) ) ||
                        loadingWithdraw
                    }
                    loading={isWithdrawalLoading && loadingWithdraw}>
                    {web3.utils.toBN( amount ).lt( web3.utils.toBN( web3.utils.toWei( "500" ) ) ) ? (
                        <BootstrapTooltip title='The minimum withdrawal amount must be greater than 500 HILOs'>
                            <Typography variant='body1'>{"WITHDRAWAL"}</Typography>
                        </BootstrapTooltip>
                    ) : (
                        <Typography variant='body1'>{"WITHDRAWAL"}</Typography>
                    )}
                </LoadingButton>
            );
        }

        if ( approvedAmount === "0" || web3.utils.toBN( amount ).gt( web3.utils.toBN( approvedAmount ) ) ) {
            return (
                <LoadingButton
                    className={classes.approvalButton}
                    onClick={approveErc20}
                    disabled={!currentUserAddress}
                    loading={isCashierApprovalLoading}>
                    <Typography variant='body1'>APPROVE</Typography>
                </LoadingButton>
            );
        }

        if ( isOnEthChain ) {
            return (
                <LoadingButton
                    className={classes.approvalButton}
                    onClick={() => {
                        setLoadingDeposit( true );
                        makeDeposit();
                    }}
                    disabled={
                        !currentUserAddress ||
                        web3.utils.toBN( amount ).gt( web3.utils.toBN( userBalance ) ) ||
                        loadingDeposit
                    }
                    loading={isDepositLoading && loadingDeposit}>
                    <Typography variant='body1'>{"DEPOSIT"}</Typography>
                </LoadingButton>
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        approvedAmount,
        amount,
        approveErc20,
        currentUserAddress,
        depositIntoCashier,
        withdrawalFromCashier,
    ] );
    const MaterialUISwitch = styled( Switch )( ( {theme} ) => ( {
        width: 62,
        height: 34,
        padding: 7,
        "& .MuiSwitch-switchBase": {
            margin: 1,
            padding: 0,
            transform: "translateX(6px)",
            "&.Mui-checked": {
                color: "#fff",
                transform: "translateX(22px)",
                "& .MuiSwitch-thumb:before": {
                    backgroundImage: `url('/arbitrum.png')`,
                },
                "& + .MuiSwitch-track": {
                    opacity: 1,
                    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
                },
            },
        },
        "& .MuiSwitch-thumb": {
            backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
            width: 32,
            height: 32,
            "&:before": {
                content: "''",
                position: "absolute",
                width: "100%",
                height: "100%",
                left: 0,
                top: 0,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundImage: `url('/eth.png')`,
            },
        },
        "& .MuiSwitch-track": {
            opacity: 1,
            backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
            borderRadius: 20 / 2,
        },
    } ) );
    const [switchClick, setSwitchClick] = useState( false );

    useEffect( () => {
        fetchUserBalance();
        setSwitchClick( !isEthChain( chain?.id ) );
    }, [chain?.id, currentUserAddress, fetchUserBalance] );
    const {switchNetwork} = useSwitchNetwork();

    return (
        <Box className={classes.blockContainer}>
            <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                backgroundColor='#354352'
                padding='1rem'
                spacing={1}
                sx={{borderBottom: 0, borderTopLeftRadius: "10px", borderTopRightRadius: "10px"}}>
                {/* <FormControlLabel
        control={<MaterialUISwitch sx={{ m: 1 }} defaultChecked />}
        label="MUI switch"
      /> */}

                <Typography
                    variant='h6'
                    sx={{color: "white"}}>
                    Deposit
                </Typography>
                <FormControlLabel
                    control={
                        <MaterialUISwitch
                            // defaultChecked={switchClick}
                            checked={switchClick}
                            onClick={( e ) => {
                                // setSwitchClick(temp);
                                switchNetwork?.(
                                    switchClick === true
                                        ? Number( process.env.REACT_APP_ETH_CHAIN_ID )
                                        : Number( process.env.REACT_APP_ARB_CHAIN_ID )
                                );
                            }}
                        />
                    }
                />
                <Typography
                    variant='h6'
                    sx={{color: "white"}}>
                    Withdraw
                </Typography>
                {/* <Switch
          checked={!showDeposit}
          onChange={handleChange}
          value="checked"
          sx={{
            "& .MuiSwitch-switchBase": {
              "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: "white",
              },
            },
            "&.MuiSwitch-root .MuiSwitch-switchBase": {
              color: "#4166f4",
            },

            "&.MuiSwitch-root .Mui-checked": {
              color: "#4166f4",
              "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: "white",
              },
            },
          }}
        /> */}
            </Stack>
            <Box>
                <Box className={classes.innerBox}>
                    <Stack
                        direction='column'
                        justifyContent='center'
                        alignItems='center'>
                        <Box sx={{width: "90%"}}>
                            <Stack
                                direction='column'
                                justifyContent='center'
                                alignItems='center'
                                spacing={3}>
                                <Stack
                                    direction='row'
                                    justifyContent='flex-start'
                                    alignItems='center'
                                    sx={{width: "100%"}}>
                                    <Typography sx={{color: "#828992"}}>
                                        {isOnEthChain ? "Deposit" : "Withdrawal"} Amount
                                    </Typography>
                                </Stack>
                                <TextField
                                    id='input-with-icon-textfield'
                                    fullWidth
                                    value={web3.utils.fromWei( amount ).split( "." )[0]}
                                    onChange={enterBet}
                                    type='number'
                                    sx={{
                                        input: {
                                            color: "white",
                                            fontSize: "23px",
                                            textAlign: "center",
                                            paddingLeft: "0px",
                                        },
                                    }}
                                    className={classes.betInput}
                                    placeholder='0'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <img src={hiloAmount} />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <Button
                                                variant='contained'
                                                onClick={setMaxBet}>
                                                Max
                                            </Button>
                                        ),
                                    }}
                                />
                                {/* <Box> */}
                                <Stack
                                    direction='column'
                                    justifyContent='center'
                                    alignItems='flex-end'
                                    sx={{width: "100%"}}
                                    spacing={2}>
                                    {!isOnEthChain && hiloBalanceOnCashier && (
                                        <Typography
                                            className={classes.maxBetBalance}
                                            variant='body1'>{`Withdrawable amount: ${ web3.utils.fromWei( hiloBalanceOnCashier ).split( "." )[0]
                                                } $HILO `}</Typography>
                                    )}
                                    <Stack
                                        direction='row'
                                        justifyContent='flex-end'
                                        alignItems='center'
                                        sx={{width: "100%"}}
                                        spacing={2}>
                                        <Typography
                                            className={classes.maxBetBalance}
                                            variant='body1'>{`Balance: ${ web3.utils.fromWei( userBalance ).split( "." )[0]
                                                } $HILO `}</Typography>
                                    </Stack>
                                </Stack>
                                {/* </Box> */}
                            </Stack>
                        </Box>
                    </Stack>
                </Box>
                <Box
                    className={classes.payoutBox}
                    justifyContent='center'>
                    {cashierButton}
                </Box>

            </Box>
        </Box>
    );
}

export default CashierCard;
