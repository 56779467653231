import { ethers } from "ethers";
import axios from "axios";
import { BACKEND_API_URL } from "configs";
import web3 from "web3";
import arbCashierAbi from "contracts/cashierArb.json";

/**
 * Calculate the reward multiplier


@example: const bullMultiplier = getMultiplierV2(totalAmount, bullAmount);
@example: const bearMultiplier = getMultiplierV2(totalAmount, bearAmount);
*/
export const getMultiplierV2 = (total, amount) => {
    if (!total) {
        return ethers.FixedNumber.from(0);
    }

    if (total.eq(0) || amount.eq(0)) {
        return ethers.FixedNumber.from(0);
    }

    const rewardAmountFixed = ethers.FixedNumber.from(total);
    const multiplierAmountFixed = ethers.FixedNumber.from(amount);

    return rewardAmountFixed.divUnsafe(multiplierAmountFixed);
};

/**
 * Takes a string that is a large number, with many decimals, cuts it to two decimal places
 * @param {string} numericString string to be formatted to two decimals places
 * @param {number} decimals the amount of decimals to keep
 */
export const setDecimalsNumericString = (numericString, decimals = 2) => {
    const numArray = numericString.split(".");
    if (numArray.length <= 1) return numArray[0];
    const twoDecimals = numArray[1].slice(0, decimals);

    return [numArray[0], twoDecimals].join(".");
};
export const formatCurrency = (value) => {
    var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    });
    return formatter.format(value);
};

export const isEthChain = (chainId) => [Number(process.env.REACT_APP_ETH_CHAIN_ID)].includes(chainId ?? undefined);

export const depositHILO = async (sender, amount, tx) => {
    try {
        const response = await axios.post(`${BACKEND_API_URL}/api/contracts/deposit`, {
            sender,
            tx,
            amount,
        });
        console.log(response.data);
        return true;
    } catch (err) {
        return false;
    }
};

export const withdrawHILO = async (sender, amount, tx) => {
    try {
        const response = await axios.post(`${BACKEND_API_URL}/api/contracts/withdraw`, {
            sender,
            tx,
            amount,
        });
        console.log(response.data);
        return true;
    } catch (err) {
        return false;
    }
};

export const convertNumberToLocaleString = (num) => {
    if (!num) return;
    return num.toLocaleString("en-US");
};

export const convertAddress = (addr) => {
    if (!addr) return;
    return addr.slice(0, 7) + "..." + addr.slice(-4);
};

export const convertWinningRate = (winningNum, involvedNum) => {
    return ((winningNum * 100) / involvedNum).toFixed(2) + "%";
};

export const convertUSDTPrice = (amt, price) => {
    if (!amt) return;
    return (
        "~" +
        (web3.utils.fromWei(amt, "ether") * price).toLocaleString("es-US", {
            style: "currency",
            currency: "USD",
        })
    );
};

export const restructorArray = (array, length) => {
    let size = 0,
        subArray = [];
    const parentArray = [];
    array?.forEach((each, index) => {
        subArray.push(each);
        size++;
        if (size === length) {
            parentArray.push(subArray);
            size = 0;
            subArray = [];
        }
    });
    subArray.length && parentArray.push(subArray);
    return parentArray;
};

export const getWithdrawCounts = async (address) => {
    const wweb3 = new web3("https://arb1.arbitrum.io/rpc");
    const contractInstance = new wweb3.eth.Contract(
        arbCashierAbi,
        process.env.REACT_APP_ARB_CASHIER
    );
    const now = new Date();
    const param = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, "0")}-${now
        .getDate()
        .toString()
        .padStart(2, "0")}T00:00:00`;
    const response = await axios.get(
        `https://deep-index.moralis.io/api/v2/dateToBlock?chain=arbitrum&date=${param}`,
        {
            headers: {
                accept: "application/json",
                "X-API-Key": "8RzKHVWr03FVYji5VKFZFxLh5e5FQ9PZPqeUDtwYIGnZkPz1ounQkUoGLgSakDlo",
            },
        }
    );
    const fromBlock = response?.data?.block;
    const eventList = await contractInstance.getPastEvents("Burn", {
        filter: { user: address },
        fromBlock: fromBlock,
        toBlock: "latest",
    });
    return eventList?.length;
};
