import clsx from "clsx";
import styles from "assets/jss/tableStyles";
import {useEffect, useState} from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Snackbar,
    Alert,
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useNetwork} from "wagmi";
import AdminQuestionRoundRow from "./AdminQuestionRoundRow";
import EditQuestionRound from "components/Admin/EditQuestionRound";


const useStyles = makeStyles( styles );

function OpenQuestionRounds ( {openQuestionRounds, fetchQuestionRounds} ) {
    const classes = useStyles();

    const [snackbarData, setSnackbarData] = useState( {
        message: "",
        severity: "success",
    } );
    const [openSnackbar, setOpenSnackbar] = useState( false );
    const [showEditQuestionRoundForm, setShowEditQuestionRoundForm] = useState( false );
    const [questionRoundToEdit, setQuestionRoundToEdit] = useState( {} );



    const {chain} = useNetwork();
    useEffect( () => {
        fetchQuestionRounds();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chain?.id] );

    const handleSnackbarOpen = () => {
        setOpenSnackbar( true );
    };

    const handleSnackbarClose = ( event, reason ) => {
        if ( reason === "clickaway" ) {
            return;
        }

        setOpenSnackbar( false );
    };
    if ( openQuestionRounds === null ) return null;

    return (
        <>
            <TableContainer className={classes.block} component={Paper}>
                <Table
                    className={classes.body}
                    sx={{minWidth: 650}}
                    aria-label="simple table"
                >
                    <TableHead className={clsx( classes.trow, classes.header )}>
                        <TableRow>
                            <TableCell align="center" className={classes.tCell}>
                                #
                            </TableCell>
                            <TableCell align="center" className={classes.tCell}>
                                question Name
                            </TableCell>
                            <TableCell align="center" className={classes.tCell}>
                                OPEN UNTIL
                            </TableCell>
                            <TableCell align="center" className={classes.tCell}>
                                SETTLES ON
                            </TableCell>
                            <TableCell align="center" className={classes.tCell}>
                                STATUS
                            </TableCell>
                            <TableCell align="center" className={classes.tCell} />
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.body}>
                        {openQuestionRounds.map( ( round, key ) => (
                            <AdminQuestionRoundRow
                                key={key}
                                roundData={round}
                                fetchRounds={fetchQuestionRounds}
                                setSnackbarData={setSnackbarData}
                                handleSnackbarOpen={handleSnackbarOpen}
                                setShowEditQuestionRoundForm={setShowEditQuestionRoundForm}
                                setQuestionRoundToEdit={setQuestionRoundToEdit}
                            />
                        ) )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarData.severity}
                    sx={{width: "100%"}}
                >
                    {snackbarData.message}
                </Alert>
            </Snackbar>
            <EditQuestionRound
                showEditQuestionRoundForm={showEditQuestionRoundForm}
                setShowEditQuestionRoundForm={setShowEditQuestionRoundForm}
                defaultQuestionValues={questionRoundToEdit}
                setQuestionRoundToEdit={setQuestionRoundToEdit}
                fetchQuestionRounds={fetchQuestionRounds}
            />
        </>
        // <Box className={classes.block}>
        //   <Box className={classes.body}>
        //     <Box className={clsx(classes.trow, classes.header)}>
        //       <Box>#</Box>
        //       <Box>PAIR</Box>
        //       <Box>OPEN UNTIL</Box>
        //       <Box>SETTLES ON</Box>
        //     </Box>
        //     <Box className={classes.scollBox}>
        //     </Box>
        //   </Box>
        // </Box>
    );
}

export default OpenQuestionRounds;
