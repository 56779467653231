import {useEffect} from "react";
import {Stack, Box, TextField} from "@mui/material";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm, Controller} from "react-hook-form";
import {postUpdateQuestionRound} from "api/questions";
import {LoadingButton} from "@mui/lab";

import {editQuestionSchema} from "./schemas";

function EditQuestionRoundForm ( {
    fetchQuestionRounds,
    handleClose,
    setIsSubmitting,
    defaultValues,
} ) {
    const replaceNull = ( roundData ) => {
        const filterResult = Object.entries( roundData )
            .filter( ( [key, value] ) => {
                return value === null;
            } )
            .map( ( [key] ) => [key, ""] );

        return {...roundData, ...Object.fromEntries( filterResult )};
    };

    const formatCategories = ( defautlValues ) => {
        if ( !defaultValues?.category ) return {};
        const data = defaultValues.category.map( ( category ) => category.category );
        return {category: data};
    };

    const {
        handleSubmit,
        watch,
        formState: {
            errors,
            isSubmitting,
            isValid,
            isDirty,
            dirtyFields,
            defaultValues: formDefaults,
        },
        control,
        reset,
    } = useForm( {
        defaultValues: {
            ...replaceNull( defaultValues ),
            ...formatCategories( defaultValues ),
        },
        resolver: yupResolver( editQuestionSchema ),
        mode: "all",
    } );

    const onSubmit = async ( data ) => {
        try {
            const onlyDirtyFields = Object.fromEntries(
                Object.keys( dirtyFields ).map( ( key ) => [key, data[key]] )
            );
            await postUpdateQuestionRound( {...onlyDirtyFields, epochId: data.epochId} );
            fetchQuestionRounds();
            handleClose();
            reset();
        } catch ( e ) {
            console.log( e );
        }
    };

    useEffect( () => {
        setIsSubmitting( isSubmitting );
    }, [isSubmitting] );

    return (
        <Box sx={{mt: 2}}>
            <form onSubmit={handleSubmit( onSubmit )}>
                <Stack
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                    spacing={2}>
                    <Controller
                        render={( {field} ) => {
                            return (
                                <TextField
                                    {...field}
                                    error={Boolean( errors.questionName?.message )}
                                    helperText={errors.questionName?.message}
                                    fullWidth
                                    label='question name'
                                />
                            );
                        }}
                        name='questionName'
                        label='question name'
                        control={control}
                    />
                    <Controller
                        render={( {field} ) => (
                            <TextField
                                {...field}
                                error={Boolean( errors.questionSymbol?.message )}
                                helperText={errors.questionSymbol?.message}
                                fullWidth
                                label='question symbol'
                            />
                        )}
                        name='questionSymbol'
                        label='question symbol'
                        control={control}
                    />
                    {watch( "questionImageUrl" ) && (
                        <Box
                            component='img'
                            style={{width: 25, height: 25}}
                            src={`${ watch( "questionImageUrl" ) }`}
                            alt='question'
                        />
                    )}
                    <Controller
                        render={( {field} ) => (
                            <TextField
                                {...field}
                                error={Boolean( errors.questionImageUrl?.message )}
                                helperText={errors.questionImageUrl?.message}
                                fullWidth
                                label='question image url'
                            />
                        )}
                        name='questionImageUrl'
                        label='question image url'
                        control={control}
                    />
                    <Controller
                        render={( {field} ) => (
                            <TextField
                                {...field}
                                multiline
                                rows={4}
                                error={Boolean( errors.questionDescription?.message )}
                                helperText={errors.questionDescription?.message}
                                fullWidth
                                label='question description'
                            />
                        )}
                        name='questionDescription'
                        label='question description'
                        control={control}
                    />
                    <Controller
                        render={( {field} ) => (
                            <TextField
                                {...field}
                                error={Boolean( errors.upOption?.message )}
                                helperText={errors.upOption?.message}
                                fullWidth
                                label='Choice One'
                            />
                        )}
                        name='choiceOne'
                        control={control}
                    />
                    <Controller
                        render={( {field} ) => (
                            <TextField
                                {...field}
                                error={Boolean( errors.downOption?.message )}
                                helperText={errors.downOption?.message}
                                fullWidth
                                label='Choice Two'
                            />
                        )}
                        name='choiceTwo'
                        control={control}
                    />
                    <Controller
                        render={( {field} ) => (
                            <TextField
                                {...field}
                                error={Boolean( errors.mediumUrl?.message )}
                                helperText={errors.mediumUrl?.message}
                                fullWidth
                                label='news url'
                            />
                        )}
                        name='newsUrl'
                        control={control}
                    />
                    <LoadingButton
                        loading={isSubmitting}
                        variant='contained'
                        type='submit'
                        disabled={!isDirty || !isValid}>
                        Submit
                    </LoadingButton>
                </Stack>
            </form>
        </Box>
    );
}

export default EditQuestionRoundForm;
