import {useState, useEffect, useCallback, useMemo} from "react";
import clsx from "clsx";
import {makeStyles} from "@mui/styles";

import styles from "assets/jss/tableStyles";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Select,
    MenuItem,
    Stack,
    Typography,
    Checkbox, ButtonBase, Button,
} from "@mui/material";
import LiveRoundRow from "./LiveRoundRow";
import {useAccount, useContractRead, useNetwork, useContractReads} from "wagmi";
import {getRounds} from "api/rounds";
import {useInterval} from "hooks/useInterval";
import {find} from "lodash";
import dayjs from "dayjs";
import {getCategoriess} from "api/categories";
import predictionMarketAbi from "../../contracts/predictionMarketContract.json";
import hiloTokenAbi from "../../contracts/hiloTokenContractAbi.json";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import pridictionnewabi from "../../contracts/pridictionnewabi.json";



const useStyles = makeStyles( styles );

const PublicRounds = () => {
    const classes = useStyles();
    const {address: currentUserAddress} = useAccount();
    const {chain} = useNetwork();

    const [refreshRoundData, setRefreshRoundData] = useState( false );
    const [approvedAmount, setApprovedAmount] = useState( null );

    const [openRounds, setOpenRounds] = useState( [] );
    const [smartContractRoundData, setSmartContractRoundData] = useState( [] );
    const [contracts, setContracts] = useState( [] );
    const [sortKey, setSortKey] = useState( "totalAmount" );
    const [filter, setFilter] = useState( {
        open: true,
        locked: true,
    } );
    const [categories, setCategories] = useState( [] );
    const [categoryKey, setCategoryKey] = useState( "All" );
    const [roundsCopy, setRoundsCopy] = useState( [] );
    const [arbPrice, setArbPrice] = useState();
    useEffect( () => {
        const fetchPrice = async () => {
            const response = await axios.get( "https://api.coinbase.com/v2/prices/HILO-USD/spot" );
            setArbPrice( response.data.data.amount );
        };
        fetchPrice();
    }, [] );

    const roundBettingStatus = ( roundData ) => {
        if ( dayjs().isBefore( dayjs.unix( roundData?.lockTimestamp ) ) ) {
            return "OPEN";
        } else if ( dayjs().isBefore( dayjs.unix( roundData?.closeTimestamp ) ) ) {
            return "LOCKED";
        } else {
            return "DONE";
        }
    };

    const fetchRounds = () => {
        getRounds().then( ( res ) => {
            setOpenRounds( res.data.rounds );
        } );
    };

    useInterval( () => {
        fetchRounds();
    }, 60000 );

    const fetchCategories = async () => {
        try {
            const {
                data: {categories},
            } = await getCategoriess();
            setCategories( [...categories] );
        } catch ( e ) {
            console.log( e );
        }
    };

    useEffect( () => {
        fetchCategories();
    }, [] );

    const handleCategoryChange = ( e ) => {
        setCategoryKey( e.target.value );
    };

    useEffect( () => {
        fetchRounds();
    }, [chain?.id] );

    useEffect( () => {
        if ( openRounds.length ) {
            const result = [];
            for ( const round of openRounds ) {

                const contract = round.epochId >= Number( process.env.REACT_APP_EPOCH ) ? {
                    address: process.env.REACT_APP_PRIDICTION_CONTRACT,
                    abi: pridictionnewabi,
                } : {
                    address: process.env.REACT_APP_OLD_PRIDICTION_CONTRACT,
                    abi: predictionMarketAbi,
                };
                result.push( {
                    ...contract,
                    functionName: "rounds",
                    args: [round.epochId],
                    chainId: Number( process.env.REACT_APP_ARB_CHAIN_ID ),
                } );
            }
            setContracts( [...result] );
        }
    }, [openRounds.length, currentUserAddress] );

    const {isLoading} = useContractReads( {
        contracts,
        enabled: !!contracts.length,
        onSuccess: ( data ) => setSmartContractRoundData( data ),
    } );

    const sortedRounds = useMemo( () => {
        const rounds = [];
        if ( smartContractRoundData.length && openRounds.length && !isLoading ) {
            smartContractRoundData.forEach( ( round ) => {
                if ( !round?.epoch?.toNumber() ) return;
                const dbRound = find( openRounds, {
                    epochId: round.epoch.toNumber(),
                } );
                rounds.push( {
                    ...dbRound,
                    ...round,
                    roundBettingStatus: roundBettingStatus( round ),
                } );
            } );
            let finalData = rounds.sort( ( a, b ) => {
                if ( a[sortKey] < ( b[sortKey] ) ) {
                    return 1;
                } else if ( a[sortKey] > ( b[sortKey] ) ) {
                    return -1;
                } else {
                    return 0;
                }
            } );

            if ( categoryKey !== "All" ) {
                const result = finalData.filter( ( round ) => {
                    return !!find( round.category, ( category ) => category.category === categoryKey );
                } );

                setRoundsCopy( result );
                return result;
            } else {
                setRoundsCopy( finalData );
                return finalData;
            }
        } else {
            setRoundsCopy( [] );
            return [];
        }
    }, [smartContractRoundData, openRounds, isLoading, categoryKey, sortKey] );

    useEffect( () => {
        if ( filter.locked && filter.open ) {
            setRoundsCopy( [...sortedRounds] );
        } else if ( filter.locked && !filter.open ) {
            setRoundsCopy(
                [...sortedRounds].filter( ( item ) => roundBettingStatus( item ) === "LOCKED" )
            );
        } else if ( !filter.locked && filter.open ) {
            setRoundsCopy( [...sortedRounds].filter( ( item ) => roundBettingStatus( item ) === "OPEN" ) );
        } else {
            setRoundsCopy( [] );
        }
    }, [filter, sortedRounds] );

    function refreshPage () {
        window.location.reload();
    }
    useEffect( () => {
        if ( refreshRoundData ) {
            refreshPage();
        }
    }, [refreshRoundData] );

    const checkAllowance = useContractRead( {
        address: process.env.REACT_APP_HILO_TOKEN,
        abi: hiloTokenAbi,
        functionName: "allowance",
        args: [currentUserAddress, process.env.REACT_APP_PRIDICTION_CONTRACT],
        enabled: false,
    } );

    const fetchApprovedAmount = useCallback( async () => {
        const result = await checkAllowance.refetch();
        setApprovedAmount( result.data?.toString() ?? "0" );
    }, [checkAllowance] );

    useEffect( () => {
        const init = async () => {
            if ( chain?.id && currentUserAddress ) {
                await fetchApprovedAmount();
            }
        };
        init();
    }, [chain?.id, currentUserAddress, fetchApprovedAmount] );

    const handleChangeSortKey = ( e ) => {
        setSortKey( e.target.value );
    };

    const navigate = useNavigate();
    const goToQuestions = () => {
        navigate( '/questions' );
    };
    const goToRounds = () => {
        navigate( '/' );
    };


    if ( openRounds === null ) return null;



    return (
        <>
            <Box>
                <Stack
                    className={classes.filterContainer}
                    direction={{xs: "column-reverse", sm: "column-reverse", md: "row"}}
                    justifyContent='space-between'
                    alignItems={{sm: "start", md: "start"}}
                    spacing={2}>
                    <Box
                        display='flex'
                        alignItems='center'>
                        <Stack
                            className={classes.predictionButtonsContainer}
                        >
                            <Button className={classes.button} onClick={goToQuestions}>Real-Life Predictions</Button>
                            <Button className={classes.button} onClick={goToRounds}>Price Predictions</Button>
                        </Stack>
                    </Box>
                    <Stack
                        className={classes.filterContainer}
                        direction={{sm: "row", md: "row"}}
                        justifyContent={{sm: "space-evenly", md: "flex-end"}}
                        alignItems={{sm: "center", md: "center"}}
                        spacing={2}>
                        <Box
                            display='flex'
                            alignItems='center'>
                            <Stack
                                direction={{
                                    sm: "center",
                                    md: "row",
                                }}
                                justifyContent='space-between'
                                alignItems={{sm: "center", md: "center"}}
                                spacing={2}>
                                <Typography className={classes.sortBy}>Categories: </Typography>
                                <Select
                                    disableUnderline={true}
                                    variant='standard'
                                    className={classes.categorySorter}
                                    labelId='categoryLabel'
                                    id='demo-simple-select'
                                    value={categoryKey}
                                    onChange={handleCategoryChange}>
                                    <MenuItem
                                        key={"All"}
                                        value={"All"}>
                                        All
                                    </MenuItem>
                                    {categories.map( ( category ) => (
                                        <MenuItem
                                            key={category}
                                            value={category}>
                                            {category}
                                        </MenuItem>
                                    ) )}
                                </Select>
                                <Typography className={classes.sortBy}>Sort by:</Typography>
                                <Select
                                    disableUnderline={true}
                                    variant='standard'
                                    className={classes.sorter}
                                    labelId='demo-simple-select-label'
                                    id='demo-simple-select'
                                    value={sortKey}
                                    label='Age'
                                    onChange={handleChangeSortKey}>
                                    <MenuItem value={"totalAmount"}>Total amount</MenuItem>
                                    <MenuItem value={"bearAmount"}>Bear amount</MenuItem>
                                    <MenuItem value={"bullAmount"}>Bull amount</MenuItem>
                                    <MenuItem value={"closeTimestamp"}>Round close</MenuItem>
                                    <MenuItem value={"lockTimestamp"}>Round lock</MenuItem>
                                </Select>
                            </Stack>
                        </Box>
                        <Box className={classes.checboxContainer}>
                            <Checkbox
                                onChange={( e ) => {
                                    setFilter( {...filter, locked: e.target.checked} );
                                }}
                                checked={filter.locked}
                                className={classes.checkbox}
                                sx={{color: "#33404D"}}
                            />
                            <Typography className={classes.sortBy}>Show Locked</Typography>
                            <Checkbox
                                onChange={( e ) => {
                                    setFilter( {...filter, open: e.target.checked} );
                                }}
                                checked={filter.open}
                                className={classes.checkbox}
                                sx={{color: "#33404D"}}
                            />
                            <Typography className={classes.sortBy}>Show Open</Typography>
                        </Box>
                        <Box
                            display='flex'
                            alignItems='center'>
                            <Stack
                                direction={{
                                    sm: "column",
                                    md: "row",
                                }}
                                justifyContent={{
                                    sm: "space-evenly",
                                    md: "center",
                                }}
                                alignItems={{md: "center"}}
                                spacing={2}>

                            </Stack>
                        </Box>
                    </Stack>
                </Stack>
            </Box>
            <TableContainer
                className={classes.block}
                component={Paper}>
                <Table
                    className={classes.body}
                    sx={{minWidth: 650}}
                    aria-label='simple table'>
                    <TableHead className={clsx( classes.trow, classes.header )}>
                        <TableRow>
                            <TableCell
                                align='center'
                                className={classes.tCell}>
                                NAME
                            </TableCell>
                            <TableCell
                                align='center'
                                className={classes.tCell}>
                                TICKER
                            </TableCell>
                            <TableCell
                                align='center'
                                className={classes.tCell}>
                                TIMEFRAME
                            </TableCell>
                            <TableCell
                                align='center'
                                className={classes.tCell}>
                                POOL
                            </TableCell>
                            <TableCell
                                align='center'
                                className={classes.tCell}>
                                PAYOUTS
                            </TableCell>
                            <TableCell
                                align='center'
                                className={classes.tCell}>
                                CHART (LAST 24H)
                            </TableCell>
                            <TableCell
                                align='center'
                                className={classes.tCell}>
                                Status
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.body}>
                        {roundsCopy &&
                            roundsCopy.map( ( round, index ) => (
                                <LiveRoundRow
                                    key={index}
                                    roundData={round}
                                    approvedAmount={approvedAmount}
                                    fetchApprovedAmount={fetchApprovedAmount}
                                    arbPrice={arbPrice}
                                />
                            ) )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default PublicRounds;
