import Countdown from "react-countdown";
import axios from "axios";
import Web3 from "web3";
import dayjs from "dayjs";
import {useEffect} from "react";
import {Stack, Box, TextField} from "@mui/material";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm, Controller} from "react-hook-form";
import {postCreateQuestion} from "api/questions";
import {LoadingButton} from "@mui/lab";
import {debounce} from "lodash";
import {startNewQuestionSchema} from "./schemas";
import CategoryInput from "components/CategoryInput/CategoryInput";

const web3 = new Web3();

const defaultFormValues = {
    questionName: "",
    questionSymbol: "",
    questionImageUrl: "",
    questionDescription: "",
    lockTimestamp: dayjs(),
    closeTimestamp: dayjs(),
    choiceOne: "",
    choiceTwo: "",
    questionCategory: []
};

//todo bottom two functions to be updated when adding new chain
/**
 * Takes a string that is a large number, with many decimals, cuts it to two decimal places
 * @param {string} pairAddress address of pair we want the values of
 * @param {string} chain dexcreener chain id for pair we are looking up
 */

function CreateRoundForm ( {fetchRounds, handleClose, setIsSubmitting} ) {
    const {
        handleSubmit,
        watch,
        formState: {errors, isSubmitting, isValid, isDirty},
        control,
        reset,
        setValue,
    } = useForm( {
        defaultValues: defaultFormValues,
        resolver: yupResolver( startNewQuestionSchema ),
        mode: "all",
    } );

    const onSubmit = async ( data ) => {
        try {
            await postCreateQuestion( data );
            fetchRounds();
            handleClose();
            reset();
        } catch ( e ) {
            console.log( e );
        }
    };

    useEffect( () => {
        setIsSubmitting( isSubmitting );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitting] );

    return (
        <Box sx={{mt: 2}}>
            <form onSubmit={handleSubmit( onSubmit )}>
                <Stack
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                    spacing={2}>
                    <Controller
                        render={( {field} ) => {
                            return (
                                <TextField
                                    {...field}
                                    error={Boolean( errors.questionName?.message )}
                                    helperText={errors.questionName?.message}
                                    fullWidth
                                    label='question name'
                                />
                            );
                        }}
                        name='questionName'
                        label='question name'
                        control={control}
                    />
                    <Controller
                        render={( {field} ) => (
                            <TextField
                                {...field}
                                error={Boolean( errors.questionSymbol?.message )}
                                helperText={errors.questionSymbol?.message}
                                fullWidth
                                label='question symbol'
                            />
                        )}
                        name='questionSymbol'
                        label='question symbol'
                        control={control}
                    />
                    {watch( "questionImageUrl" ) && (
                        <Box
                            component='img'
                            style={{width: 25, height: 25}}
                            src={`${ watch( "questionImageUrl" ) }`}
                            alt='question'
                        />
                    )}
                    <Controller
                        render={( {field} ) => (
                            <TextField
                                {...field}
                                error={Boolean( errors.questionImageUrl?.message )}
                                helperText={errors.questionImageUrl?.message}
                                fullWidth
                                label='question image url'
                            />
                        )}
                        name='questionImageUrl'
                        label='question image url'
                        control={control}
                    />
                    <Controller
                        render={( {field} ) => (
                            <TextField
                                {...field}
                                multiline
                                rows={4}
                                error={Boolean( errors.questionDescription?.message )}
                                helperText={errors.questionDescription?.message}
                                fullWidth
                                label='question description'
                            />
                        )}
                        name='questionDescription'
                        label='question description'
                        control={control}
                    />
                    <CategoryInput
                        control={control}
                        errors={errors}
                        fieldName="questionCategory"
                        label="Question Categories"
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {dayjs( watch( "lockTimestamp" ) ).isValid() && (
                            <Countdown date={dayjs( watch( "lockTimestamp" ) ).toDate()} />
                        )}
                        <Controller
                            name='lockTimestamp'
                            control={control}
                            render={( {field: {ref, ...rest}} ) => (
                                <DateTimePicker
                                    disablePast
                                    renderInput={( {error: inputError, ...params} ) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            error={Boolean( errors.lockTimestamp?.message )}
                                            helperText={errors.lockTimestamp?.message}
                                        />
                                    )}
                                    label='Lock betting'
                                    {...rest}
                                />
                            )}
                        />
                        {dayjs( watch( "closeTimestamp" ) ).isValid() && (
                            <Countdown date={dayjs( watch( "closeTimestamp" ) ).toDate()} />
                        )}
                        <Controller
                            name="closeTimestamp"
                            control={control}
                            render={( {field: {ref, ...rest}} ) => (
                                <DateTimePicker
                                    disablePast
                                    renderInput={( {error: inputError, ...params} ) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            error={Boolean( errors.closeTimestamp?.message )}
                                            helperText={errors.closeTimestamp?.message}
                                        />
                                    )}
                                    label="Close round"
                                    {...rest}
                                />
                            )}
                        />
                    </LocalizationProvider>
                    <Controller
                        render={( {field} ) => (
                            <TextField
                                {...field}
                                error={Boolean( errors.upOption?.message )}
                                helperText={errors.upOption?.message}
                                fullWidth
                                label='Choice One'
                            />
                        )}
                        name='choiceOne'
                        control={control}
                    />
                    <Controller
                        render={( {field} ) => (
                            <TextField
                                {...field}
                                error={Boolean( errors.downOption?.message )}
                                helperText={errors.downOption?.message}
                                fullWidth
                                label='Choice Two'
                            />
                        )}
                        name='choiceTwo'
                        control={control}
                    />
                    <Controller
                        render={( {field} ) => (
                            <TextField
                                {...field}
                                error={Boolean( errors.mediumUrl?.message )}
                                helperText={errors.mediumUrl?.message}
                                fullWidth
                                label='news url'
                            />
                        )}
                        name='newsUrl'
                        control={control}
                    />
                    <LoadingButton
                        loading={isSubmitting}
                        variant='contained'
                        type='submit'
                        disabled={!isDirty || !isValid}>
                        Submit
                    </LoadingButton>
                </Stack>
            </form>
        </Box>
    );
}

export default CreateRoundForm;
