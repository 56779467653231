import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import styles from "assets/jss/landingPageStyles";
import Cashier from "components/Cashier/Cashier";
import { Stack, Typography } from "@mui/material";

const useStyles = makeStyles(styles);

const CashierPage = () => {
    const classes = useStyles();

    return (
        <Box className={classes.mainContainer}>
            <Stack
                display='flex'
                direction='row'
                alignItems='center'
                gap='1rem'>
                <Typography
                    color='white'
                    variant='h3'>
                    Wallet |
                </Typography>
                <Typography color='#767582'>
                    Deposit and Withdraw HILO seamlessly across two chains here
                </Typography>
            </Stack>
            <Cashier />
        </Box>
    );
};

export default CashierPage;
