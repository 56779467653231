import clsx from "clsx";
import styles from "assets/jss/tableStyles";
import AdminRoundRow from "components/Admin/AdminRoundRow";
import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
  Alert,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import EditRound from "components/Admin/EditRound";
import { useNetwork } from "wagmi";

const useStyles = makeStyles(styles);

function OpenRounds({ openRounds, fetchRounds }) {
  const classes = useStyles();

  const [snackbarData, setSnackbarData] = useState({
    message: "",
    severity: "success",
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [showEditRoundForm, setShowEditRoundForm] = useState(false);
  const [roundToEdit, setRoundToEdit] = useState({});

  const { chain } = useNetwork();
  useEffect(() => {
    fetchRounds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chain?.id]);

  const handleSnackbarOpen = () => {
    setOpenSnackbar(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  if (openRounds === null) return null;

  return (
    <>
      <TableContainer className={classes.block} component={Paper}>
        <Table
          className={classes.body}
          sx={{ minWidth: 650 }}
          aria-label="simple table"
        >
          <TableHead className={clsx(classes.trow, classes.header)}>
            <TableRow>
              <TableCell align="center" className={classes.tCell}>
                #
              </TableCell>
              <TableCell align="center" className={classes.tCell}>
                TOKEN NAME
              </TableCell>
              <TableCell align="center" className={classes.tCell}>
                OPEN UNTIL
              </TableCell>
              <TableCell align="center" className={classes.tCell}>
                SETTLES ON
              </TableCell>
              <TableCell align="center" className={classes.tCell}>
                STATUS
              </TableCell>
              <TableCell align="center" className={classes.tCell} />
            </TableRow>
          </TableHead>
          <TableBody className={classes.body}>
            {openRounds.map((round, key) => (
              <AdminRoundRow
                key={key}
                roundData={round}
                fetchRounds={fetchRounds}
                setSnackbarData={setSnackbarData}
                handleSnackbarOpen={handleSnackbarOpen}
                setShowEditRoundForm={setShowEditRoundForm}
                setRoundToEdit={setRoundToEdit}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarData.severity}
          sx={{ width: "100%" }}
        >
          {snackbarData.message}
        </Alert>
      </Snackbar>
      <EditRound
        showEditRoundForm={showEditRoundForm}
        setShowEditRoundForm={setShowEditRoundForm}
        defaultValues={roundToEdit}
        setRoundToEdit={setRoundToEdit}
        fetchRounds={fetchRounds}
      />
    </>
    // <Box className={classes.block}>
    //   <Box className={classes.body}>
    //     <Box className={clsx(classes.trow, classes.header)}>
    //       <Box>#</Box>
    //       <Box>PAIR</Box>
    //       <Box>OPEN UNTIL</Box>
    //       <Box>SETTLES ON</Box>
    //     </Box>
    //     <Box className={classes.scollBox}>
    //     </Box>
    //   </Box>
    // </Box>
  );
}

export default OpenRounds;
