import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {NavLink} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import {Divider, Typography} from "@mui/material";
import HILO from "assets/img/HILO.svg";
import menuIcon from "assets/img/menu.png";
import logo from "assets/img/logo.png";
import meteamaskIcon from "assets/img/icon-metamask.png";
import coinbaseIcon from "assets/img/icon-coinbase.png";
import trustwalletIcon from "assets/img/trust_platform.svg";
import {
    useConnect,
    useDisconnect,
    useAccount,
    useNetwork,
    useSwitchNetwork,
    useBalance,
    useContractRead
} from "wagmi";
import axios from "axios";
import styles from "assets/jss/navbarStyles";
import { signMessage } from "@wagmi/core";
import {ADMIN_ADDRESS} from "configs";
import hiloTokenAbi from "../../contracts/hiloTokenContractAbi.json";

const style = {
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const WALLETS = {
    MetaMask: {
        title: "Metamask",
        connector: "METAMASK",
        icon: meteamaskIcon,
    },
    "Trust Wallet": {
        icon: trustwalletIcon,
    },
    "Coinbase Wallet": {icon: coinbaseIcon},
};

const truncateAddress = ( address ) => {
    if ( !address ) return "No Account";
    const match = address.match( /^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{2})$/ );
    if ( !match ) return address;
    return `${ match[1] }…${ match[2] }`;
};

const useStyles = makeStyles( styles );

const Navbar = () => {
    const classes = useStyles();
    const [open, setOpen] = useState( false );

    const handleClose = () => {
        setOpen( false );
    };

    const handleOpen = () => {
        setOpen( true );
    };

    const {connectAsync, connectors, isLoading, pendingConnector} = useConnect();
    const {disconnect} = useDisconnect();
    const {address, isConnected} = useAccount();
    const {chain} = useNetwork();
    const {switchNetworkAsync} = useSwitchNetwork();

    const [isOpenConnectModal, setIsOpenConnectModal] = useState( false );

    const handleSwitchNetwork = async () => {
        await switchNetworkAsync( Number( process.env.REACT_APP_ETH_CHAIN_ID ) );
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    };

    useEffect(() => {
        (async function(){     
        if (isConnected) {
            if (
                chain?.id === Number( process.env.REACT_APP_ETH_CHAIN_ID ) ||
                chain?.id === Number( process.env.REACT_APP_ARB_CHAIN_ID )
            ) {
                setIsOpenConnectModal( false );
            } else {
                handleSwitchNetwork();
            }

            if(ADMIN_ADDRESS.includes(address)){
                try {
                    const message = `Request to sign in from address ${address}`;

                    const signature = await signMessage({ message });
                    window.localStorage.setItem("message", message);
                    window.localStorage.setItem("message-token", signature);
                } catch (exception) {
                    console.error(exception);
                    disconnect();
                }
            }
        }else {
            window.localStorage.removeItem('message');
            window.localStorage.removeItem('message-token')
        }
        })();
    }, [isConnected]);

    const {data: ethBalance} = useBalance( {
        address,
        chainId: Number( process.env.REACT_APP_ETH_CHAIN_ID ), // Ethereum mainnet
    } );

    const checkBalance = useContractRead( {
        address: process.env.REACT_APP_HILO_TOKEN,
        abi: hiloTokenAbi,
        functionName: "balanceOf",
        args: [address],
        chainId: Number( process.env.REACT_APP_ETH_CHAIN_ID ),
        onError: ( e ) => {
            console.log( "error hilo balance", e );
        }
    } );

    console.log( "hilo balance", checkBalance.data );

    const [ethPrice, setEthPrice] = useState();
    const [arbPrice, setArbPrice] = useState();

    useEffect( () => {
        const fetchPrice = async () => {
            const response = await axios.get( "https://api.coinbase.com/v2/prices/ETH-USD/spot" );
            setEthPrice( response.data.data.amount );
        };
        fetchPrice();
    }, [] );

    useEffect( () => {
        const fetchPrice = async () => {
            const response = await axios.get( "https://api.coinbase.com/v2/prices/HILO-USD/spot" );
            setArbPrice( response.data.data.amount );
        };
        fetchPrice();
    }, [] );

    const arbBalanceInUSD = checkBalance ? ( ( checkBalance?.data * arbPrice ) / 10 ** 18 ).toFixed( 2 ) : 0;
    const ethBalanceInUSD = ethBalance ? ( ( ethBalance.value * ethPrice ) / 10 ** 18 ).toFixed( 2 ) : 0;
    console.log( "eth balance", ethBalance?.value );
    console.log( "arb balance in usd", arbBalanceInUSD );

    return (
        <>
            <Drawer
                anchor='left'
                open={open}
                onClose={handleClose}>
                <Box className={classes.sidebar}>
                    <Box display='flex'>
                        <IconButton
                            sx={{marginLeft: "auto"}}
                            onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box className={classes.mobileNav}>

                        {!address ? (
                            <Button
                                sx={{padding: 0, fontSize: "16px", marginBottom: "24px"}}
                                className={classes.connectWalletBtnXsScreen}
                                onClick={() => setIsOpenConnectModal( true )}>
                                CONNECT
                            </Button>
                        ) : (
                            <Button
                                sx={{padding: 0, fontSize: "16px", marginBottom: "24px"}}
                                className={classes.connectWalletBtnXsScreen}
                                onClick={() => {
                                    disconnect();
                                }}>
                                {truncateAddress( address )}
                            </Button>
                        )}

                        {/* {isConnected && <SwitchNetwork />} */}
                        <NavLink
                            onClick={() => {
                                handleClose();
                            }}
                            to='/'
                            underline='none'>
                            HOME
                        </NavLink>
                        {address ? (
                            <NavLink
                                onClick={() => {
                                    handleClose();
                                }}
                                to='/positions'
                                underline='none'>
                                POSITIONS
                            </NavLink>
                        ) : (
                            <></>
                        )}
                        {address ? (
                            <NavLink
                                onClick={() => {
                                    handleClose();
                                }}
                                to='/dashboard'
                                underline='none'>
                                DASHBOARD
                            </NavLink>
                        ) : (
                            <></>
                        )}
                        <NavLink
                            onClick={() => {
                                handleClose();
                            }}
                            to='/leaderboard'
                            underline='none'>
                            LEADERBOARD
                        </NavLink>
                        <NavLink
                            onClick={() => {
                                handleClose();
                            }}
                            to='/analytics'
                            underline='none'>
                            ANALYTICS
                        </NavLink>
                        {address ? (
                            <NavLink
                                onClick={() => {
                                    handleClose();
                                }}
                                to='/cashier'
                                underline='none'>
                                WALLET
                            </NavLink>
                        ) : (
                            <></>
                        )}
                        {ADMIN_ADDRESS.includes( address ) && (
                            <NavLink
                                onClick={() => {
                                    handleClose();
                                }}
                                to='/admin'
                                underline='none'>
                                ADMIN
                            </NavLink>
                        )}
                    </Box>
                </Box>
            </Drawer>
            <Box
                position='grid'
                className={classes.navbar}>
                <Box className={classes.navbarInner}>
                    <Box>
                        <NavLink to='/'>
                            <img
                                src={logo}
                                alt=''
                                width={168}
                            />
                        </NavLink>
                    </Box>

                    <Box className={classes.navLinks}>
                        {/* <NavLink
              href="/"
              underline="none"
              className={clsx(classes.activeLink)}
            >
              STATS
            </NavLink> */}
                        <NavLink
                            className={( {isActive} ) =>
                                isActive ? classes.linkItemActive : classes.linkItem
                            }
                            to='/'
                            underline='none'>
                            HOME
                        </NavLink>
                        {address ? (
                            <NavLink
                                className={( {isActive} ) =>
                                    isActive ? classes.linkItemActive : classes.linkItem
                                }
                                to='/positions'
                                underline='none'>
                                POSITIONS
                            </NavLink>
                        ) : (
                            <></>
                        )}
                        {address ? (
                            <NavLink
                                className={( {isActive} ) =>
                                    isActive ? classes.linkItemActive : classes.linkItem
                                }
                                to='/dashboard'
                                underline='none'>
                                DASHBOARD
                            </NavLink>
                        ) : (
                            <></>
                        )}
                        <NavLink
                            className={( {isActive} ) =>
                                isActive ? classes.linkItemActive : classes.linkItem
                            }
                            to='/leaderboard'
                            underline='none'>
                            LEADERBOARD
                        </NavLink>
                        <NavLink
                            className={( {isActive} ) =>
                                isActive ? classes.linkItemActive : classes.linkItem
                            }
                            to='/analytics'
                            underline='none'>
                            ANALYTICS
                        </NavLink>
                        {address ? (
                            <NavLink
                                className={( {isActive} ) =>
                                    isActive ? classes.linkItemActive : classes.linkItem
                                }
                                to='/cashier'
                                underline='none'>
                                WALLET
                            </NavLink>
                        ) : (
                            <></>
                        )}
                        {ADMIN_ADDRESS.includes( address ) && (
                            <NavLink
                                className={( {isActive} ) =>
                                    isActive ? classes.linkItemActive : classes.linkItem
                                }
                                to='/admin'
                                underline='none'>
                                ADMIN
                            </NavLink>
                        )}
                    </Box>
                    <Stack direction="row" >
                        {isConnected && (
                            <Stack
                                direction='row'
                                spacing={2}
                                paddingRight='1rem'
                                sx={{
                                    background: "#1A192E",
                                    borderTopLeftRadius: "100px",
                                    borderBottomLeftRadius: "100px",
                                    borderTopRightRadius: "100px",
                                    borderBottomRightRadius: "100px",
                                    height: "3.688rem"
                                }}>
                                <img src={HILO} />
                                <Stack justifyContent='center'>
                                    <Typography
                                        sx={{
                                            color: "rgba(255, 255, 255, 0.40)",
                                            fontFamily: "Barlow",
                                            fontSize: {md: "20px", xs: "16px"},
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            lineHeight: "130%",
                                        }}>
                                        $ETH
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: "#FFF",
                                            fontFamily: "Barlow",
                                            fontSize: {md: "20px", xs: "16px"},
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            lineHeight: "130%",
                                        }}>
                                        ${ethBalanceInUSD}
                                    </Typography>
                                </Stack>
                                <Divider
                                    orientation='vertical'
                                    flexItem
                                    variant='middle'
                                    sx={{borderRightWidth: 2}}
                                />
                                <Stack justifyContent='center'>
                                    <Typography
                                        sx={{
                                            color: "rgba(255, 255, 255, 0.40)",
                                            fontFamily: "Barlow",
                                            fontSize: {md: "20px", xs: "16px"},
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            lineHeight: "130%",
                                        }}>
                                        $HILO
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: "#FFF",
                                            fontFamily: "Barlow",
                                            fontSize: {md: "20px", xs: "16px"},
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            lineHeight: "130%",
                                        }}>
                                        ${arbBalanceInUSD}
                                    </Typography>
                                </Stack>
                            </Stack>
                        )
                        }
                        <IconButton className={classes.mobileButton} onClick={handleOpen}>
                            <img
                                src={menuIcon}
                                alt=''
                            />
                        </IconButton>
                        {!address ? (
                            <Button
                                className={classes.connectWalletBtn}
                                onClick={() => setIsOpenConnectModal( true )}>
                                CONNECT
                            </Button>
                        ) : (
                            <Button
                                className={classes.connectWalletBtn}
                                onClick={() => {
                                    disconnect();
                                }}>
                                {truncateAddress( address )}
                            </Button>
                        )}

                    </Stack>

                </Box>

            </Box>

            <Modal
                open={isOpenConnectModal}
                onClose={() => setIsOpenConnectModal( false )}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'>
                <Box sx={style}>
                    {connectors.map( ( connector ) => {
                        return (
                            <Button
                                disabled={!connector.ready}
                                key={connector.id}
                                onClick={() => connectAsync( {connector} )}
                                sx={{marginBo: "10px"}}>
                                <img
                                    style={{marginRight: "10px"}}
                                    src={WALLETS[connector.name].icon}
                                    width='40px'
                                    alt='wallet icon'
                                />
                                {connector.name}
                                {!connector.ready && " (unsupported)"}
                                {isLoading &&
                                    connector.id === pendingConnector?.id &&
                                    " (connecting)"}
                            </Button>
                        );
                    } )}
                </Box>
            </Modal>
        </>
    );
};

export default Navbar;
