import React, {useMemo, useState, useEffect, useCallback} from "react";
import {makeStyles} from "@mui/styles";
import Box from "@mui/material/Box";
import arbCashierAbi from "contracts/cashierArb.json";
import pridictionMarketAbi from "contracts/predictionMarketContract.json";

import {useContractRead, useAccount, useNetwork} from "wagmi";

import styles from "assets/jss/landingPageStyles";
import "react-multi-carousel/lib/styles.css";
import {useInterval} from "hooks/useInterval";
import HiloQuestionCard from "components/hiloQuestionCard/hiloQuestionCard";
import {getSingleQuestion} from "api/questions";
import {useParams} from "react-router-dom";
import dayjs from "dayjs";
import {Stack, Typography} from "@mui/material";

const useStyles = makeStyles( styles );

const HiloQuestionCardPage = () => {
    const {id} = useParams();
    const classes = useStyles();

    const {chain} = useNetwork();
    const {address: currentUserAddress, isConnected} = useAccount();
    const [isRoundDataLoading, setIsRoundDataLoading] = useState( true );
    const [dbRoundData, setDbRoundData] = useState( {} );
    const [approvedAmount, setApprovedAmount] = useState( null );
    const [userBalance, setUserBalance] = useState( "0" );
    // const [dataFromSmartContract, setDataFromSmartContract] = useState({});

    //get round data from database
    const fetchRoundData = ( roundId ) => {
        getSingleQuestion( roundId ).then( ( res ) => {
            setDbRoundData( res.data.roundDataFromDb );
            setIsRoundDataLoading( false );
        } );
    };
    const {
        data: dataFromSmartContract,
        isLoading: isScRoundDataLoading,
        refetch: refetchRoundData,
    } = useContractRead( {
        address: process.env.REACT_APP_PRIDICTION_CONTRACT,
        abi: pridictionMarketAbi,
        functionName: "rounds",
        args: [Number( id )],
    } );

    useInterval( () => {
        refetchRoundData();
        fetchRoundData( id );
    }, 5000 );

    useEffect( () => {
        fetchRoundData( id );
    }, [id] );

    const timeCheck = dayjs( new Date() );

    const roundBettingStatus = useMemo( () => {
        if ( dayjs().isBefore( dayjs.unix( dataFromSmartContract?.lockTimestamp ) ) ) {
            return "OPEN";
        } else if ( dayjs().isBefore( dayjs.unix( dataFromSmartContract?.closeTimestamp ) ) ) {
            return "LOCKED";
        } else {
            return "DONE";
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isRoundDataLoading,
        dataFromSmartContract?.lockTimestamp,
        dataFromSmartContract?.closeTimestamp,
        timeCheck,
    ] );
    const checkAllowance = useContractRead( {
        address: process.env.REACT_APP_ARB_CASHIER,
        abi: arbCashierAbi,
        functionName: "allowance",
        args: [currentUserAddress, process.env.REACT_APP_PRIDICTION_CONTRACT],
        enabled: false,
    } );

    const fetchApprovedAmount = useCallback( async () => {
        try {
            const result = await checkAllowance.refetch();
            setApprovedAmount( result.data.toString() );
        } catch ( e ) {
            console.log( "Error fetching the approved amount", e );
        }
    }, [checkAllowance] );

    useEffect( () => {
        const init = async () => {
            if ( chain?.id && currentUserAddress && isConnected ) {
                await fetchApprovedAmount();
            }
        };
        init();
    }, [chain?.id, currentUserAddress, fetchApprovedAmount, isConnected] );

    const checkBalance = useContractRead( {
        address: process.env.REACT_APP_ARB_CASHIER,
        abi: arbCashierAbi,
        functionName: "balanceOf",
        args: [currentUserAddress],
        enabled: false,
    } );

    const fetchUserBalance = useCallback( async () => {
        try {
            const result = await checkBalance.refetch();
            setUserBalance( result.data.toString() );
        } catch ( e ) {
            setUserBalance( "0" );
        }
    }, [checkBalance] );

    useEffect( () => {
        fetchUserBalance();
    }, [chain?.id, currentUserAddress, fetchUserBalance] );

    if ( isRoundDataLoading || isScRoundDataLoading ) return null;

    return (
        <Box className={classes.mainContainer}>
            {!isConnected ? (
                <Stack
                    justifyContent='center'
                    alignItems='center'>
                    <Typography
                        sx={{
                            color: "#fff",
                            fontSize: "30px",
                            fontWeight: "500",
                            lineHeight: "27px",
                            marginLeft: "46px",
                            textDecoration: "none",
                        }}>
                        Please connect your wallet
                    </Typography>
                </Stack>
            ) : !dataFromSmartContract ? (
                <Typography
                    sx={{
                        color: "#fff",
                        fontSize: "30px",
                        fontWeight: "500",
                        lineHeight: "27px",
                        marginLeft: "46px",
                        textDecoration: "none",
                    }}>
                    Please make sure you select Arbitrum network on your wallet
                </Typography>
            ) : (
                <HiloQuestionCard
                    dbRoundData={dbRoundData}
                    dataFromSmartContract={dataFromSmartContract}
                    roundBettingStatus={roundBettingStatus}
                    epoch={Number( dataFromSmartContract.epoch )}
                    refetchRoundData={refetchRoundData}
                    approvedAmount={approvedAmount}
                    userBalance={userBalance}
                    fetchApprovedAmount={fetchApprovedAmount}
                />
            )}
        </Box>
    );
};

export default HiloQuestionCardPage;
