import {useMemo, useState} from "react";
import {
    Button,
    TableCell,
    TableRow,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Select,
    MenuItem,
    TextField,
} from "@mui/material";
// import ClaimWinningsButton from "./ClaimWinningsButton";
import styles from "assets/jss/tableStyles";

import {makeStyles} from "@mui/styles";
import Web3 from "web3";
import {postCloseRound} from "api/rounds";
import {postLockRound} from "api/rounds";
import dayjs from "dayjs";
import {useNetwork, useContractRead} from "wagmi";
import predictionMarketAbi from "../../contracts/predictionMarketContract.json";

const useStyles = makeStyles( styles );

function AdminRoundRow ( {
    roundData,
    fetchRounds,
    setSnackbarData,
    handleSnackbarOpen,
    setShowEditRoundForm,
    setRoundToEdit,
} ) {
    const web3 = new Web3();
    const classes = useStyles();

    const [openDialog, setOpenDialog] = useState( false );
    const [openLockPriceDialog, setOpenLockPriceDialog] = useState( false );
    const [roundOutcome, setRoundOutcome] = useState( 0 );
    const [lockPrice, setLockPrice] = useState( 0 );

    const {chain} = useNetwork();

    const {epochId, lockTimestamp, closeTimestamp, tokenName, outcome} = roundData;
    console.log( "rounds to edit from Admin round row", roundData );


    const priceFormat = ( number ) => {
        const fromWei = web3.utils.fromWei( number );
        return fromWei.replace( /(\d)(?=(\d\d\d)+(?!\d))/g, "$1," );
    };

    const roundStatus = useMemo( () => {
        return true;
    }, [] );

    const handleClickOpen = () => {
        setOpenDialog( true );
    };

    const handleClose = () => {
        setOpenDialog( false );
    };
    const handleClickOpenLockDialog = () => {
        setOpenLockPriceDialog( true );
    };

    const handleCloseLockDialog = () => {
        setOpenLockPriceDialog( false );
    };

    const {data: dataFromSmartContract} = useContractRead( {
        address: process.env.REACT_APP_PRIDICTION_CONTRACT,
        abi: predictionMarketAbi,
        functionName: "rounds",
        args: [epochId],
        enabled: Math.floor( Date.now() / 1000 ) > closeTimestamp && outcome,
    } );

    const closeRound = async () => {
        try {
            await postCloseRound( {
                roundToEnd: epochId,
                outcome: roundOutcome,
            } );
            fetchRounds();
            setSnackbarData( {
                message: `Successfully closed round #${ epochId }`,
                severity: "success",
            } );
            handleSnackbarOpen();
            handleClose();
        } catch ( e ) {
            setSnackbarData( {
                message: `There was an error closing round #${ epochId } - ${ e.response.data.message }`,
                severity: "error",
            } );
            handleSnackbarOpen();
            console.log( e );
        }
    };

    const lockPriceChange = ( e ) => {
        setLockPrice( e.target.value );
    };

    const sendLockPrice = async () => {
        try {
            await postLockRound( {roundToLock: epochId, lockPrice} );
            fetchRounds();
            setSnackbarData( {
                message: `Successfully added lock price to round #${ epochId }`,
                severity: "success",
            } );
            handleSnackbarOpen();
            handleCloseLockDialog();
        } catch ( e ) {
            setSnackbarData( {
                message: `There was an error locking round #${ epochId } - ${ e.response.data.message }`,
                severity: "error",
            } );
            handleSnackbarOpen();
            console.log( e );
        }
    };

    const roundOutcomeChange = ( e ) => {
        setRoundOutcome( e.target.value );
    };

    const handleClickOpenEditRound = () => {
        setRoundToEdit( roundData );
        setShowEditRoundForm( true );
    };

    return (
        <>
            <Dialog
                open={openDialog}
                onClose={handleClose}>
                <DialogTitle>End round</DialogTitle>
                <DialogContent>
                    <DialogContentText>Select outcome for round:</DialogContentText>
                    <Select
                        value={roundOutcome}
                        onChange={roundOutcomeChange}>
                        <MenuItem value={0}>Up</MenuItem>
                        <MenuItem value={1}>Down</MenuItem>
                        <MenuItem value={2}>Draw</MenuItem>
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={closeRound}>Submit</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openLockPriceDialog}
                onClose={handleCloseLockDialog}>
                <DialogTitle>Set lock price</DialogTitle>
                <DialogContent>
                    <DialogContentText>Select lock price for round:</DialogContentText>
                    <TextField
                        type='number'
                        value={lockPrice}
                        onChange={lockPriceChange}></TextField>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseLockDialog}>Cancel</Button>
                    <Button onClick={sendLockPrice}>Submit</Button>
                </DialogActions>
            </Dialog>
            <TableRow className={classes.tRows}>
                <TableCell
                    align='center'
                    className={classes.tCell}>
                    {epochId}
                </TableCell>
                <TableCell
                    align='center'
                    className={classes.tCell}>
                    {tokenName}
                </TableCell>
                <TableCell
                    align='center'
                    className={classes.tCell}>
                    {new Date( lockTimestamp * 1000 ).toUTCString()}
                </TableCell>
                <TableCell
                    align='center'
                    className={classes.tCell}>
                    {new Date( closeTimestamp * 1000 ).toUTCString()}
                </TableCell>
                <TableCell
                    align='center'
                    className={classes.tCell}>
                    {!roundData.lockPrice ? (
                        <Button
                            className={classes.claimBtn}
                            disabled={Boolean(
                                roundData.lockPrice || dayjs().isBefore( dayjs.unix( lockTimestamp ) )
                            )}
                            onClick={handleClickOpenLockDialog}>
                            Send lock price
                        </Button>
                    ) : (
                        <Button
                            className={classes.claimBtn}
                            onClick={handleClickOpen}
                            disabled={
                                Math.floor( Date.now() / 1000 ) < closeTimestamp ||
                                ( outcome && dataFromSmartContract?.roundClosed )
                            }>
                            Close round
                        </Button>
                    )}

                    {/* <ClaimWinningsButton
      isClaimable={
        isClaimable ||
        isClaimableLoading ||
        isCheckRefundLoading ||
        isRefundable
      }
      claimWinnings={claimWinnings}
      epoch={epoch}
    /> */}
                </TableCell>
                <TableCell className={classes.tCell}>
                    <Button
                        className={classes.claimBtn}
                        sx={{color: "white", borderColor: "white"}}
                        onClick={handleClickOpenEditRound}>
                        Edit Round
                    </Button>
                </TableCell>
            </TableRow>
        </>
    );
}

export default AdminRoundRow;
