import {useState} from "react";
import CreateRound from "../../components/Admin/CreateRound";
import OpenRounds from "../../components/Admin/OpenRounds";
import {Box, Stack} from "@mui/material";
import {getAdminRounds} from "api/rounds";
import {useInterval} from "hooks/useInterval";
import AdminBalances from "components/Admin/AdminBalances";

import {makeStyles} from "@mui/styles";
import styles from "assets/jss/adminCreateRound";
import {useNetwork} from "wagmi";
import {getAdminQuestionRounds} from "../../api/questions";
import OpenQuestionRounds from "../../components/Admin/OpenQuestionRounds";

const useStyles = makeStyles( styles );

function Admin () {
    const classes = useStyles();
    const {chain} = useNetwork();

    const [openRounds, setOpenRounds] = useState( null );
    const [openQuestionRounds, setOpenQuestionRounds] = useState( null );


    const fetchRounds = () => {
        getAdminRounds().then( ( res ) => setOpenRounds( res.data.rounds ) );
    };

    const fetchQuestionRounds = () => {
        getAdminQuestionRounds().then( ( res ) => setOpenQuestionRounds( res.data.questions ) );
    };

    useInterval( () => {
        fetchRounds();
        fetchQuestionRounds();
    }, 60000 );

    return (
        <Box sx={{width: "100%"}}>
            {!chain ? (
                <></>
            ) : chain.id === Number( process.env.REACT_APP_ARB_CHAIN_ID ) ? (
                <>
                    <Stack
                        direction={{xs: "row", sm: "row"}}
                        justifyContent='space-between'
                        alignItems={{xs: "flex-end", sm: "center"}}
                        spacing={2}
                        className={classes.block}>
                        <CreateRound fetchRounds={fetchRounds} />
                        <AdminBalances />
                    </Stack>
                    <OpenRounds
                        openRounds={openRounds}
                        fetchRounds={fetchRounds}
                    />
                    <OpenQuestionRounds
                        openQuestionRounds={openQuestionRounds} fetchQuestionRounds={fetchQuestionRounds} />
                </>
            ) : (
                <Stack
                    direction={{xs: "row", sm: "row"}}
                    justifyContent='center'
                    alignItems={{xs: "center", sm: "center"}}>
                    <h3 style={{color: "white"}}>
                        {process.env.REACT_APP_ENV === "production"
                            ? "Currently we are using Arbitrum mainnet. You need to change the network if you want to see the tables"
                            : "Currently we are using Goerli Arbitrum network. You need to change the network if you want to see the tables"}
                    </h3>
                </Stack>
            )}
        </Box>
    );
}

export default Admin;
